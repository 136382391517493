import logo from "../../images/logo.png";
import diamondWhite from "../../images/diamondWhite.png";
import closeBlack from "../../images/HeaderHamburgherMenu/closeBlack.png";
import openWhite from "../../images/HeaderHamburgherMenu/openWhite.png";
import openBlack from "../../images/HeaderHamburgherMenu/openBlack.png";
import chDo from "../../images/chevron-down.svg";
import video_header from "../../images/video_header.png";
import website_header from "../../images/website_header.png";

import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";

import { StyledMainHeader } from "./StyledMainHeader";
import { headerContext } from "../../context/headerContext";

const MainHeader = (props) => {
	const [opened, setOpened] = useState(false);
	const headerCtx = useContext(headerContext);
	const [isMobile, setIsMobile] = useState(false);
	const [serviziMenu, setServiziMenu] = useState(false);
	const [isHovering, setIsHovering] = useState(false);
	const [langMenu, setLangMenu] = useState(false);

	const { t, i18n } = useTranslation();

	const menuHandler = () => {
		setOpened(!opened);
	};

	const handleResize = () => {
		if (window.innerWidth < 980) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
		return;
	};

	const handleLang = () => {
		setLangMenu(!langMenu);
	};

	useEffect(() => {
		if (isHovering) return setServiziMenu(true);
		setServiziMenu(false);
	}, [isHovering]);

	useEffect(() => {
		handleResize();
		window.addEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		i18n.changeLanguage(
			window.navigator.userLanguage || window.navigator.language
		);
	}, [i18n]);

	const lngs = {
		en: { nativeName: "English 🇺🇸" },
		de: { nativeName: "Deutsch 🇩🇪" },
		es: { nativeName: "Spanish 🇪🇸" },
		it: { nativeName: "Italian 🇮🇹" },
		fr: { nativeName: "French 🇫🇷" },
	};

	if (!headerCtx.isHidden) {
		return (
			<StyledMainHeader
				isScrolled={!headerCtx.isTransparent}
				headerColors={headerCtx.headerColors}
			>
				<div className='mainDivHeaderWeb'>
					<a href='/'>
						<img
							src={
								headerCtx.headerColors
									? logo
									: !headerCtx.isTransparent
									? logo
									: diamondWhite
							}
							className='logo'
							alt='logo'
						/>
					</a>
					<nav className='nav'>
						<ul>
							<li>
								<a className='a' href='/contattaci'>
									{t("mainHeader.1")}
								</a>
							</li>
							<li
								onMouseEnter={() => setIsHovering(true)}
								onMouseLeave={() => setIsHovering(false)}
							>
								<p className={`a ${isHovering && "bold"}`} id={`a-p`}>
									{t("mainHeader.2")}
									<svg
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
										className={`chDo ${isHovering && "rot"}`}
									>
										<path
											d='M6.34317 7.75732L4.92896 9.17154L12 16.2426L19.0711 9.17157L17.6569 7.75735L12 13.4142L6.34317 7.75732Z'
											fill='currentColor'
										/>
									</svg>
								</p>
							</li>
							<li className='li-wrapper'>
								<h2 className='lang-sel' onClick={handleLang}>
									<span>{i18n.resolvedLanguage}</span>
								</h2>
							</li>
						</ul>
					</nav>
					{serviziMenu && (
						<div
							className='hover-menu'
							onMouseEnter={() => setIsHovering(true)}
							onMouseLeave={() => setIsHovering(false)}
						>
							<a className='wrapper' href='ordina-il-tuo-sito-web'>
								<img src={website_header} alt='globe' className='img' />
								<h2 className='h2'>{t("mainHeader.3")}</h2>
								<p className='p'>{t("mainHeader.4")}</p>
							</a>

							<a className='wrapper' href='/ordina-i-tuoi-video-ads'>
								<img src={video_header} alt='globe' className='img' />
								<h2 className='h2'>{t("mainHeader.5")}</h2>
								<p className='p'>{t("mainHeader.6")}</p>
							</a>
						</div>
					)}
					{langMenu && (
						<div className='wrapper-langs'>
							{Object.keys(lngs).map((lng) => (
								<button
									className='btn'
									key={lng}
									style={{
										fontWeight:
											i18n.resolvedLanguage === lng ? "bold" : "normal",
									}}
									type='submit'
									onClick={() => {
										i18n.changeLanguage(lng);
										setLangMenu(false);
									}}
								>
									{lngs[lng].nativeName}
								</button>
							))}
						</div>
					)}
				</div>
				<div className='divWrapper'>
					<div className='mainDivHeaderMobile'>
						<a href='/'>
							<img
								src={
									headerCtx.headerColors
										? logo
										: !headerCtx.isTransparent
										? logo
										: diamondWhite
								}
								className='logo'
								alt='logo'
							/>
						</a>
						<div className='divMenu'>
							<img
								className='img'
								src={
									headerCtx.headerColors
										? openBlack
										: !headerCtx.isTransparent
										? openBlack
										: openWhite
								}
								onClick={menuHandler}
								alt='open menu button'
							/>
						</div>
					</div>
				</div>
				{opened && (
					<div className='overlay'>
						<div className='divMenu'>
							<img
								className='img'
								src={closeBlack}
								onClick={menuHandler}
								alt='close menu button'
							/>
						</div>
						<nav className='nav'>
							<ul>
								<li>
									<a className='a' href='/contattaci'>
										{t("mainHeader.1")}
									</a>
								</li>
								<li onClick={() => setIsHovering(!isHovering)}>
									<p
										className={`a ${isHovering && "bold"}`}
										id={`a-p`}
										href='https://www.servizidiamond.it'
									>
										{t("mainHeader.2")}
										<svg
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
											className={`chDo ${isHovering && "rot"}`}
										>
											<path
												d='M6.34317 7.75732L4.92896 9.17154L12 16.2426L19.0711 9.17157L17.6569 7.75735L12 13.4142L6.34317 7.75732Z'
												fill='currentColor'
											/>
										</svg>
									</p>
								</li>
								{isHovering && (
									<li className='li-menu'>
										<a href='/ordina-il-tuo-sito-web' className='p'>
											{t("mainHeader.3")}
										</a>
										<a href='/ordina-i-tuoi-video-ads' className='p'>
											{t("mainHeader.5")}
										</a>
									</li>
								)}
							</ul>
						</nav>

						<select
							value={i18n.resolvedLanguage}
							className='lang-sel-mobile'
							onChange={(e) => i18n.changeLanguage(e.target.value)}
						>
							{Object.keys(lngs).map((lng) => (
								<option key={lng} value={lng}>
									{lngs[lng].nativeName}
								</option>
							))}
						</select>
					</div>
				)}
			</StyledMainHeader>
		);
	}
};

export default MainHeader;
