import { useContext, useState } from "react";
import InputLabel from "../../components/InputLabel/InputLabel";
import TextArea from "../../components/TextArea/TextArea";
import { headerContext } from "../../context/headerContext";
import { realtimeDatabase } from "../../firebase/realtimeFir";
import { ref, set } from "firebase/database";
import { useTranslation } from "react-i18next";

import whatsappQR from "../../images/whatsappQR.webp";

import { StyledContattaci } from "./StyledContattaci";

const Contattaci = () => {
	const [name, setName] = useState();
	const [email, setEmail] = useState();
	const [message, setMessage] = useState();
	const [successMessage, setSuccessMessage] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalText, setModalText] = useState();

	const { t } = useTranslation();

	const headerCtx = useContext(headerContext);
	headerCtx.handleTransparency(true);
	headerCtx.handleHeaderColors(true);

	const isValid = (email) => {
		return /\S+@\S+\.\S+/.test(email);
	};

	const setGenerateDate = () => {
		var date = new Date();
		var datetime = `${date.getFullYear()}${
			date.getMonth() + 1
		}${date.getDay()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}${date.getMilliseconds()}`;
		return datetime;
	};

	const handleSubmitForm = (e) => {
		e.preventDefault();

		if (isValid(email)) {
			set(
				ref(realtimeDatabase, "moduloContatti/messaggi/" + setGenerateDate()),
				{
					name: name,
					email: email,
					message: message,
				}
			).then(() => {
				setSuccessMessage(true);
				var form = document.getElementById("formContatti");
				form.reset();
				setIsModalOpen(true);
				setModalText(t("rightContactPageForm.5"));
			});
		} else {
			setIsModalOpen(true);
			setModalText(t("contactPageOverlay.1"));
		}
	};

	return (
		<StyledContattaci>
			{isModalOpen && (
				<div className='overlayModal'>
					<div className='modal'>
						<h3>{successMessage ? t("contactPageOverlay.2") : "Oops..."}</h3>
						<span className='span'>{modalText}</span>
						<button
							onClick={() => {
								if (successMessage) {
									setIsModalOpen(false);
									window.location.reload();
								} else {
									setIsModalOpen(false);
								}
							}}
						>
							OK
						</button>
					</div>
				</div>
			)}
			<div className='leftDiv'>
				<h1>{t("leftContactPageTitle.1")}</h1>
				<p>{t("leftContactPageP1.1")}</p>
				<ul>
					<li>
						+39 327 621 64 53
						<a href='tel:+39 327 621 64 53'>
							{t("contactPageCTA.1")}
							<div className='imageDiv'>
								<img
									src='https://img.icons8.com/ios/50/000000/phone.png'
									alt=''
								/>
							</div>
						</a>
					</li>
					<li>
						+39 342 149 04 13
						<a href='tel:+39 342 149 04 13'>
							{t("contactPageCTA.1")}
							<div className='imageDiv'>
								<img
									src='https://img.icons8.com/ios/50/000000/phone.png'
									alt=''
								/>
							</div>
						</a>
					</li>
				</ul>
				<div className='divQR'>
					<img src={whatsappQR} alt='whatsapp QR code' />
					<p>{t("leftContactPageP2.1")}</p>
				</div>
				<p>{t("leftContactPageP3.1")}</p>
				<ul>
					<li>
						informazioni@servizidiamond.it
						<a href='mailto:informazioni@servizidiamond.it'>
							{t("contactPageCTA.2")}
							<div className='imageDiv'>
								<img src='https://img.icons8.com/ios/50/000000/mail.png' />
							</div>
						</a>
					</li>
				</ul>
			</div>
			<div className='rightDiv'>
				<h1>{t("rightContactPageTitle.1")}</h1>
				<p>{t("rightContactPageP1.1")}</p>
				<form onSubmit={handleSubmitForm} id='formContatti'>
					<div className='line'>
						<InputLabel
							label={t("rightContactPageForm.1")}
							inputText={setName}
						/>
					</div>
					<div className='line'>
						<InputLabel
							label={t("rightContactPageForm.2")}
							inputText={setEmail}
						/>
					</div>
					<div className='text'>
						<h3>{t("rightContactPageForm.3")}</h3>
						<TextArea
							className='textArea'
							inputText={setMessage}
							placeholder={t("rightContactPageForm.7")}
						/>
					</div>
					<div className='divButton'>
						<div className='left'>
							{successMessage && <p>{t("rightContactPageForm.4")}</p>}
						</div>
						<div className='right'>
							<button type='submit'>{t("rightContactPageForm.6")}</button>
						</div>
					</div>
				</form>
			</div>
		</StyledContattaci>
	);
};

export default Contattaci;
