import squareLogo from "../../images/squareLogo.png";

import { StyledMainFooter } from "./StyledMainFooter";

const MainFooter = () => {
	return (
		<StyledMainFooter>
			<div className='wrapper'>
				<div className='head'>
					<div className='divLogo'>
						<img src={squareLogo} alt='diamond logo' className='logo' />
					</div>
					<div className='textDiv'>
						<h3 className='title'>Servizi diamond</h3>
						<span className='subtitle'>di Nonis Christian</span>

						<p className='contacts'>
							+39 342 149 0413 | +39 327 621 6453
							<br />
							informazioni@servizidiamond.it
						</p>
					</div>
				</div>
				<div className='info'>
					<h4 className='title'>Sede legale:</h4>
					<span className='text'>
						Via Freschi, 4, Sesto al Reghena
						<br />
						Provincia di Pordenone (PN) 33079
					</span>
					<h4 className='title second'>Ufficio in allestimento a Udine</h4>
					<h4 className='title third'>P.IVA e CF:</h4>
					<span className='text'>IT01920250931 - NNSCRS00S12I403M</span>
				</div>
			</div>
			<div className='copyrightDiv'>
				<span className='copyright'>© 2023 by servizi diamond. </span>
			</div>
		</StyledMainFooter>
	);
};

export default MainFooter;
