import { useEffect, useRef, useState } from "react";
import { StyledInputLabel } from "./StyledInputLabel";

const InputLabel = (props) => {
	const isRequired = props.isRequired;

	const [inputEmpty, setInputEmpty] = useState(true);

	return (
		<StyledInputLabel width={props.width} isRequired={props.isRequired}>
			<div className='div'>
				{isRequired ? (
					<input
						className='input'
						required
						onChange={(input) => {
							if (input.target.value.length > 0) {
								setInputEmpty(false);
								props.inputText(input.target.value);
							}
							if (input.target.value.length === 0) {
								setInputEmpty(true);
								props.inputText(input.target.value);
							}
						}}
						placeholder={props.text}
						value={props.value}
					/>
				) : (
					<input
						className={"input" + inputEmpty ? "" : ""}
						onChange={(input) => {
							if (input.target.value.length > 0) {
								setInputEmpty(false);
								props.inputText(input.target.value);
							}
							if (input.target.value.length === 0) {
								setInputEmpty(true);
								props.inputText(input.target.value);
							}
						}}
						placeholder={props.text}
						value={props.value}
					></input>
				)}
				<div className='underline'></div>
				<label className={inputEmpty ? "" : "inputNotEmpty"}>
					{props.label}
				</label>
			</div>
		</StyledInputLabel>
	);
};

export default InputLabel;
