import styled from "styled-components";

export const StyledOrdinaVideoAds = styled.div`
	margin-bottom: 100px;

	.sectionOne {
		background: rgb(176, 63, 253);
		background: linear-gradient(
			349deg,
			rgba(176, 63, 253, 1) 0%,
			rgba(147, 63, 253, 1) 30%,
			rgba(233, 146, 251, 1) 100%
		);
		min-height: 100vh;

		@supports (clip-path: polygon(0 0)) {
			-webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 95%);
			clip-path: polygon(0 0, 100% 0, 100% 85%, 0 95%);
		}

		.firstSection {
			display: flex;
			margin-left: 8.33vw;
			margin-right: 8.33vw;
			justify-content: space-between;
			align-items: center;
			padding-top: 80px;
			height: 609px;

			@media (max-width: 979px) {
				.firstDiv {
					text-align: center;
				}
			}

			.titleFirst {
				font-size: 2.25rem;
				font-weight: 900;
				margin: 0px 0px 30px 0px;
				color: ${({ theme }) => theme.colors.secondary};
			}

			.subtitleFirst {
				font-weight: 300;
				margin: 0px 0px 0px 0px;
				color: ${({ theme }) => theme.colors.secondary};
			}

			.image {
				max-width: 40.64vw;
				@media (max-width: 979px) {
					display: none;
				}
			}

			.aButton {
				background: none;
				border: 2px solid;
				border-color: ${({ theme }) => theme.colors.secondary};
				border-radius: 25px;
				text-decoration: none;
				color: ${({ theme }) => theme.colors.secondary};
				padding: 13px 18px 13px 18px;
				font-weight: 500;
				transition: all 0.2s;

				&:hover {
					color: ${({ theme }) => theme.colors.primary};
					background: ${({ theme }) => theme.colors.secondary};
				}
			}

			.divAButton {
				margin: 43px 0px 0px 0px;
			}
		}
	}

	.second__section {
		display: flex;
		margin-inline: auto;
		padding-block: 6rem;
		align-items: center;

		@media only screen and (max-width: 979px) {
			flex-direction: column;
			text-align: center;
		}

		@media only screen and (max-width: 500px) {
			padding-block: 0;
			padding-top: 6rem;
		}

		.left-text {
			margin-left: 8.33vw;
			padding-top: 30px;
			padding-bottom: 30px;
			max-width: 100%;
			text-align: left;
			margin-right: 3vw;

			@media only screen and (max-width: 979px) {
				margin-inline: 8.33vw;
			}

			.h3 {
				font-size: 2.4rem;
				color: #933ffd;
				text-align: left;
				margin-bottom: 1vw;

				@media (max-width: 1080px) {
					font-size: 1.9rem;
				}

				@media only screen and (max-width: 979px) {
					text-align: center;
					font-size: 1.6;
					margin-bottom: 2rem;
				}
			}

			.p {
				font-weight: 300;

				@media (max-width: 1080px) {
					font-size: 0.9rem;
				}

				@media only screen and (max-width: 979px) {
					text-align: center;
				}

				@media only screen and (max-width: 500px) {
					margin-bottom: 0;
				}
			}
		}

		.right-box {
			box-shadow: 20px 20px 40px rgba(0, 0, 0, 0.2);
			border-radius: 20px;
			padding-inline: 3rem;
			padding-block: 1.4rem;
			display: flex;
			flex-direction: row;
			background: linear-gradient(
				349deg,
				rgba(176, 63, 253, 0.7) 0%,
				rgba(147, 63, 253, 0.7) 30%,
				rgba(233, 146, 251, 0.7) 100%
			);
			transition: all 0.2s;

			@media (min-width: 980px) {
				margin-right: 8.33vw;
			}

			@media only screen and (max-width: 500px) {
				flex-direction: column;
			}

			.text-left {
				margin-right: 3rem;

				.fase {
					margin: 0;
					display: flex;
					align-items: flex-start;

					.number {
						color: #eee;
						font-size: 6rem;
						line-height: 1;
						color: ${({ theme }) => theme.colors.secondary};
						opacity: 0.3;
					}

					.text {
						margin-top: 1rem;
						margin-left: -1.5rem;
						font-size: 2.3rem;
						font-weight: 600;
						color: ${({ theme }) => theme.colors.secondary};
						z-index: 0;
					}
				}

				@media only screen and (max-width: 500px) {
					margin-bottom: 3rem;
				}
			}

			.image-grid-right {
				display: grid;
				margin: auto;

				@media (max-width: 979px) {
					margin-inline: 4rem;
				}

				.image {
					&-1 {
						height: 12rem;
					}

					&-2 {
						height: 12rem;
						margin-top: -8rem;
						margin-left: 4rem;
					}
				}
			}

			@media (min-width: 979px) {
				&:hover {
					transform: scale(1.1);

					@media only screen and (max-width: 1280px) {
						transform: scale(0.8);
					}
				}
			}

			@media only screen and (max-width: 1280px) {
				transform: scale(0.7);
			}
		}
	}

	.section__third {
		padding-inline: 8.33vw;
		padding-block: 8.33vw;
		background-color: #f2f2f2;
		@supports (clip-path: polygon(0 0)) {
			-webkit-clip-path: polygon(0 0, 100% 10%, 100% 90%, 0 100%);
			clip-path: polygon(0 0, 100% 10%, 100% 90%, 0 100%);
		}

		@media (max-width: 1080px) {
			padding-bottom: 3rem;

			@supports (clip-path: polygon(0 0)) {
				-webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%);
				clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%);
			}
		}

		@media (max-width: 979px) {
			padding-bottom: 8.33vw;
		}

		.card {
			box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
			border-radius: 20px;
			background-color: #fff;
			padding-inline: 4rem;
			padding-block: 3rem;
			max-width: 1200px;

			@media (max-width: 979px) {
				padding-inline: 2rem;
				padding-block: 3rem;
			}

			.wrapper {
				display: flex;
				flex-direction: row;
				align-items: center;

				@media (max-width: 1280px) {
					flex-direction: column;
				}

				.text-left {
					font-weight: 300;
					margin-right: 3rem;

					@media (max-width: 979px) {
						margin-right: 0;
					}

					.h3__guarda-esempi {
						font-size: 2rem;
						text-align: start;
						color: ${({ theme }) => theme.colors.primary};

						margin-bottom: 1.5rem;

						@media (max-width: 979px) {
							text-align: center;
						}
					}

					.p {
						max-width: 80%;
						font-size: 0.9rem;

						@media (max-width: 1280px) {
							max-width: 100%;
						}

						@media (max-width: 979px) {
							text-align: center;
						}
					}
				}

				.video-grid {
					display: grid;
					grid-template-columns: repeat(3, 1fr);
					grid-template-rows: repeat(1, 1fr);
					grid-gap: 1rem;
					align-items: center;

					@media (max-width: 1280px) {
						margin-top: 3rem;
					}

					@media (max-width: 979px) {
						grid-template-columns: repeat(2, 1fr);
						grid-template-rows: repeat(1, 1fr);
					}

					.video {
						transition: transform 0.4s;
						clip-path: inset(0% 0% 0% 0% round 8px);
						width: fit-content;
						justify-items: center;

						@media (min-width: 979px) {
							&:hover {
								transform: scale(1.5);
								z-index: 2;
							}
						}
					}

					.vertical {
						grid-area: 1 / 1 / 3 / 2;
					}
				}
			}
		}
	}

	.section__fourth {
		margin-top: 4rem;
		margin-inline: 8.33vw;
		text-align: center;

		.h3 {
			font-size: 2rem;
			color: ${({ theme }) => theme.colors.primary};
		}

		.ul {
			display: flex;
			list-style: none;
			justify-content: center;
			margin-top: 4rem;
			text-align: center;

			@media (max-width: 979px) {
				flex-direction: column;
				align-items: center;
			}

			li {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 25rem;

				@media (max-width: 979px) {
					width: 90%;
				}

				.icon {
					margin-bottom: 1rem;

					img {
						height: 4rem;
						width: 4rem;
					}
				}

				.title {
					font-size: 1.4rem;
					margin-bottom: 0.5rem;
				}

				.body {
				}

				@media (min-width: 980px) {
					&:not(:last-child) {
						margin-right: 5vw;
					}
				}

				@media (max-width: 979px) {
					&:not(:last-child) {
						margin-bottom: 10vw;
					}
				}
			}
		}
	}

	.section__fifth {
		margin-top: 12rem;
		max-width: 1200px;
		margin-inline: 8.33vw;

		@media only screen and (min-width: 1300px) {
			margin-inline: auto;
		}

		.acquista {
			color: ${({ theme }) => theme.colors.primary};
			font-size: 2.62rem;
			margin-bottom: 3rem;
			text-align: center;
		}

		.wrapper {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			@media (max-width: 979px) {
				flex-direction: column;
				align-items: center;
			}

			.card {
				width: 32%;
				max-width: 30rem;
				box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.05);
				border-radius: 10px;
				border: 1px solid #eee;
				/* background-color: #fff9f3; */
				/* background: linear-gradient(
					349deg,
					rgba(176, 63, 253, 0.6) 0%,
					rgba(147, 63, 253, 0.6) 30%,
					rgba(233, 146, 251, 0.6) 100%
				); */
				/* background-color: #eee; */
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 2rem;
				justify-content: space-between;

				@media (max-width: 979px) {
					width: 100%;
					background-color: white;

					&:not(:last-child) {
						margin-bottom: 4rem;
					}
				}

				[class^="img-"] {
					margin-bottom: 1rem;
				}

				.img-1 {
					height: 200px;
				}

				.img-2 {
					height: 200px;
				}

				.img-3 {
					height: 200px;
				}

				.title {
					font-size: 2rem;
					color: ${({ theme }) => theme.colors.black};
				}

				.line {
					height: 0.8px;
					background-color: ${({ theme }) => theme.colors.grey};
					width: 90%;
					margin: 0.5rem;
				}

				.price {
					font-weight: 600;
					font-size: 2rem;
				}

				p {
					color: ${({ theme }) => theme.colors.black};
					text-align: center;
					font-size: 0.8rem;
					width: 80%;
					margin-top: 0.5rem;
					margin-bottom: 2rem;
				}

				.button:link,
				.button:visited {
					text-decoration: none;
					color: ${({ theme }) => theme.colors.secondary};
					background-color: ${({ theme }) => theme.colors.primary};
					padding: 0.8rem 2rem;
					border-radius: 100px;
					font-size: 1.1rem;
					transition: all 0.3s;
					font-weight: 600;
					border: 0.5px solid transparent;

					&:hover {
						color: ${({ theme }) => theme.colors.primary};
						background-color: ${({ theme }) => theme.colors.secondary};
						transform: translateY(-3px);
						box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
						border: 0.5px solid ${({ theme }) => theme.colors.primary};
					}
				}
			}
		}
	}

	.FAQ {
		display: flex;
		align-items: center;
		flex-direction: column;
		margin-top: 6rem;

		.title {
			margin-top: 100px;
			font-size: 2rem;
			display: block;
			color: ${({ theme }) => theme.colors.primary};
		}

		.text-boxes {
			.text {
				margin-left: 20vw;
				margin-right: 20vw;
				margin-top: 50px;

				@media (max-width: 979px) {
					margin-inline: 8.33vw;
				}

				h3 {
					font-size: 1.5rem;
					margin-bottom: 1rem;
				}

				p {
					font-weight: 300;
				}
			}
		}
	}
`;
