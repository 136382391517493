// import firstImage from "../../images/firstImage.png";
// import ordinaPromuoviVendiImg from "../../images/OrdinaPromuoviVendiImag.png";

import ReactPlayer from "react-player/lazy";
import { useState, useEffect, useContext } from "react";
import { headerContext } from "../../context/headerContext";
import { useTranslation } from "react-i18next";

import video1Ads from "../../images/video1Ads.png";
import video2Ads from "../../images/video2Ads.png";
import video3Ads from "../../images/video3Ads.png";
import promuovi from "../../images/promuovi.png";
import co_working from "../../images/OrdinaVideoAds/co-working.png";
import graph from "../../images/OrdinaVideoAds/graph.png";
import projector from "../../images/OrdinaVideoAds/projector.png";

import { StyledOrdinaVideoAds } from "./StyledOrdinaVideoAds";

const OrdinaVideoAds = () => {
	const [isMobile, setIsMobile] = useState(false);

	const headerCtx = useContext(headerContext);
	headerCtx.handleTransparency(true);
	headerCtx.handleHeaderColors(false);

	const { t } = useTranslation();

	const handleResize = () => {
		if (window.innerWidth < 980) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
		return;
	};

	useEffect(() => {
		handleResize();
		window.addEventListener("resize", handleResize);
	}, []);

	return (
		<StyledOrdinaVideoAds>
			<section className='sectionOne'>
				<div className='firstSection'>
					<div className='firstDiv'>
						<h1 className='titleFirst'>{t("orderVideoAdsPageHeader.1")}</h1>
						<p className='subtitleFirst'>{t("orderVideoAdsPageHeader.2")}</p>
						<div className='divAButton'>
							<a href='#ConsegnaEntro72Ore' className='aButton'>
								{t("orderVideoAdsPageHeader.3")} ›
							</a>
						</div>
					</div>
					<img
						className='image'
						src={require("../../images/firstImage.png")}
						alt='video making draw'
					/>
				</div>
			</section>
			<section id='ConsegnaEntro72Ore' className='second__section'>
				<div className='left-text'>
					<h3 className='h3'>{t("orderVideoAdsPageSectionSecond.1")}</h3>
					<p className='p'>
						{t("orderVideoAdsPageSectionSecond.2")}
						{/*​ I pacchetti classici che troverai qui sotto includono
						video realizzati con materiale trovato online e montato insieme per
						realizzare le creative di cui hai bisogno.
						al momento stiamo
						lavorando per offrire anche la possibilità di realizzare video
						realizzati nel nostro studio e quindi con un'aspetto ed una
						flessibilità sullo script e sulle scene maggiore, se vuoi rimanere
						aggiornato su quando renderemo disponibile questo nuovo servizio
						lasciaci la tua mail e ti contatteremo noi. */}
					</p>
				</div>
				<div className='right-box'>
					<div className='text-left'>
						<h2 className='fase fase-1'>
							<span className='fase-1 number'>1</span>
							<span className='fase-1 text'>
								{t("orderVideoAdsPageSectionSecond.3")}
							</span>
						</h2>
						<h2 className='fase fase-2'>
							<span className='fase-2 number'>2</span>
							<span className='fase-2 text'>
								{t("orderVideoAdsPageSectionSecond.4")}
							</span>
						</h2>
						<h2 className='fase fase-3'>
							<span className='fase-3 number'>3</span>
							<span className='fase-3 text'>
								{t("orderVideoAdsPageSectionSecond.5")}
							</span>
						</h2>
					</div>
					<div className='image-grid-right'>
						<img src={video1Ads} className='image image-1' alt='video ad' />
						<img
							src={promuovi}
							className='image image-2'
							alt='instagram feed'
						/>
					</div>
				</div>
			</section>
			<section className='section__third'>
				<div className='card'>
					<div className='wrapper'>
						<div className='text-left'>
							<h3 className='h3__guarda-esempi'>
								{t("orderVideoAdsPageThirdSection.1")}
							</h3>
							<p className='p'>
								{t("orderVideoAdsPageThirdSection.2")}
								<a
									href='https://servizidiamond.it/crea-il-tuo-logo-online'
									className='cliccandoQui'
								>
									{t("orderVideoAdsPageThirdSection.3")}
								</a>
								.
							</p>
						</div>
						<div className='video-grid'>
							<div className='video video-3 vertical'>
								<ReactPlayer
									url='https://firebasestorage.googleapis.com/v0/b/servizi-diamond.appspot.com/o/video-website%2F20220626-125335-408.mp4?alt=media&token=8d42a655-aa3e-49f8-b763-4b5f7bba9df8'
									controls={true}
									width={isMobile ? "30vw" : "auto"}
									height={isMobile ? "auto" : "24vw"}
								/>
							</div>
							<div className='video video-1'>
								<ReactPlayer
									url='https://firebasestorage.googleapis.com/v0/b/servizi-diamond.appspot.com/o/video-website%2F20220704-221523-012%20(1).mp4?alt=media&token=b9ac4c1b-7f86-4e02-9e95-cc17d5693c6c'
									controls={true}
									width={isMobile ? "30vw" : "12vw"}
									height={isMobile ? "30vw" : "12vw"}
								/>
							</div>
							<div className='video video-2'>
								<ReactPlayer
									url='https://firebasestorage.googleapis.com/v0/b/servizi-diamond.appspot.com/o/video-website%2F20220626-235421-147.mp4?alt=media&token=05ae5c84-e255-45b6-9065-8907cf8930bb'
									controls={true}
									width={isMobile ? "30vw" : "12vw"}
									height={isMobile ? "30vw" : "12vw"}
								/>
							</div>
							<div className='video video-4'>
								<ReactPlayer
									url='https://firebasestorage.googleapis.com/v0/b/servizi-diamond.appspot.com/o/video-website%2F20220626-110042-968.mp4?alt=media&token=c4e5dda7-714b-4920-9e34-1a6848cd04b0'
									controls={true}
									width={isMobile ? "30vw" : "12vw"}
									height={isMobile ? "30vw" : "12vw"}
								/>
							</div>
							<div className='video video-5'>
								<ReactPlayer
									url='https://firebasestorage.googleapis.com/v0/b/servizi-diamond.appspot.com/o/video-website%2F20220630-105300-473.mp4?alt=media&token=f60c413f-3912-47b4-972b-feced0035d93'
									controls={true}
									width={isMobile ? "30vw" : "12vw"}
									height={isMobile ? "30vw" : "12vw"}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className='section__fourth'>
				<h3 className='h3'>{t("orderVideoAdsPageFourthSection.1")}</h3>
				<ul className='ul'>
					<li>
						<div className='icon'>
							<img src={graph} alt='' />
						</div>
						<h3 className='title'>{t("orderVideoAdsPageFourthSection.2")}</h3>
						<p className='body'>{t("orderVideoAdsPageFourthSection.3")}</p>
					</li>
					<li>
						<div className='icon'>
							<img src={projector} alt='' />
						</div>
						<h3 className='title'>{t("orderVideoAdsPageFourthSection.4")}</h3>
						<p className='body'>{t("orderVideoAdsPageFourthSection.5")}</p>
					</li>
					<li>
						<div className='icon'>
							<img src={co_working} alt='' />
						</div>
						<h3 className='title'>{t("orderVideoAdsPageFourthSection.6")}</h3>
						<p className='body'>{t("orderVideoAdsPageFourthSection.7")}</p>
					</li>
				</ul>
			</section>
			<section className='section__fifth'>
				{/* <h2 className='acquista'>Acquista</h2> */}
				<div className='wrapper'>
					<div className='card card-1'>
						<img src={video1Ads} alt='1 video' className='img-1' />
						<h3 className='title'>{t("orderVideoAdsPageFifthSection.1")}</h3>
						<div className='line'>&nbsp;</div>
						<p className='paragraph'>{t("orderVideoAdsPageFifthSection.2")}</p>
						<p className='price'>€29</p>
						<a
							className='button'
							href='https://checkout.stripe.com/pay/plink_1LGjzXKVkXhgqG7axU307svD#fidkdWxOYHwnPyd1blppbHNgWjA0STxGSU9OU25dbWJ0QjJkTlZ%2FYjdATXJxPF00VnFRSDBNX0l2aEZNc25uY0pSU21ybXxuaE03TW9kSlRtMDUyV2xHMz1BaTFdandOYnNJQVFGMFBxUUdwNTVnUDFQbXBHVycpJ3VpbGtuQH11anZgYUxhJz8nPXJINmNpZG1qMnduZ3ZMM2p2J3gl'
						>
							{t("orderVideoAdsPageFifthSection.7")}
						</a>
					</div>
					<div className='card card-2'>
						<img src={video2Ads} alt='2 video' className='img-2' />
						<h3 className='title'>{t("orderVideoAdsPageFifthSection.3")}</h3>
						<div className='line'>&nbsp;</div>
						<p className='paragraph'>{t("orderVideoAdsPageFifthSection.4")}</p>
						<p className='price'>€59</p>
						<a
							className='button'
							href='https://buy.stripe.com/bIY17dgFM270bsI9AF'
						>
							{t("orderVideoAdsPageFifthSection.7")}
						</a>
					</div>
					<div className='card card-3'>
						<img src={video3Ads} alt='3 video' className='img-3' />
						<h3 className='title'>{t("orderVideoAdsPageFifthSection.5")}</h3>
						<div className='line'>&nbsp;</div>
						<p className='paragraph'>{t("orderVideoAdsPageFifthSection.6")}</p>
						<p className='price'>€72</p>
						<a
							className='button'
							href='https://checkout.stripe.com/pay/plink_1LGm9rKVkXhgqG7aIrLSa006#fidkdWxOYHwnPyd1blppbHNgWjA0STxGSU9OU25dbWJ0QjJkTlZ%2FYjdATXJxPF00VnFRSDBNX0l2aEZNc25uY0pSU21ybXxuaE03TW9kSlRtMDUyV2xHMz1BaTFdandOYnNJQVFGMFBxUUdwNTVnUDFQbXBHVycpJ3VpbGtuQH11anZgYUxhJz8nMG5EPX9DY0dMMWM9Y0xcY18wJ3gl'
						>
							{t("orderVideoAdsPageFifthSection.7")}
						</a>
					</div>
				</div>
			</section>
			<section className='FAQ'>
				<h2 className='title'>FAQ</h2>
				<div className='text-boxes'>
					<div className='text'>
						<h3>{t("orderVideoAdsPageFAQ.1")}</h3>
						<p>
							{t("orderVideoAdsPageFAQ.2.1")}
							<br />
							<br />
							{t("orderVideoAdsPageFAQ.2.2")}
							<br />
							{t("orderVideoAdsPageFAQ.2.3")}
							<br />
							{t("orderVideoAdsPageFAQ.2.4")}
							<br />
							<br />
							{t("orderVideoAdsPageFAQ.2.5")}
							<br />
							<br />
							{t("orderVideoAdsPageFAQ.2.6")}
							<br />
							<br />
							{t("orderVideoAdsPageFAQ.2.7")}
						</p>
					</div>
					{/* <div className='text'>
						<h3>
							Se ho cambiato idea o non sono soddisfatto posso richiedere il
							rimborso?
						</h3>
						<p>
							Certo, siamo a tua completa disposizione in caso di problemi per
							aiutarti a risolverli insieme, se non dovessimo riuscire a
							soddisfare le tue esigenze potrai tranquillamente richiedere il
							rimborso entro 14 giorni dall'acquisto contattandoci su:
							<br />
							<br />
							- Whatsapp +39 327 621 6453
							<br />- Email informazioni@servizidiamond.it
						</p>
					</div> */}
				</div>
			</section>
		</StyledOrdinaVideoAds>
	);
};

export default OrdinaVideoAds;
