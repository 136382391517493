import { StyledOrdiniModuloSitoWeb } from "./StyledOrdiniModuloSitoWeb";
import { useState, useRef, useEffect, useContext } from "react";
import { realtimeDatabase } from "../../../../firebase/realtimeFir";
import { ref, onValue, update, remove } from "firebase/database";
import storageRef from "../../../../firebase/storageFir";
import { deleteObject, ref as sRef } from "firebase/storage";

import Card from "../../../../components/Card/Card";
import { useAuth } from "../../../../context/authContext";
import { RangeSliderLRValues } from "../../../../components/SliderRange/SliderRange";

const OrdiniModuloSitoWeb = () => {
	const [orderList, setOrderList] = useState([]);
	const [mostraDettagli, setMostraDettagli] = useState(false);
	const [selectedDettagli, setSelectedDettagli] = useState();
	const [mostraCompletati, setMostraCompletati] = useState(false);
	const [finalOrderList, setFinalOrderList] = useState([]);
	const [procediEliminazione, setProcediEliminazione] = useState(false);
	const [modalEliminazione, setModalEliminazione] = useState(false);

	let firstUpdate = useRef(0);

	const ordiniRef = ref(realtimeDatabase, "ordinazioneSitoWeb/modulo/ordini");

	const handleMostraCompletati = () => {
		setMostraCompletati(!mostraCompletati);
	};

	useEffect(() => {
		orderList.map((i) => {
			if (i.isCompleted !== true) {
				return;
			} else {
				if (i.fileOneName !== "none") {
					const fileOneRef = sRef(
						storageRef,
						`modulo-creazione-sito-web/immagini-inviate/${i.mail}/_${i.dateKey}_${i.fileOneName}`
					);
					console.log(fileOneRef);
					deleteObject(fileOneRef).then(() => {
						console.log("deleted first");
					});
				}
				if (i.fileTwoName !== "none") {
					const fileTwoRef = sRef(
						storageRef,
						`modulo-creazione-sito-web/immagini-inviate/${i.mail}/_${i.dateKey}_${i.fileTwoName}`
					);
					console.log(fileTwoRef);
					deleteObject(fileTwoRef).then(() => {
						console.log("deleted second");
					});
				}

				remove(
					ref(realtimeDatabase, `ordinazioneSitoWeb/modulo/ordini/${i.dateKey}`)
				);
			}
		});

		setProcediEliminazione(false);
	}, [procediEliminazione]);

	useEffect(() => {
		if (firstUpdate.current < 1) {
			firstUpdate.current += 1;
			return;
		}
		onValue(ordiniRef, (snapshot) => {
			setOrderList((ov) => []);
			snapshot.forEach((childSnapshot) => {
				const childData = childSnapshot.val();
				setOrderList((prev) => [...prev, childData]);
			});
		});
	}, []);

	useEffect(() => {
		if (mostraCompletati) {
			setFinalOrderList(orderList);
		} else {
			setFinalOrderList(orderList.filter((ordine) => !ordine.isCompleted));
		}
	}, [mostraCompletati, orderList]);

	return (
		<StyledOrdiniModuloSitoWeb>
			{modalEliminazione && (
				<div className='overlayModal'>
					<div className='modal'>
						<h3>Aspetta!</h3>
						<span className='span'>
							Sei sicuro di voler Procedere?
							<br />I dati saranno eliminati definitivamente
						</span>
						<div className='buttonsWrapper'>
							<button
								className='ok'
								onClick={() => {
									setProcediEliminazione(true);
									setModalEliminazione(false);
								}}
							>
								OK
							</button>
							<button
								className='annulla'
								onClick={() => {
									setModalEliminazione(false);
								}}
							>
								Annulla
							</button>
						</div>
					</div>
				</div>
			)}
			{!mostraDettagli && (
				<div className='mainWrapper'>
					<span className='elCo' onClick={() => setModalEliminazione(true)}>
						Elimina Completati
					</span>
					<div className='firstWrapper'>
						<h2>Ordini</h2>
						<div className='wrapperFilter'>
							<span
								className='completedSelector'
								onClick={handleMostraCompletati}
							>
								{mostraCompletati ? "Nascondi Completati" : "Mostra Completati"}
							</span>
						</div>
					</div>
					<div className='ordiniWrapper'>
						{finalOrderList.map((i) => (
							<div key={i.dateKey} className='cardWrapper'>
								<Card height='auto' width='100%'>
									<div className='div'>
										<div className='titleDiv'>
											<span className='title'>{`${i.mail} | ${i.dateKey}`}</span>
											<span
												className='mostraDettagli'
												onClick={() => {
													setMostraDettagli(true);
													setSelectedDettagli(i);
												}}
											>
												Mostra dettagli &rsaquo;
											</span>
										</div>
										<div className='completedDiv'>
											<div
												className={
													i.isCompleted
														? "circleIndicatorBlue"
														: "circleIndicatorRed"
												}
											/>
											<span>
												{i.isCompleted
													? "Ordine completato"
													: "Ordine non completato"}
											</span>
										</div>
									</div>
								</Card>
							</div>
						))}
					</div>
				</div>
			)}
			{mostraDettagli && (
				<div className='mainDettagliWrapper'>
					<div className='indietroDiv'>
						<span onClick={() => setMostraDettagli(false)} className='indietro'>
							&lsaquo; Indietro
						</span>
						<span
							onClick={() => {
								update(
									ref(
										realtimeDatabase,
										`ordinazioneSitoWeb/modulo/ordini/${selectedDettagli.dateKey}`
									),
									{ isCompleted: true }
								);

								console.log("remove");
								setMostraDettagli(false);
							}}
						>
							Segna come completato &#x2715;
						</span>
					</div>
					<Card height='auto'>
						<div className='insideWrapper'>
							<span className='number'>{selectedDettagli.dateKey}</span>
						</div>
						{selectedDettagli.sitePack === "CMS_rev" && (
							<div className='columnWrapper'>
								<div className='left'>
									<div className='wrapper'>
										<h2 className='h2'>Email</h2>
										<p className='p'>{selectedDettagli.mail}</p>
									</div>
									<div className='wrapper'>
										<h2 className='h2'>Idea Revisione</h2>
										<p className='p'>{selectedDettagli.ideaRevisione}</p>
									</div>
									<div className='wrapper'>
										<h2 className='h2'>Siti Ispirazione</h2>
										<p className='p'>{selectedDettagli.sitiIspirazione}</p>
									</div>
									<div className='wrapper'>
										<h2 className='h2'>Link Sito</h2>
										<a className='a' href={selectedDettagli.linkSito}>
											{selectedDettagli.linkSito}
										</a>
									</div>
								</div>
								<div className='right'>
									<div className='wrapper'>
										<h2 className='h2'>Dettagli Extra</h2>
										<p className='p'>{selectedDettagli.sitiIspirazione}</p>
									</div>
									<div className='wrapper'>
										<h2 className='h2'>Contatti</h2>
										<p className='p'>{selectedDettagli.contatti}</p>
									</div>
									<div className='wrapper'>
										<h2 className='h2'>Pacchetto</h2>
										<p className='p'>{selectedDettagli.sitePack}</p>
									</div>
									<div className='wrapper'>
										<h2 className='h2'>CMS</h2>
										<p className='p'>{selectedDettagli.cms}</p>
									</div>
								</div>
							</div>
						)}
						{(selectedDettagli.sitePack === "CMS_5pag" ||
							selectedDettagli.sitePack === "CC_sP") && (
							<div className='columnWrapper'>
								<div className='left'>
									<div className='wrapper'>
										<h2 className='h2'>Email</h2>
										<p className='p'>{selectedDettagli.mail}</p>
									</div>
									<div className='wrapper'>
										<h2 className='h2'>Colori</h2>
										<p className='p'>{selectedDettagli.colori}</p>
									</div>
									<div className='wrapper'>
										<h2 className='h2'>Idea Sito</h2>
										<p className='p'>{selectedDettagli.ideaSito}</p>
									</div>
									<div className='wrapper'>
										<h2 className='h2'>Nome Sito</h2>
										<p className='p'>{selectedDettagli.nomeSito}</p>
									</div>
									<div className='wrapper'>
										<h2 className='h2'>Siti Ispirazione</h2>
										<p className='p'>{selectedDettagli.sitiIspirazione}</p>
									</div>
									<h2 className='h2-title-inside'>Pagine</h2>
									<div className='section-inside'>
										<div className='wrapper'>
											<h2 className='h2'>Titolo Pagina 1</h2>
											<p className='p'>{selectedDettagli.pagine.titoloPag1}</p>
										</div>
										<div className='wrapper'>
											<h2 className='h2'>Descrizione Pagina 1</h2>
											<p className='p'>{selectedDettagli.pagine.descrPag1}</p>
										</div>
									</div>
									<div className='section-inside'>
										<div className='wrapper'>
											<h2 className='h2'>Titolo Pagina 2</h2>
											<p className='p'>{selectedDettagli.pagine.titoloPag2}</p>
										</div>
										<div className='wrapper'>
											<h2 className='h2'>Descrizione Pagina 2</h2>
											<p className='p'>{selectedDettagli.pagine.descrPag2}</p>
										</div>
									</div>
									<div className='section-inside'>
										<div className='wrapper'>
											<h2 className='h2'>Titolo Pagina 3</h2>
											<p className='p'>{selectedDettagli.pagine.titoloPag3}</p>
										</div>
										<div className='wrapper'>
											<h2 className='h2'>Descrizione Pagina 3</h2>
											<p className='p'>{selectedDettagli.pagine.descrPag3}</p>
										</div>
									</div>
									<div className='section-inside'>
										<div className='wrapper'>
											<h2 className='h2'>Titolo Pagina 4</h2>
											<p className='p'>{selectedDettagli.pagine.titoloPag4}</p>
										</div>
										<div className='wrapper'>
											<h2 className='h2'>Descrizione Pagina 4</h2>
											<p className='p'>{selectedDettagli.pagine.descrPag4}</p>
										</div>
									</div>
									<div className='section-inside'>
										<div className='wrapper'>
											<h2 className='h2'>Titolo Pagina 5</h2>
											<p className='p'>{selectedDettagli.pagine.titoloPag5}</p>
										</div>
										<div className='wrapper'>
											<h2 className='h2'>Descrizione Pagina 5</h2>
											<p className='p'>{selectedDettagli.pagine.descrPag5}</p>
										</div>
									</div>
								</div>
								<div className='right'>
									<div className='wrapper'>
										<h2 className='h2'>File One</h2>
										<a href={selectedDettagli.fileOne} target='_blank'>
											{selectedDettagli.fileOne}
										</a>
									</div>
									<div className='wrapper'>
										<h2 className='h2'>File Two</h2>
										<a href={selectedDettagli.fileTwo} target='_blank'>
											{selectedDettagli.fileTwo}
										</a>
									</div>
									<div className='wrapper'>
										<h2 className='h2'>Dettagli Extra</h2>
										<p className='p'>{selectedDettagli.dettagliExtra}</p>
									</div>
									<div className='wrapper'>
										<h2 className='h2'>Contatti</h2>
										<p className='p'>{selectedDettagli.contatti}</p>
									</div>
									<div className='wrapper'>
										<h2 className='h2'>Pacchetto</h2>
										<p className='p'>{selectedDettagli.sitePack}</p>
									</div>
									<div className='wrapper'>
										<h2 className='h2'>CMS</h2>
										<p className='p'>{selectedDettagli.cms}</p>
									</div>
									<div className='section-inside'>
										<h2 className='h2-title-inside'>Slider</h2>
										<RangeSliderLRValues
											value={10} // selectedDettagli.slider.sliderFM
											disabled={true}
											left='Femminile'
											right='Maschile'
										/>
										<RangeSliderLRValues
											value={selectedDettagli.slider.sliderShape} // selectedDettagli.slider.sliderShape
											disabled={true}
											left='Curvo'
											right='Squadrato'
										/>
										<RangeSliderLRValues
											value={selectedDettagli.slider.classicoModerno} // selectedDettagli.slider.classicoModerno
											disabled={true}
											left='Classico'
											right='Moderno'
										/>
										<RangeSliderLRValues
											value={selectedDettagli.slider.giocosoSerio} // selectedDettagli.slider.giocosoSerio
											disabled={true}
											left='Giocoso'
											right='Serio'
										/>
										<RangeSliderLRValues
											value={selectedDettagli.slider.coloratoPulito} // selectedDettagli.slider.coloratoPulito
											disabled={true}
											left='Colorato'
											right='Pulito'
										/>
										<RangeSliderLRValues
											value={selectedDettagli.slider.sempliceComplesso} // selectedDettagli.slider.sempliceComplesso
											disabled={true}
											left='Semplice'
											right='Complesso'
										/>
									</div>
								</div>
							</div>
						)}
					</Card>
				</div>
			)}
		</StyledOrdiniModuloSitoWeb>
	);
};

export default OrdiniModuloSitoWeb;
