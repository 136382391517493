import styled from "styled-components";

export const StyledOrdiniModuloVideoAds = styled.div`
	/* margin-inline: 8.33vw; */
	min-height: 100vh;
	width: 80%;

	.divRev {
		margin-top: 150px;
		width: 100%;
		text-align: center;

		.spanRev {
			font-size: 2rem;
		}
	}

	.overlayModal {
		position: fixed;
		width: 100vw;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		z-index: 10;
		display: flex;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;

		.modal {
			display: grid;
			background: #f4f4f4;
			align-items: center;
			justify-items: center;
			margin: auto;
			border-radius: 10px;
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

			h3 {
				font-size: 2rem;
				margin-bottom: 20px;
				margin-top: 40px;
			}

			.span {
				font-weight: lighter;
				margin-inline: 80px;
				text-align: center;
			}

			.buttonsWrapper {
				display: flex;

				button {
					border-radius: 8px;
					background: ${({ theme }) => theme.colors.primary};
					color: #fff;
					border: 1px solid ${({ theme }) => theme.colors.primary};
					padding: 8px 20px 8px 20px;
					font-size: 1.05rem;
					margin-bottom: 40px;
					margin-top: 40px;
					margin-inline: 10px;

					&:hover {
						/* border-radius: 30px; */
						background: #fff;
						border: 1px solid ${({ theme }) => theme.colors.primary};
						color: ${({ theme }) => theme.colors.primary};
						cursor: pointer;
					}
				}

				.annulla {
					background: none;
					color: ${({ theme }) => theme.colors.primary};

					&:hover {
						background: ${({ theme }) => theme.colors.primary};
						color: ${({ theme }) => theme.colors.secondary};
					}
				}
			}
		}
	}

	.elCo {
		color: red;
		cursor: pointer;
	}

	.firstWrapper {
		display: flex;
		h2 {
			font-size: 3rem;
			font-weight: 700;
			margin-bottom: 0;
		}
	}

	.mainWrapper {
		/* width: 900px; */

		margin-top: 100px;
		margin-inline: 8.33vw;
		padding-bottom: 100px;

		.wrapperFilter {
			width: 100%;
			align-self: flex-end;
			text-align: right;

			span {
				cursor: pointer;
			}
		}

		.ordiniWrapper {
			margin-top: 20px;
			.cardWrapper {
				margin-block: 10px;
				.div {
					padding-block: 30px;
					padding-inline: 60px;

					.titleDiv {
						display: flex;
						justify-content: space-between;

						.title {
							font-size: 1.5rem;
							font-weight: 600;
						}

						span {
							align-self: center;
							cursor: pointer;
						}

						.mostraDettagli {
							color: ${({ theme }) => theme.colors.primary};

							&:hover {
								opacity: 60%;
							}
						}
					}

					.completedDiv {
						display: flex;
						margin-top: 15px;

						.circleIndicatorRed {
							border-radius: 100%;
							background: red;
							height: 30px;
							width: 30px;
							margin: 0;
							margin-right: 15px;
							padding: 0;
						}

						.circleIndicatorBlue {
							border-radius: 100%;
							background: blue;
							height: 30px;
							width: 30px;
							margin: 0;
							margin-right: 15px;
							padding: 0;
						}

						span {
							align-self: center;
							font-weight: lighter;
						}
					}
				}
			}
		}
	}

	.mainDettagliWrapper {
		width: 80%;

		margin-top: 50px;
		margin-bottom: 100px;
		margin-inline: 100px;

		.indietroDiv {
			margin-bottom: 20px;
			display: flex;
			justify-content: space-between;

			.indietro {
				font-size: 1.2rem;
				margin-bottom: 15px;
				cursor: pointer;
				color: ${({ theme }) => theme.colors.primary};

				&:hover {
					opacity: 60%;
				}
			}

			span:hover {
				opacity: 60%;
				cursor: pointer;
			}
		}

		.insideWrapper {
			padding-block: 30px;
			padding-inline: 60px;

			.number {
				font-size: 2rem;
				font-weight: 600;
			}
		}

		.columnWrapper {
			display: flex;
			justify-content: space-between;
			margin-inline: 60px;
			padding-bottom: 30px;
			padding-inline: 30px;

			.left {
				width: 50%;
			}

			.right {
				width: 50%;
			}

			.spanDiv {
				margin-top: 20px;
			}

			.pDiv {
				margin-top: 10px;
				p,
				a {
					font-weight: lighter;
				}
			}
		}
	}
`;
