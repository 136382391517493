import { initializeApp } from "firebase/app";
import { getStorage, ref } from "firebase/storage";
import { firebaseConfig } from "./firConfig";

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Storage and get a reference to the service
const storage = getStorage(app);
const storageRef = ref(storage);

export default storageRef;
