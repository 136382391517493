import { StyledCard } from "./StyledCard";

const Card = (props) => {
	return (
		<StyledCard color={props.color} height={props.height} width={props.width}>
			{props.children}
		</StyledCard>
	);
};

export default Card;
