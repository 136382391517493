import styled from "styled-components";
import img from "../../images/homepage-background-top.png";

export const StyledHome = styled.div`
	overflow-anchor: none;

	.divImageTop {
		min-height: 120vh;
		display: grid;
		background-image: url(${img});
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 0 -50px;

		@media (max-width: 860px) {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		.divText {
			margin-inline: 8.33vw;
			position: absolute;
			top: 30%;
			animation: titleFall 1s ease-in-out;

			@media (max-width: 860px) {
				text-align: center;
				top: 25%;
			}

			h2 {
				font-size: 4.4rem;
				margin: 0;
				color: #333;

				@media (max-width: 1280px) {
					font-size: 4rem;
				}

				@media (max-width: 1080px) {
					font-size: 3.5rem;
				}

				@media (max-width: 860px) {
					font-size: 3rem;
				}

				@media (max-width: 400px) {
					font-size: 2.8rem;
				}
			}

			p {
				margin-top: 1rem;
				letter-spacing: 0.5px;
				font-family: "Avenir";
				max-width: 45vw;
				font-size: 1.3rem;
				font-weight: 200;
				color: #444;

				@media (max-width: 860px) {
					max-width: 100%;
					padding-inline: 10vw;
					margin-top: 2rem;
					font-size: 1.1rem;
				}
			}

			.buttonScopri {
				background: ${({ theme }) => theme.colors.primary};
				border: 1px solid ${({ theme }) => theme.colors.primary};
				color: ${({ theme }) => theme.colors.secondary};
				padding: 1rem 2rem;
				border-radius: 30px;
				margin-top: 3rem;
				font-size: 1.02rem;
				font-weight: 400;
				cursor: pointer;
				transition: all 0.2s;

				@media (max-width: 1080px) {
					background: ${({ theme }) => theme.colors.secondary};
					border: 1px solid ${({ theme }) => theme.colors.primary};
					color: ${({ theme }) => theme.colors.primary};
				}

				&:hover {
					background: ${({ theme }) => theme.colors.secondary};
					color: ${({ theme }) => theme.colors.primary};
					transform: scale(1.01) translateY(-3px);
					box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
				}

				&:active {
					background: ${({ theme }) => theme.colors.secondary};
					color: ${({ theme }) => theme.colors.primary};
					transform: scale(0.98) translateY(0px);
					box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
				}
			}
		}
	}

	//MARK: first section
	//* FIRST SECTION ----------------------------------------------------------------------------------------------
	.section-first {
		background-color: white;
		width: 100%;
		box-shadow: -10px 0px 40px rgba(0, 0, 0, 0.1);
		padding: 4rem 2vw 4rem 8.33vw;
		display: grid;
		grid-template-columns: 38.2% 61.8%;
		grid-template-rows: min-content min-content;
		border-radius: 10000px 10000px 0 0;
		padding-top: 18vw;
		position: relative;
		z-index: 1;

		@media (max-width: 1080px) {
			grid-template-rows: 20rem min-content;
			padding-bottom: 12rem;
		}

		@media (max-width: 980px) {
			grid-template-rows: 10rem repeat(2, min-content);
			grid-template-columns: 100%;
			text-align: center;
			padding-bottom: 12rem;
			padding-inline: 8.33vw;
		}
	}

	.left-text-wrapper {
		display: flex;
		justify-content: center;
		flex-direction: column;

		/* @media (max-width: 1080px) {
			padding-inline: 10vw;
			padding-bottom: 4rem;
		} */

		@media (max-width: 600px) {
			margin-top: 9rem;
		}

		.h3 {
			font-size: 2.8rem;
			margin-bottom: 1.5rem;
			color: ${({ theme }) => theme.colors.black};

			@media (max-width: 860px) {
				margin-top: 4rem;
			}

			@media (max-width: 600px) {
				margin-top: 8rem;
			}
		}

		.p {
			.span-p {
				font-weight: 300;

				span {
					color: ${({ theme }) => theme.colors.primary};
					font-weight: 400;
				}
			}
		}
	}

	.right-images-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;

		@media (max-width: 980px) {
			grid-area: 3 / 1 / 4 / 2;
		}

		@media (max-width: 600px) {
			padding-top: 34rem;
			padding-bottom: 18rem;
		}

		#blob {
			position: relative;
		}

		.text-box {
			position: absolute;
			width: 30rem;
			padding: 1.5rem;
			background-color: white;
			border-radius: 16px;
			box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
			display: grid;
			grid-template-columns: min-content 20rem;
			grid-template-rows: min-content max-content;

			@media (max-width: 1079px) {
				width: 40vw;
				grid-template-columns: min-content 28vw;
			}

			@media (max-width: 980px) {
				width: 70vw;
				grid-template-columns: min-content auto;
				padding-right: 2rem;
				text-align: left;
			}

			@media (max-width: 600px) {
				width: 90vw;
			}

			.img {
				grid-area: 1 / 1 / 3 / 1;
				width: 3rem;
				height: 3rem;
				margin-right: 1rem;
				align-self: center;
			}

			.h2 {
				color: ${({ theme }) => theme.colors.black};
			}

			.p {
				font-size: 0.8rem;
				color: #999;
			}

			&--1 {
				animation: leftRight1 4s infinite 0.6s;
			}

			&--2 {
				animation: leftRight2 4s infinite 0.3s;
			}

			&--3 {
				animation: leftRight3 4s infinite;
			}
		}
	}

	//MARK: second section
	//* SECOND SECTION ----------------------------------------------------------------------------------------------
	.section-second {
		position: relative;
		z-index: 1;

		.background {
			height: 650px;
			width: 100%;
			background-color: #f4f4f4;
			display: flex;
			justify-content: center;
			transform: skewY(6deg);
			margin-top: -8rem;

			@media (max-width: 1080px) {
				height: auto;
				padding-bottom: 8rem;
			}

			.wrapper {
				transform: skewY(-6deg);
				display: flex;
				width: 100%;
				padding-inline: 8.33vw;
				align-items: center;

				@media (max-width: 1080px) {
					transform: skewY(-6deg) translateY(-5rem);
				}

				@media (max-width: 860px) {
					padding-top: 10rem;
					flex-direction: column;
				}

				.left-wrapper {
					@media (min-width: 1081px) {
						transform: translateY(-10rem);
					}

					@media (max-width: 1080px) {
						transform: scale(0.8) translateX(-5rem);
						width: 50%;
					}

					@media (max-width: 500px) {
						transform: scale(0.6) translateX(-8rem);
					}

					.rocket {
						height: 20rem;
						width: auto;
						margin-right: 4rem;
						transform: translateY(13rem);

						@media (max-width: 1080px) {
							margin-right: 0;
						}
					}

					.star {
						position: absolute;
						height: 5rem;
						width: auto;

						&--1 {
							transform: translate(2rem, 7rem);
						}

						&--2 {
							transform: translate(17rem, 3rem);
						}

						&--3 {
							transform: translate(-9rem, 29rem);

							@media (max-width: 600) {
								transform: translate(18rem, 10rem);
							}
						}
					}
				}

				.right-wrapper {
					display: flex;
					flex-direction: column;
					justify-content: center;

					@media (max-width: 1080px) {
						margin-top: 16rem;
					}

					@media (max-width: 860px) {
						text-align: center;
						align-items: center;
						margin-top: 12rem;
					}

					@media (max-width: 500px) {
						margin-top: 7rem;
					}

					.h2 {
						font-size: 2.8rem;
					}

					.p {
						margin-top: 1rem;

						@media (max-width: 860px) {
							margin-top: 1rem;
						}
					}

					.p-ct {
						width: 80%;
						font-weight: 300;
						margin-top: 1rem;

						@media (max-width: 860px) {
							margin-inline: 30%;
							width: 100%;
						}
					}
				}
			}
		}
	}

	//MARK: second third
	//* THIRD SECTION ----------------------------------------------------------------------------------------------
	.section-third {
		.wrapper {
			background-color: white;
			margin-top: -10rem;
			padding-top: 10rem;
			padding-inline: 8.33vw;
			display: flex;
			align-items: center;
			padding-bottom: 5rem;
			padding-top: 15rem;

			@media (max-width: 860px) {
				flex-direction: column;
				text-align: center;
			}

			.left-wrapper {
				width: 38.2%;

				@media (max-width: 860px) {
					width: 25rem;
					padding-block: 4rem;
				}

				.logos-grid {
					opacity: 0.7;
					width: 100%;
					height: auto;
					padding-right: 1rem;

					@media (max-width: 860px) {
						padding-right: 0;
						padding-left: 3rem;
					}
				}
			}

			.right-wrapper {
				width: 61.8%;
				padding-left: 1rem;

				@media (max-width: 860px) {
					width: 100%;
				}

				.h2 {
					font-size: 2.8rem;
					margin-top: -1rem;
				}

				.p {
					margin-top: 1rem;
				}

				.p-ct {
					font-weight: 300;
					margin-top: 1rem;
				}
			}
		}
	}

	//MARK: second fourth
	//* FOURTH SECTION ----------------------------------------------------------------------------------------------
	.section-fourth {
		padding-inline: 2vw;
		padding-block: 4rem;
		background-color: white;

		.wrapper {
			height: 600px;
			width: 100%;
			background-color: #fff8f2;
			border-radius: 5rem;
			padding: 3rem 4rem;
			display: flex;

			@media (max-width: 56rem) {
				flex-direction: column;
				height: auto;
				padding-bottom: 4rem;
			}

			.left-wrapper {
				width: 50%;
				height: 100%;

				@media (max-width: 56rem) {
					width: 100%;
					text-align: center;
				}

				.selector {
					display: flex;
					justify-content: center;

					.text-wrapper {
						.h2 {
							font-size: 1.1rem;
							font-weight: 600;
							color: #292d34;
							transition: all 0.3s;

							&:hover {
								cursor: pointer;
							}

							&--underline {
								color: ${({ theme }) => theme.colors.primary};
							}
						}

						.underline {
							width: 100%;
							height: 2px;
							background-color: ${({ theme }) => theme.colors.primary};
							border-radius: 10px;
							animation: underline 0.4s ease-in-out;
						}

						&:not(:last-child) {
							margin-right: 3rem;
						}
					}
				}

				.body {
					display: flex;
					height: 100%;
					flex-direction: column;
					justify-content: center;

					@media (max-width: 56rem) {
						margin-top: 4rem;
					}

					.h2-title {
						align-self: flex-start;
						font-size: 2.8rem;

						@media (max-width: 56rem) {
							align-self: center;
						}
					}

					.p {
						margin-top: 1rem;
					}

					.a {
						width: fit-content;
						background-color: #ffb675;
						text-decoration: none;
						padding: 1rem 2rem;
						color: white;
						box-shadow: 0 10px 20px rgba(232, 178, 95, 0.5);
						font-family: "Avenir";
						font-weight: 700;
						letter-spacing: 0px;
						font-size: 1.1rem;
						margin-top: 3rem;
						border-radius: 20px;
						transition: all 0.2s;

						@media (max-width: 56rem) {
							align-self: center;
						}

						&:hover {
							box-shadow: 0 20px 40px rgba(232, 200, 151, 0.5);
							transform: translateY(-2px) scale(1.05);
						}

						&:active {
							box-shadow: 0 5px 10px rgba(232, 200, 151, 0.5);
							transform: translateY(0px) scale(0.95);
						}
					}
				}
			}

			.right-wrapper {
				width: 50%;
				display: flex;
				justify-content: center;

				@media (max-width: 56rem) {
					width: 100%;
				}

				.card {
					display: flex;
					align-items: center;
					justify-content: center;

					.startup-website {
						width: 40vw;
						height: auto;
						border-radius: 10px;
						margin-left: 5rem;
						box-shadow: 20px 0 40px rgba(200, 200, 253, 0.5);
						filter: blur(0.3px);
						animation: image3d 6s infinite;
						transition: all 0.2s;

						@media (max-width: 56rem) {
							width: 80%;
							margin-left: 0;
							margin-top: 4rem;
						}

						/* &:hover {
							@media (min-width: 861px) {
								animation: none;
							}
						} */
					}

					.video-ads {
						width: 40vw;
						height: auto;
						animation: video_ad 0.5s, video_ad_pulsating 5s infinite ease-in-out;

						@media (max-width: 56rem) {
							width: 80%;
							margin-left: 0;
							margin-top: 4rem;
						}
					}
				}
			}
		}
	}

	.section-fifth {
		padding-inline: 8.33vw;
		padding-block: 4rem;
		background-color: white;

		.h2-title {
			display: block;
			font-size: 2.4rem;
			text-align: center;
		}

		.wrapper-boxes {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(19rem, 1fr));
			padding-block: 3rem;
			gap: 4rem;

			.wrapper {
				display: flex;
				flex-direction: column;
				align-items: center;

				.img {
					height: 16rem;
					background-color: #f0f0f0;
					padding: 1.5rem;
					border-radius: 30px;
					filter: grayscale(1);
				}

				.title {
					margin-block: 1rem;
				}

				.p {
					font-weight: 300;
					text-align: center;
					padding-inline: 1rem;
				}
			}
		}
	}

	.animate {
		animation: images 1s ease-in-out;
	}

	@keyframes video_ad {
		0% {
			opacity: 0;
			transform: scale(1.3);
		}

		100% {
			opacity: 1;
			transform: scale(1);
		}
	}

	@keyframes video_ad_pulsating {
		0% {
			transform: scale(1.05) translateY(-5px);
		}

		50% {
			transform: scale(1) translateY(0);
		}

		100% {
			transform: scale(1.05) translateY(-5px);
		}
	}

	@keyframes images {
		0% {
			transform: translateY(50px);
			opacity: 0;
		}

		100% {
			transform: translateY(0);
			opacity: 1;
		}
	}

	@keyframes titleFall {
		0% {
			transform: translateY(-100px);
			opacity: 0;
		}

		100% {
			transform: translateY(0px);
			opacity: 1;
		}
	}

	@keyframes leftRight1 {
		0% {
			transform: translate(-3rem, -8.8rem);
		}

		50% {
			transform: translate(-3rem, -8.5rem);
		}

		100% {
			transform: translate(-3rem, -8.8rem);
		}
	}

	@media (max-width: 1079px) {
		@keyframes leftRight1 {
			0% {
				transform: translate(-3rem, -9.5rem);
			}

			50% {
				transform: translate(-3rem, -9.2rem);
			}

			100% {
				transform: translate(-3rem, -9.5rem);
			}
		}
	}

	@media (max-width: 600px) {
		@keyframes leftRight1 {
			0% {
				transform: translate(0rem, -10.9rem);
			}

			50% {
				transform: translate(0rem, -10.4rem);
			}

			100% {
				transform: translate(0rem, -10.9rem);
			}
		}
	}

	@keyframes leftRight2 {
		0% {
			transform: translate(0px);
		}

		50% {
			transform: translate(0rem, 0.5rem);
		}

		100% {
			transform: translate(0px);
		}
	}

	@keyframes leftRight3 {
		0% {
			transform: translate(3rem, 8.5rem);
		}

		50% {
			transform: translate(3rem, 8.7rem);
		}

		100% {
			transform: translate(3rem, 8.5rem);
		}
	}

	@media (max-width: 1079px) {
		@keyframes leftRight3 {
			0% {
				transform: translate(3rem, 9rem);
			}

			50% {
				transform: translate(3rem, 9.5rem);
			}

			100% {
				transform: translate(3rem, 9rem);
			}
		}
	}

	@media (max-width: 600px) {
		@keyframes leftRight3 {
			0% {
				transform: translate(0rem, 10.1rem);
			}

			50% {
				transform: translate(0rem, 10.5rem);
			}

			100% {
				transform: translate(0rem, 10.1rem);
			}
		}
	}

	@keyframes underline {
		0% {
			width: 0%;
		}

		100% {
			width: 100%;
		}
	}

	@keyframes image3d {
		0% {
			transform: perspective(1000px) rotateX(9deg) rotateY(8deg) scale(1.1);
		}

		50% {
			transform: perspective(1000px) rotateX(-9deg) rotateY(-8deg) scale(1.1);
		}

		100% {
			transform: perspective(1000px) rotateX(9deg) rotateY(8deg) scale(1.1);
		}
	}
`;
