import { useState, useRef, useEffect, useContext } from "react";
import { realtimeDatabase } from "../../../firebase/realtimeFir";
import { ref, onValue, update, remove } from "firebase/database";
import storageRef from "../../../firebase/storageFir";
import { deleteObject, ref as sRef } from "firebase/storage";

import { StyledOrdiniModuloVideoAds } from "./StyledOrdiniModuloVideoAds";
import Card from "../../../components/Card/Card";
import { useAuth } from "../../../context/authContext";
import { headerContext } from "../../../context/headerContext";

const OrdiniModuloVideoAds = () => {
	const [orderList, setOrderList] = useState([]);
	const [mostraDettagli, setMostraDettagli] = useState(false);
	const [selectedDettagli, setSelectedDettagli] = useState();
	const [mostraCompletati, setMostraCompletati] = useState(false);
	const [finalOrderList, setFinalOrderList] = useState([]);
	const [procediEliminazione, setProcediEliminazione] = useState(false);
	const [modalEliminazione, setModalEliminazione] = useState(false);

	const { user } = useAuth();

	let firstUpdate = useRef(0);

	const ordiniRef = ref(realtimeDatabase, "ordinazioneVideoAds/modulo/ordini");

	const handleMostraCompletati = () => {
		setMostraCompletati(!mostraCompletati);
	};

	const headerCtx = useContext(headerContext);
	headerCtx.handleTransparency(false);

	useEffect(() => {
		orderList.map((i) => {
			if (i.isCompleted !== true) {
				return;
			} else {
				if (i.fileOneName !== "none") {
					const fileOneRef = sRef(
						storageRef,
						`modulo-creazione-video-ads/immagini-inviate/${i.mail}/_${i.dateKey}_${i.fileOneName}`
					);
					console.log(fileOneRef);
					deleteObject(fileOneRef).then(() => {
						console.log("deleted first");
					});
				}
				if (i.fileTwoName !== "none") {
					const fileTwoRef = sRef(
						storageRef,
						`modulo-creazione-video-ads/immagini-inviate/${i.mail}/_${i.dateKey}_${i.fileTwoName}`
					);
					console.log(fileTwoRef);
					deleteObject(fileTwoRef).then(() => {
						console.log("deleted second");
					});
				}

				remove(
					ref(
						realtimeDatabase,
						`ordinazioneVideoAds/modulo/ordini/${i.dateKey}`
					)
				);
			}
		});

		setProcediEliminazione(false);
	}, [procediEliminazione]);

	useEffect(() => {
		if (firstUpdate.current < 1) {
			firstUpdate.current += 1;
			return;
		}
		onValue(ordiniRef, (snapshot) => {
			setOrderList((ov) => []);
			snapshot.forEach((childSnapshot) => {
				const childData = childSnapshot.val();
				setOrderList((prev) => [...prev, childData]);
			});
		});
	}, []);

	useEffect(() => {
		if (mostraCompletati) {
			setFinalOrderList(orderList);
		} else {
			setFinalOrderList(orderList.filter((ordine) => !ordine.isCompleted));
		}
	}, [mostraCompletati, orderList]);

	headerCtx.handleVisibility(true);

	if (user) {
		return (
			<StyledOrdiniModuloVideoAds>
				{modalEliminazione && (
					<div className='overlayModal'>
						<div className='modal'>
							<h3>Aspetta!</h3>
							<span className='span'>
								Sei sicuro di voler Procedere?
								<br />I dati saranno eliminati definitivamente
							</span>
							<div className='buttonsWrapper'>
								<button
									className='ok'
									onClick={() => {
										setProcediEliminazione(true);
										setModalEliminazione(false);
									}}
								>
									OK
								</button>
								<button
									className='annulla'
									onClick={() => {
										setModalEliminazione(false);
									}}
								>
									Annulla
								</button>
							</div>
						</div>
					</div>
				)}
				{!mostraDettagli && (
					<div className='mainWrapper'>
						<span className='elCo' onClick={() => setModalEliminazione(true)}>
							Elimina Completati
						</span>
						<div className='firstWrapper'>
							<h2>Ordini</h2>
							<div className='wrapperFilter'>
								<span
									className='completedSelector'
									onClick={handleMostraCompletati}
								>
									{mostraCompletati
										? "Nascondi Completati"
										: "Mostra Completati"}
								</span>
							</div>
						</div>
						<div className='ordiniWrapper'>
							{finalOrderList.map((i) => (
								<div key={i.dateKey} className='cardWrapper'>
									<Card height='auto' width='100%'>
										<div className='div'>
											<div className='titleDiv'>
												<span className='title'>{`${i.mail} | ${i.dateKey}`}</span>
												<span
													className='mostraDettagli'
													onClick={() => {
														setMostraDettagli(true);
														setSelectedDettagli(i);
													}}
												>
													Mostra dettagli &rsaquo;
												</span>
											</div>
											<div className='completedDiv'>
												<div
													className={
														i.isCompleted
															? "circleIndicatorBlue"
															: "circleIndicatorRed"
													}
												/>
												<span>
													{i.isCompleted
														? "Ordine completato"
														: "Ordine non completato"}
												</span>
											</div>
										</div>
									</Card>
								</div>
							))}
						</div>
					</div>
				)}
				{mostraDettagli && (
					<div className='mainDettagliWrapper'>
						<div className='indietroDiv'>
							<span
								onClick={() => setMostraDettagli(false)}
								className='indietro'
							>
								&lsaquo; Indietro
							</span>
							<span
								onClick={() => {
									update(
										ref(
											realtimeDatabase,
											`ordinazioneVideoAds/modulo/ordini/${selectedDettagli.dateKey}`
										),
										{ isCompleted: true }
									);

									console.log("remove");
									setMostraDettagli(false);
								}}
							>
								Segna come completato &#x2715;
							</span>
						</div>
						<Card height='auto'>
							<div className='insideWrapper'>
								<span className='number'>{selectedDettagli.dateKey}</span>
							</div>
							<div className='columnWrapper'>
								<div className='left'>
									<div className='spanDiv'>
										<span>Email</span>
									</div>
									<div className='pDiv'>
										<p>{selectedDettagli.mail}</p>
									</div>
									<div className='spanDiv'>
										<span>Link</span>
									</div>
									<div className='pDiv'>
										<a href={selectedDettagli.linkProdotto}>
											{selectedDettagli.linkProdotto}
										</a>
									</div>
									<div className='spanDiv'>
										<span>Nome del Prodotto</span>
									</div>
									<div className='pDiv'>
										<p>{selectedDettagli.nomeProdotto}</p>
									</div>
									<div className='spanDiv'>
										<span>Store Concorrenti</span>
									</div>
									<div className='pDiv'>
										<p>{selectedDettagli.storeConcorrenti}</p>
									</div>
									<div className='spanDiv'>
										<span>Dettagli Extra</span>
									</div>
									<div className='pDiv'>
										<p>{selectedDettagli.dettagliExtra}</p>
									</div>
									<div className='spanDiv'>
										<span>Contatti</span>
									</div>{" "}
									<div className='pDiv'>
										<p>{selectedDettagli.contatti}</p>
									</div>
								</div>
								<div className='right'>
									<div className='spanDiv'>
										<span>Colori</span>
									</div>
									<div className='pDiv'>
										<p>{selectedDettagli.colori}</p>
									</div>
									<div className='spanDiv'>
										<span>Punti di Forza</span>
									</div>
									<div className='pDiv'>
										<p>{selectedDettagli.puntiDiForza}</p>
									</div>
									<div className='spanDiv'>
										<span>Idea del Video</span>
									</div>
									<div className='pDiv'>
										<p>{selectedDettagli.ideaVideo}</p>
									</div>
									<div className='spanDiv'>
										<span>File 1</span>
									</div>
									<div className='pDiv'>
										<a href={selectedDettagli.fileOne} target='_blank'>
											{selectedDettagli.fileOne}
										</a>
									</div>
									<div className='spanDiv'>
										<span>File 2</span>
									</div>
									<div className='pDiv'>
										<a href={selectedDettagli.fileTwo} target='_blank'>
											{selectedDettagli.fileTwo}
										</a>
									</div>
								</div>
							</div>
						</Card>
					</div>
				)}
			</StyledOrdiniModuloVideoAds>
		);
	} else {
		return (
			<StyledOrdiniModuloVideoAds>
				<div className='divRev'>
					<span className='spanRev'>Questa pagina è riservata</span>
				</div>
			</StyledOrdiniModuloVideoAds>
		);
	}
};

export default OrdiniModuloVideoAds;
