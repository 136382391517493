import { useState, useEffect, useContext } from "react";
import { headerContext } from "../../context/headerContext";
import { useTranslation } from "react-i18next";

import imageByBrookeCagle from "../../images/ImagebyBrookeCagle.webp";
import whatsappQR from "../../images/whatsappQR.webp";

import { StyledGraziePerIlTuoOrdine } from "./StyledGraziePerIlTuoOrdine";

const GraziePerIlTuoOrdine = () => {
	const { t } = useTranslation();

	const headerCtx = useContext(headerContext);
	headerCtx.handleTransparency(false);

	const [isMobile, setIsMobile] = useState(false);

	const handleResize = () => {
		if (window.innerWidth < 980) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
		return;
	};

	useEffect(() => {
		handleResize();
		window.addEventListener("resize", handleResize);
	}, []);

	return (
		<StyledGraziePerIlTuoOrdine>
			{!isMobile && (
				<div className='firstSectionDiv'>
					<div className='textWrapper'>
						<span className='title'>{t("thanksForYourOrderPageTitle.1")}</span>
						<span className='subtitle'>{t("thanksForYourOrderPageP1.1")}</span>
					</div>
				</div>
			)}
			{isMobile && (
				<div className='firstSectionDivMobile'>
					<div className='textWrapper'>
						<span className='title'>{t("thanksForYourOrderPageTitle.1")}</span>
						<span className='subtitle'>{t("thanksForYourOrderPageP1.1")}</span>
					</div>{" "}
					<div className='overlay' />
					<img
						src={imageByBrookeCagle}
						alt='girl smiling'
						className='imageGirl'
					/>
				</div>
			)}
			<div className='secondSectionDiv'>
				<div className='divText'>
					<span className='span'>{t("thanksForYourOrderPageLeftTitle.1")}</span>
					<p className='p'>{t("thanksForYourOrderPageLeftP2.1")}</p>
					<div className='divLinks'>
						<span>{t("thanksForYourOrderPageCTA.1")}</span>
						<a href='https://servizidiamond.it'>
							{t("thanksForYourOrderPageCTA.2")}
						</a>
					</div>
					<div className='divQR'>
						<img src={whatsappQR} alt='QR code for whatsapp' />
						<div className='divText'>
							<span>{t("thanksForYourOrderPageSubtitle.1")}</span>
							<p>
								{`${t("thanksForYourOrderPageP3.1")} + ${
									!isMobile && t("thanksForYourOrderPageP3.2")
								} +
										${t("thanksForYourOrderPageP3.3")}`}
							</p>
						</div>
					</div>
				</div>
				<img
					src={imageByBrookeCagle}
					alt='girl smiling'
					className='imageGirl'
				/>
			</div>
		</StyledGraziePerIlTuoOrdine>
	);
};

export default GraziePerIlTuoOrdine;
