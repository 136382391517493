import styled from "styled-components";

export const StyledModuloOrdinaSitoWeb = styled.div`
	margin-bottom: 100px;

	.loadingOverlay {
		position: fixed;
		width: 100vw;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		z-index: 10;
		display: flex;
		justify-content: center;
		align-items: center;

		.modal {
			display: grid;
			background: #f4f4f4;
			align-items: center;
			justify-items: center;
			margin: auto;
			border-radius: 10px;
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
			padding-block: 3vh;

			h3 {
				margin-top: 10px;
				font-size: 2rem;
				margin-bottom: 10px;
				margin-inline: 2.3vw;
			}

			span {
				color: #121212;
				font-weight: lighter;
				margin-inline: 7vw;
				margin-bottom: 100px;
			}

			.progress {
				font-family: Avenir;
				font-weight: 800;
				font-size: 3.5rem;
				color: #121212;
				margin-block: 0;
			}
		}

		.loader,
		.loader:before,
		.loader:after {
			border-radius: 50%;
			width: 2.5em;
			height: 2.5em;
			-webkit-animation-fill-mode: both;
			animation-fill-mode: both;
			-webkit-animation: load7 1.8s infinite ease-in-out;
			animation: load7 1.8s infinite ease-in-out;
		}
		.loader {
			color: ${({ theme }) => theme.colors.primary};
			font-size: 10px;
			position: relative;
			margin-top: -135px;
			text-indent: -9999em;
			-webkit-transform: translateZ(0);
			-ms-transform: translateZ(0);
			transform: translateZ(0);
			-webkit-animation-delay: -0.16s;
			animation-delay: -0.16s;
		}
		.loader:before,
		.loader:after {
			content: "";
			position: absolute;
			top: 0;
		}
		.loader:before {
			left: -3.5em;
			-webkit-animation-delay: -0.32s;
			animation-delay: -0.32s;
		}
		.loader:after {
			left: 3.5em;
		}
		@-webkit-keyframes load7 {
			0%,
			80%,
			100% {
				box-shadow: 0 2.5em 0 -1.3em;
			}
			40% {
				box-shadow: 0 2.5em 0 0;
			}
		}
		@keyframes load7 {
			0%,
			80%,
			100% {
				box-shadow: 0 2.5em 0 -1.3em;
			}
			40% {
				box-shadow: 0 2.5em 0 0;
			}
		}
	}

	.overlayModal {
		position: fixed;
		width: 100vw;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		z-index: 10;
		display: flex;

		.modal {
			display: grid;
			background: #f4f4f4;
			align-items: center;
			justify-items: center;
			margin: auto;
			border-radius: 10px;
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

			h3 {
				font-size: 2rem;
				margin-bottom: 20px;
				margin-top: 40px;
			}

			.span {
				font-weight: lighter;
				margin-inline: 80px;
			}

			button {
				border-radius: 8px;
				background: ${({ theme }) => theme.colors.primary};
				color: #fff;
				border: 1px solid ${({ theme }) => theme.colors.primary};
				padding: 8px 20px 8px 20px;
				margin-inline: 100px;
				font-size: 1.05rem;
				margin-bottom: 40px;
				margin-top: 40px;

				&:hover {
					/* border-radius: 30px; */
					background: #fff;
					border: 1px solid ${({ theme }) => theme.colors.primary};
					color: ${({ theme }) => theme.colors.primary};
					cursor: pointer;
				}
			}
		}
	}

	.firstSectionDiv {
		background: #f4f4f4;

		.textWrapper {
			padding-top: 150px;
			padding-bottom: 70px;
			display: grid;
			text-align: left;
			margin-inline: 8.33vw;

			@media (max-width: 979px) {
				padding-inline: 30px;
			}

			.title {
				font-size: 3rem;
				font-weight: 900;
				color: ${({ theme }) => theme.colors.black};
			}

			.subtitle {
				margin-top: 15px;
				font-size: 1rem;
				font-weight: lighter;
			}
		}
	}

	.questionsWrapper {
		margin-inline: 15vw;
		display: flex;
		margin-top: 50px;

		.title {
			font-size: 2rem;
			font-weight: 600;
		}

		p {
			font-weight: lighter;
			margin-top: 60px;
			margin-bottom: 10px;
		}

		.leftWrapper {
			width: 50%;
			padding-right: 50px;

			.cat:not(:first-child) {
				margin-top: 50px;

				.title {
					font-size: 2rem;
					font-weight: 600;
				}

				.subtitle-slider {
					margin-top: 20px;
					margin-bottom: 1rem;
				}

				.slider {
					margin-top: 2rem;
				}
			}
		}

		.rightWrapper {
			width: 50%;
			margin-top: -50px;

			a:link,
			a:visited {
				font-weight: 400;
				color: ${({ theme }) => theme.colors.primary};
			}

			p {
				margin-top: 20px;
			}

			.cat {
				margin-top: 50px;

				.title {
					font-size: 2rem;
					font-weight: 600;
				}

				.selector {
					display: grid;
					grid-template-columns: repeat(auto-fit, 6rem);

					[class^="p"] {
						background-color: ${({ theme }) => theme.colors.secondary};
						color: ${({ theme }) => theme.colors.black};
						padding: 0.6rem 0.9rem;
						font-size: 0.8rem;
						margin-inline: 0.3rem;
						justify-self: center;
						border-radius: 3px;
						font-weight: 500;
						cursor: pointer;
						border: 1px solid transparent;

						&:hover {
							border: 1px solid ${({ theme }) => theme.colors.primary};
						}
					}

					.selected {
						background-color: ${({ theme }) => theme.colors.primary};
						color: ${({ theme }) => theme.colors.secondary};
					}
				}
			}

			.divUploadButtons {
				margin-top: 25px;
				text-align: -moz-center;
				text-align: -webkit-center;
				display: flex;
				justify-content: space-evenly;
			}

			.divInvia {
				margin-top: 70px;
				display: grid;
				text-align: center;

				span {
					font-weight: lighter;
					margin-bottom: 20px;
				}

				button {
					border-radius: 2px;
					background: ${({ theme }) => theme.colors.primary};
					color: #fff;
					border: 1px solid ${({ theme }) => theme.colors.primary};
					padding: 10px 20px 10px 20px;
					margin-inline: 100px;

					&:hover {
						/* border-radius: 30px; */
						background: #fff;
						border: 1px solid ${({ theme }) => theme.colors.primary};
						color: ${({ theme }) => theme.colors.primary};
						cursor: pointer;
					}
				}
			}

			.contattoQR {
				text-align: center;
				margin-top: 100px;

				img {
					height: 200px;
					margin-top: 30px;
					box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
					border-radius: 20px;
				}

				h2 {
					font-family: Avenir;
					font-weight: 800;
				}

				.a:link,
				.a:visited {
					color: ${({ theme }) => theme.colors.primary};
					b {
						font-weight: 400;
						color: ${({ theme }) => theme.colors.primary};
					}
				}
			}
		}
	}

	@media (max-width: 979px) {
		.questionsWrapper {
			display: block;
			margin-inline: 10vw;

			.leftWrapper {
				width: 100%;
				margin-right: 0;
				padding-right: 0;
			}

			.rightWrapper {
				width: 100%;
				margin-top: 0;

				.divUploadButtons {
					display: block;
				}

				.divInvia {
					button {
						margin-inline: 30px;
					}
				}

				.contattoQR {
					img {
						display: none;
					}
				}
			}
		}
	}

	//?----------------------------------------------------------------------------
	//? Alice Carousel Card Styling -----------------------------------------------

	.card {
		/* padding-inline: 20px; */
		margin-block: 2rem;
	}

	.notVisible {
		display: none;
	}
`;
