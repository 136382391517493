import styled from "styled-components";

export const StyledOrdinaSitoWeb = styled.div`
	/* background-color: #fafbfc; */

	.wrapper-dots {
		.dot_pattern {
			width: 22rem;
			height: 22rem;
			transform: rotate(33deg);
			margin-left: -8rem;
			margin-top: -16rem;
			opacity: 0.3;
			position: absolute;
			z-index: -1;

			@media (max-width: 37rem) {
				display: none;
			}
		}
	}

	.header-section {
		display: flex;
		position: relative;
		overflow: hidden;
		height: 750px;

		@media (max-width: 67rem) {
			height: calc(750px + 40rem);
		}

		@media (max-width: 37rem) {
			height: 900px;
		}

		.left-box {
			margin-left: 8.33vw;
			max-width: 40vw;

			@media (max-width: 80rem) {
				max-width: 50vw;
			}

			@media (max-width: 67rem) {
				max-width: 90vw;
				margin-right: 8.33vw;
			}

			.title {
				margin-top: 10vw;
				font-size: 2.8rem;
				width: 130%;

				@media (max-width: 67rem) {
					margin-top: 8rem;
					width: 100%;
				}
			}

			.p {
				margin-top: 1rem;
			}

			.ul {
				list-style: none;
				margin-top: 4rem;

				@media (max-width: 80rem) {
					max-width: 40vw;
				}

				@media (max-width: 67rem) {
					max-width: 50vw;
				}

				@media (max-width: 37rem) {
					max-width: 100%;
				}

				.li {
					display: flex;

					.img {
						width: 4rem;
						height: 4rem;
						margin-right: 1.5rem;
					}

					.text {
						.h2 {
							font-size: 1.3rem;
							color: #292d34;
						}

						.p {
							font-size: 0.9rem;
							color: #999;
						}
					}

					&:not(:last-child) {
						margin-bottom: 2.5rem;
					}
				}
			}
		}

		[class*="rounded-rectangle"] {
			width: 50rem;
			height: 50rem;
			transform: rotate(20deg) translate(0, -28%);
			left: calc(55vw + 44px);
			position: absolute;

			@media (max-width: 67rem) {
				width: 40rem;
				height: 40rem;
				top: 30rem;
				left: calc(55vw + 44px);
				transform: rotate(20deg);
			}

			@media (max-width: 37rem) {
				display: none;
			}
		}

		.img-ex {
			position: absolute;
			box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3);
			border-radius: 20px;
			height: 18vw;

			@media (max-width: 67rem) {
				height: 30vw;
			}
		}

		.shopify {
			top: 13rem;
			right: 14vw;

			@media (max-width: 80rem) {
				top: 20vw;
			}

			@media (max-width: 70rem) {
				top: 28vw;
			}

			@media (max-width: 67rem) {
				top: 45rem;
				right: 35vw;
			}

			@media (max-width: 42rem) {
				top: 53rem;
			}

			@media (max-width: 37rem) {
				display: none;
			}
		}

		.vscode {
			top: 22rem;
			right: 6vw;

			@media (max-width: 80rem) {
				top: 30vw;
			}

			@media (max-width: 70rem) {
				top: 38vw;
			}

			@media (max-width: 67rem) {
				top: 55rem;
				right: 15vw;
			}

			@media (max-width: 42rem) {
				top: 60rem;
			}

			@media (max-width: 37rem) {
				display: none;
			}
		}
	}

	.section-second {
		padding-inline: 4vw;

		.box {
			position: relative;
			border-radius: 70px;
			background-color: rgba(147, 63, 253, 0.1);
			width: 100%;
			box-shadow: 0px 0px 20px rgba(147, 63, 253, 0.1);
			padding: 4rem 6rem;
			display: flex;

			@media (max-width: 56rem) {
				flex-direction: column;
			}

			@media (max-width: 37rem) {
				padding: 2.5rem 3rem;
				border-radius: 40px;
			}

			.left-box {
				width: 50%;
				padding-right: 6rem;

				@media (max-width: 56rem) {
					width: 100%;
					padding-right: 0;
				}

				.selector {
					display: flex;
					justify-content: center;

					.text-wrapper {
						.h2 {
							font-size: 1.1rem;
							font-weight: 600;
							color: #292d34;
							transition: all 0.3s;

							&:hover {
								cursor: pointer;
							}

							&--underline {
								color: ${({ theme }) => theme.colors.primary};
							}
						}

						.underline {
							width: 100%;
							height: 2px;
							background-color: ${({ theme }) => theme.colors.primary};
							border-radius: 10px;
							animation: underline 0.4s ease-in-out;
						}

						&:not(:last-child) {
							margin-right: 3rem;
						}
					}
				}

				.title {
					margin-top: 5rem;
					font-size: 3rem;
				}

				.p {
					margin-top: 1.5rem;

					@media (max-width: 56rem) {
						margin-bottom: 5rem;
					}
				}

				.logo-grid {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					grid-template-rows: repeat(2, min-content);
					align-items: center;
					justify-items: center;
					margin-top: 2rem;

					@media (max-width: 56rem) {
						margin-bottom: 5rem;
					}

					.img-logo {
						width: 12rem;
						padding-block: 1.2rem;
						padding-inline: 1rem;

						@media (max-width: 80rem) {
							width: 9rem;
						}

						@media (max-width: 56rem) {
							width: 12rem;
						}

						@media (max-width: 37rem) {
							width: 8rem;
						}
					}
				}

				.logo-grid-2 {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					grid-template-rows: repeat(2, min-content);
					align-items: center;
					justify-items: center;
					margin-top: 2rem;

					@media (max-width: 56rem) {
						margin-bottom: 5rem;
					}

					.img-logo {
						width: 8rem;
						padding-block: 0;
						padding-inline: -2rem;

						@media (max-width: 70rem) {
							width: 6rem;
						}

						@media (max-width: 56rem) {
							width: 8rem;
						}

						@media (max-width: 37rem) {
							width: 6rem;
						}

						&-javascript {
							width: 5rem;

							@media (max-width: 70rem) {
								width: 3rem;
							}
						}
					}
				}
			}

			.right-box {
				display: flex;
				flex-direction: column;
				justify-content: center;

				.img {
					border-radius: 15px;
					width: 25vw;
					box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);

					&-shopify {
						margin-left: 3rem;
						width: 28vw;

						@media (max-width: 56rem) {
							width: 45vw;
							margin-left: 10rem;
						}

						@media (max-width: 37rem) {
							margin-left: 20vw;
						}
					}

					&-wix {
						width: 40vw;
						margin-top: -4rem;

						@media (max-width: 80rem) {
							margin-top: -5vw;
						}

						@media (max-width: 56rem) {
							width: 60vw;
							margin-top: -18vw;
						}
					}

					&-saas {
						position: relative;
						align-self: flex-end;
						margin-right: 4rem;
						margin-top: -8rem;
						width: 30vw;
						z-index: 0;
						border-radius: 10px;

						@media (max-width: 80rem) {
							margin-top: -5vw;
						}

						@media (max-width: 56rem) {
							width: 50vw;
							margin-top: -14vw;
							margin-right: 2rem;
						}

						@media (max-width: 37rem) {
							margin-right: 5vw;
						}
					}
				}
			}
		}
	}

	.section-third {
		margin-inline: 8.33vw;
		margin-block: 6rem;
		display: flex;

		@media (max-width: 63rem) {
			flex-direction: column;
		}

		.left-text {
			width: 60%;
			margin-right: 3rem;

			@media (max-width: 63rem) {
				width: 100%;
				margin-right: 0;
			}

			a:link,
			a:visited,
			b {
				color: ${({ theme }) => theme.colors.primary};
			}

			.h2-contatti {
				font-size: 2.3rem;
				margin-bottom: 2rem;
			}

			.p {
				line-height: 1.7;
			}

			.wrapper-qr {
				display: flex;
				margin-top: 3rem;

				img {
					height: 200px;
					box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
					border-radius: 20px;
					transition: all 0.3s;

					&:hover {
						transform: scale(1.2);
						box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
					}
				}

				.wrapper-text {
					margin-left: 2rem;
					margin-top: 1rem;

					@media (max-width: 979px) {
						margin-left: 0;
						margin-top: 0;
						margin-bottom: 2rem;
					}

					h2 {
						margin-bottom: 1rem;

						@media (max-width: 979px) {
							display: none;
						}
					}

					p {
						line-height: 1.7;
					}
				}
			}
		}

		.form {
			width: 40vw;

			@media (max-width: 62rem) {
				width: 100%;
			}

			.input-wrapper {
				margin-block: 2.5rem;
			}

			.p {
				margin-bottom: 0.8rem;
			}

			.cta {
				margin-top: 2rem;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.visible {
					opacity: 1;
				}

				.invisible {
					opacity: 0;
				}

				.button-send {
					border: 1px solid transparent;
					color: ${({ theme }) => theme.colors.secondary};
					padding: 0.8rem 2rem;
					background-color: ${({ theme }) => theme.colors.primary};
					border-radius: 15px;
					font-size: 1rem;
					font-family: Avenir;
					font-weight: 600;
					box-shadow: 10px 10px 20px rgba(147, 63, 253, 0.1);
					transition: all 0.2s;

					&:hover {
						color: ${({ theme }) => theme.colors.primary};
						background-color: white;
						border: 1px solid ${({ theme }) => theme.colors.primary};
						cursor: pointer;
						transform: translateY(-3px);
						box-shadow: 0px 10px 30px rgba(147, 63, 253, 0.2);
					}

					&:active {
						color: ${({ theme }) => theme.colors.primary};
						background-color: white;
						border: 1px solid ${({ theme }) => theme.colors.primary};
						cursor: pointer;
						transform: translateY(-3px);
						box-shadow: 0px 5px 15px rgba(147, 63, 253, 0.2);

						transform: scale(0.95);
					}
				}
			}
		}
	}

	.section-fourth {
		padding-bottom: 8rem;
		padding-top: 4rem;
		background-color: #fafbfc;
	}

	.animate {
		animation: images 1s ease-in-out;
	}

	@keyframes underline {
		0% {
			width: 0%;
		}

		100% {
			width: 100%;
		}
	}

	@keyframes images {
		0% {
			transform: translateY(50px);
			opacity: 0;
		}

		100% {
			transform: translateY(0);
			opacity: 1;
		}
	}

	.card {
		min-width: 48rem;
		max-width: 50rem;
		border-radius: 5px;
		margin-inline: 2rem;
		margin-bottom: 2rem;
		margin-top: 6rem;
		background-color: white;
		padding: 2rem 4rem;

		&:not(:last-child) {
			margin-right: 3em;
		}

		@media (max-width: 65rem) {
			min-width: 95%;
			max-width: 95%;
			margin-inline: 1rem;
		}

		@media (max-width: 37rem) {
			padding: 1rem 2rem;
			margin-top: 8rem;
		}

		&--1 {
			/* background-color: #f4ebff; */
			/* box-shadow: 0px 0px 20px rgba(147, 63, 253, 0.1); */
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
		}

		&--2 {
			/* background-color: #f4ebff; */
			/* box-shadow: 0px 0px 20px rgba(147, 63, 253, 0.1); */
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
		}

		&--3 {
			/* background-color: #ffecbb;
			box-shadow: 0px 0px 20px rgba(147, 63, 253, 0.1); */
			box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
		}

		.img-title {
			display: flex;

			@media (max-width: 65rem) {
				flex-direction: column;
				align-items: center;
			}

			[class^="img-carousel"] {
				width: 15rem;
				height: 15rem;
				margin-top: -6rem;
				margin-left: -2rem;

				@media (max-width: 65rem) {
					margin-top: -8rem;
					margin-left: 0;
				}
			}

			.img-carousel--3 {
				/* width: 18rem;
				height: min-content; */
			}

			.right-text {
				margin-left: 2rem;

				@media (max-width: 65rem) {
					margin-left: 0;
				}

				.text-wrapper {
					box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
					height: min-content;
					padding: 1rem 2rem;
					border-radius: 20px;
					margin-top: -4rem;
					background-color: white;
					transition: all 0.2s;

					@media (max-width: 65rem) {
						margin-top: 2rem;
						box-shadow: none;
					}

					@media (max-width: 37rem) {
						padding-inline: 0;
					}

					.h2-title {
						font-size: 3rem;

						@media (max-width: 37rem) {
							font-size: 2.5rem;
						}
					}

					.h3-subtitle {
						font-weight: 600;
						text-transform: uppercase;
						font-size: 0.7rem;
						color: #999;
					}

					&:hover {
						transform: scale(1.05);

						@media (max-width: 67rem) {
							transform: scale(1);
						}
					}
				}

				.p {
					padding-left: 2rem;
					margin-top: 1.5rem;
					color: #999;

					@media (max-width: 37rem) {
						padding-left: 0;
					}
				}
			}
		}

		.wrapper-body {
			margin-bottom: 2rem;
			margin-top: 3.5rem;
			display: flex;

			@media (max-width: 65rem) {
				flex-direction: column;
			}

			.left-wrapper {
				width: 50%;
				padding-right: 2rem;

				@media (max-width: 65rem) {
					width: 100%;
					padding-right: 0;
				}

				.h2-title {
					margin-bottom: 1rem;
				}

				.p {
					color: #999;
				}
			}

			.line-middle {
				background-color: #999;
				width: 0.5px;
				border-radius: 20px;

				@media (max-width: 65rem) {
					height: 0.5px;
					width: 100%;
					margin-block: 2rem;
				}
			}

			.right-wrapper {
				width: 50%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding-inline: 2rem;

				@media (max-width: 65rem) {
					width: 100%;
					padding-inline: 0;
				}

				.p {
					font-size: 0.7rem;
					color: #999;
					margin-bottom: 2rem;
				}

				.h2-price {
					align-self: center;
					margin-bottom: 2rem;

					.text {
						font-size: 0.8rem;
						text-transform: uppercase;
						color: #999;
						margin-right: 0.8rem;

						@media (max-width: 37rem) {
							font-size: 0.8rem;
						}
					}

					.price {
						font-size: 3rem;

						@media (max-width: 37rem) {
							font-size: 2.5rem;
						}
					}
				}

				.button-acquista {
					border: 1px solid transparent;
					color: ${({ theme }) => theme.colors.secondary};
					padding: 0.8rem 2rem;
					background-color: ${({ theme }) => theme.colors.primary};
					border-radius: 15px;
					font-size: 1rem;
					font-family: Avenir;
					font-weight: 600;
					box-shadow: 10px 10px 20px rgba(147, 63, 253, 0.1);
					transition: all 0.2s;
					text-decoration: none;
					text-align: center;

					&:hover {
						color: ${({ theme }) => theme.colors.primary};
						background-color: white;
						border: 1px solid ${({ theme }) => theme.colors.primary};
						cursor: pointer;
						transform: translateY(-3px);
						box-shadow: 0px 10px 30px rgba(147, 63, 253, 0.2);
					}

					&:active {
						color: ${({ theme }) => theme.colors.primary};
						background-color: white;
						border: 1px solid ${({ theme }) => theme.colors.primary};
						cursor: pointer;
						transform: translateY(-3px);
						box-shadow: 0px 5px 15px rgba(147, 63, 253, 0.2);

						transform: scale(0.95);
					}
				}
			}
		}
	}
`;
