import styled from "styled-components";

export const StyledGraziePerIlTuoOrdine = styled.div`
	.firstSectionDiv {
		background: #f4f4f4;
		height: 350px;

		.textWrapper {
			padding-top: 150px;
			display: grid;
			text-align: center;

			@media (max-width: 979px) {
				padding-inline: 30px;
			}

			.title {
				font-size: 4rem;
				font-weight: 900;
				color: ${({ theme }) => theme.colors.black};
			}

			.subtitle {
				margin-top: 15px;
				font-size: 1.3rem;
				font-weight: lighter;
			}
		}
	}

	.firstSectionDivMobile {
		position: relative;

		.overlay {
			position: absolute;
			height: 100%;
			width: 100%;
			background: rgba(0, 0, 0, 0.5);
			top: 0;
		}

		height: 50vh;
		img {
			width: 100vw;
			max-height: 800px;
			object-fit: cover;
		}
		display: table;

		.textWrapper {
			padding-top: 150px;
			display: grid;
			text-align: -webkit-center;
			position: absolute;
			top: 25vw;
			z-index: 1;

			@media (max-width: 979px) {
				padding-inline: 30px;
			}

			.title {
				font-size: 4rem;
				font-weight: 900;
				color: ${({ theme }) => theme.colors.secondary};
			}

			.subtitle {
				margin-top: 15px;
				font-size: 1.3rem;
				font-weight: lighter;
				color: ${({ theme }) => theme.colors.secondary};
			}
		}
	}

	.secondSectionDiv {
		margin-inline: 8.33vw;
		display: flex;
		@media (max-width: 979px) {
			display: block;
			margin-inline: 0;
			margin-bottom: 100px;

			.imageGirl {
				display: none;
			}
		}
		justify-content: center;

		.divText {
			margin-top: 30px;
			padding-right: 5.2vh;

			@media (max-width: 979px) {
				padding-right: 0px;
				text-align: center;
				padding-inline: 30px;
			}

			.span {
				font-size: 2rem;
				font-weight: 600;
			}

			.p {
				font-weight: lighter;
			}

			.divLinks {
				margin-top: 60px;

				@media (max-width: 979px) {
					display: grid;
				}

				span {
					@media (min-width: 980px) {
						margin-right: 60px;
					}
					@media (max-width: 979px) {
						margin-bottom: 20px;
					}
					font-size: 1.2rem;
					font-weight: 300;
				}

				a {
					@media (max-width: 979px) {
						margin-inline: 100px;
					}

					background: ${({ theme }) => theme.colors.primary};
					text-decoration: none;
					padding: 10px 26px 10px 26px;
					color: #fff;
					font-family: Avenir;
				}
			}

			.divQR {
				margin-top: 100px;
				display: flex;
				align-items: center;

				@media (max-width: 979px) {
					img {
						display: none;
					}
					text-align: center;

					.divText {
						margin-left: 0;
					}
				}

				img {
					height: 170px;
				}

				.divText {
					@media (max-width: 979px) {
						padding-inline: 30px;
					}
					@media (min-width: 980px) {
						margin-left: 30px;
						margin-top: -1rem;
					}
					margin-top: 0;
					padding-right: 0;

					span {
						font-size: 2.3rem;
					}

					p {
						font-weight: lighter;
					}
				}
			}
		}

		.imageGirl {
			height: 700px;
		}
	}
`;
