import MainHeader from "./components/MainHeader/MainHeader";
import OrdinaVideoAds from "./pages/OrdinaVideoAds/OrdinaVideoAds";
import MainFooter from "./components/MainFooter/MainFooter";

import { StyledApp } from "./StyledApp";

import { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components";
import GraziePerIlTuoOrdine from "./pages/GraziePerIlTuoOrdine/GraziePerIlTuoOrdine";
import ModuloOrdinazioneVideoAds from "./pages/ModuloOrdinazioneVideoAds/ModuloOrdinazioneVideoAds";
import OrdiniModuloVideoAds from "./pages/Admin/OrdiniModuloVideoAds/OrdiniModuloVideoAds";
import Admin from "./pages/Admin/Admin/Admin";
import { AuthProvider } from "./context/authContext";
import { HeaderProvider } from "./context/headerContext";
import Contattaci from "./pages/Contattaci/Contattaci";
import Home from "./pages/Home/Home";
import OrdinaSitoWeb from "./pages/OrdinaSitoWeb/OrdinaSitoWeb";
import ModuloOrdinaSitoWeb from "./pages/ModuloOrdinaSitoWeb/ModuloOrdinaSitoWeb";
import UnderConstruction from "./pages/UnderConstruction/UnderConstruction";

const theme = {
	colors: {
		primary: "#933FFD",
		secondary: "#f4f4f4",
		black: "#333",
		grey: "#999",
	},
};

const DefaultWix = () => {
	window.location.replace("https://www.servizidiamond.it");
};

function App() {
	const { t } = useTranslation();

	useEffect(() => {
		if (document.title) return;
		document.title = t("docTitles.2");
	}, [t]);

	return (
		<ThemeProvider theme={theme}>
			<StyledApp>
				<Suspense
					fallback={
						<div class='lds-ellipsis'>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					}
				>
					<HeaderProvider>
						<MainHeader />
						<AuthProvider>
							<BrowserRouter>
								<Routes>
									<Route path='/old' element={<DefaultWix />} />
									<Route path='/' element={<Home />} />
									<Route path='/contattaci' element={<Contattaci />} />
									<Route
										path='/ordina-i-tuoi-video-ads'
										element={<OrdinaVideoAds />}
									/>
									<Route
										path='/grazie-per-il-tuo-ordine'
										element={<GraziePerIlTuoOrdine />}
									/>
									<Route
										path='/modulo-ordinazione-video-ads'
										element={<ModuloOrdinazioneVideoAds />}
									/>
									<Route
										path='/ordina-il-tuo-sito-web'
										element={<OrdinaSitoWeb title={t("docTitles.1")} />}
									/>
									<Route path='/admin' element={<Admin />} />
									<Route
										path='/admin/ordini-modulo-video-ads'
										element={<OrdiniModuloVideoAds />}
									/>
									<Route
										path='/modulo-ordinazione-sito-web'
										element={<ModuloOrdinaSitoWeb />}
									/>
								</Routes>
							</BrowserRouter>
						</AuthProvider>
						{window.location.pathname !== "/admin" && <MainFooter />}
					</HeaderProvider>
				</Suspense>
			</StyledApp>
		</ThemeProvider>
	);
}

export default App;
