import styled from "styled-components";

export const StyledInputLabel = styled.div`
	width: ${(props) => props.width};
	* {
		box-sizing: border-box;
		outline: none;
		font-family: Avenir;
	}

	.div {
		height: 40px;
		width: 100%;
		position: relative;

		& > input {
			height: 100%;
			width: 100%;
			border: none;
			border-bottom: 2px solid;
			font-size: 1rem;
			background-color: transparent;
		}

		& > input:focus ~ label {
			transform: translateY(-30px);
			font-size: 15px;
			color: ${({ theme }) => theme.colors.primary};
		}

		.inputNotEmpty {
			transform: translateY(-30px);
			font-size: 15px;
			color: ${({ theme }) => theme.colors.primary};
		}

		.underline {
			position: absolute;
			bottom: 0px;
			height: 2px;
			width: 100%;
		}

		.underline:before {
			position: absolute;
			content: "";
			height: 100%;
			width: 100%;
			background: ${({ theme }) => theme.colors.primary};
			transform: scaleX(0);
			transition: transform 0.3 ease;
			right: 0;
		}

		& > input:focus ~ .underline:before {
			transform: scaleX(1);
		}

		& > label {
			position: absolute;
			bottom: 10px;
			left: 0;
			color: grey;
			pointer-events: none;
			transition: all 0.3s ease;
		}
	}
`;
