import styled from "styled-components";

export const StyledAdmin = styled.div`
	display: flex;
	width: 100%;
	min-height: 100vh;

	.loginWrapper {
		min-height: 100vh;
		width: 100%;

		.login {
			margin-top: 150px;
			text-align: center;
		}
	}

	.side-bar {
		padding-block: 2rem;
		width: 20%;
		min-width: 300px;
		background-color: ${({ theme }) => theme.colors.primary};

		.logo-wrapper {
			display: flex;
			transform: translateY(-1rem);
			align-items: flex-end;
			margin-left: 1.5rem;

			.logo {
				width: 45%;
			}

			.span {
				color: white;

				transform: translateY(-1vw);
				margin-left: 0.5vw;
				font-size: 1vw;

				@media (max-width: 1366px) {
					transform: translateY(-1rem);
					margin-left: 0.5rem;
					font-size: 1rem;
				}
			}
		}

		[class^="section"] {
			.h2-title-section {
				color: white;
				font-family: "Avenir";
				font-weight: 800;
				font-size: 0.9rem;
				margin-left: 1.1rem;
			}

			.box {
				background-color: white;
				margin: 0.5rem;
				padding: 0.6rem 1.2rem;
				border-radius: 5px;
				display: flex;
				align-items: center;
				cursor: pointer;
				transition: all 0.4s;

				.icon {
					width: 1.5rem;
					margin-right: 0.5rem;
				}

				.h3 {
					font-weight: 500;
					font-size: 1rem;
					color: #444;
				}

				&-clicked {
					width: 110%;
					border-radius: 5px 0px 0px 5px;
				}
			}
		}
	}
`;
