import { createContext, useState, useEffect, useCallback } from "react";

export const headerContext = createContext();

export const HeaderProvider = ({ children }) => {
	const [isTransparent, setIsTransparent] = useState(false);
	const [isHidden, setIsHidden] = useState(false);
	const [headerColors, setHeaderColors] = useState(true);

	const handleTransparency = (bool) => {
		if (bool === false) {
			if (window.pageYOffset < 100) {
				setIsTransparent(false);
			}
			return;
		} else {
			if (window.pageYOffset > 100) {
				setIsTransparent(false);
			}

			if (window.pageYOffset < 100) {
				setIsTransparent(true);
			}
		}
	};

	const scrollHandler = useCallback(() => {
		if (window.pageYOffset > 100) {
			setIsTransparent(false);
		}

		if (window.pageYOffset < 100) {
			setIsTransparent(true);
		}
		if (!isTransparent) return false;
	}, []);

	const handleVisibility = useCallback((bool) => {
		setIsHidden(bool);
	});

	const handleHeaderColors = useCallback((bool) => {
		setHeaderColors(bool);
	});

	useEffect(() => {
		window.addEventListener("scroll", scrollHandler);
		return () => window.removeEventListener("scroll", scrollHandler);
	}, [scrollHandler]);

	return (
		<headerContext.Provider
			value={{
				isTransparent,
				handleTransparency,
				isHidden,
				handleVisibility,
				headerColors,
				handleHeaderColors,
			}}
		>
			{children}
		</headerContext.Provider>
	);
};
