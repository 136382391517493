import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import { StyledInputFile } from "./StyledInputFile";

const InputFile = (props) => {
	const { t } = useTranslation();

	const input = useRef();
	const [valueI, setValue] = useState(t("inputFile.1"));

	const handleInput = () => {
		setValue(input.current.files[0].name);
		props.inputFile(input.current.files[0]);
	};

	const handleCancel = () => {
		setValue(t("inputFile.1"));
		input.current.value = "";
	};

	return (
		<StyledInputFile>
			<input
				className='inputFile'
				type='file'
				ref={input}
				onChange={handleInput}
			/>
			<button
				onClick={() => {
					valueI === t("inputFile.1") && input.current.click();
				}}
			>
				upload
				{valueI !== t("inputFile.1") && (
					<span onClick={handleCancel}>&#x2715;</span>
				)}
			</button>
			{valueI && <p>{valueI}</p>}
		</StyledInputFile>
	);
};

export default InputFile;
