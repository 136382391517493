import { realtimeDatabase } from "../realtimeFir";
import { ref, set } from "firebase/database";

export function sendVideoAds(data, path) {
	console.log("sending...");

	set(ref(realtimeDatabase, path + data.dateKey), {
		fileOne: data.fileOne,
		fileTwo: data.fileTwo,
		fileOneName: data.fileOneName,
		fileTwoName: data.fileTwoName,
		mail: data.mail,
		colori: data.colori,
		puntiDiForza: data.puntiDiForza,
		ideaVideo: data.ideaVideo,
		linkProdotto: data.linkProdotto,
		nomeProdotto: data.nomeProdotto,
		storeConcorrenti: data.storeConcorrenti,
		dettagliExtra: data.dettagliExtra,
		contatti: data.contatti,
		dateKey: data.dateKey,
		videoPack: data.videoPack,
	});
}

export function sendSitoWeb(data, path) {
	console.log("sending...");

	set(ref(realtimeDatabase, path + data.dateKey), {
		fileOne: data.fileOne,
		fileTwo: data.fileTwo,
		fileOneName: data.fileOneName,
		fileTwoName: data.fileTwoName,
		cms: data.cms,
		mail: data.mail,
		colori: data.colori,
		linkProgetto: data.linkProgetto,
		ideaSito: data.ideaSito,
		nomeSito: data.nomeSito,
		sitiIspirazione: data.sitiIspirazione,
		pagine: {
			titoloPag1: data.pagine.titoloPag1,
			descrPag1: data.pagine.descrPag1,
			titoloPag2: data.pagine.titoloPag2,
			descrPag2: data.pagine.descrPag2,
			titoloPag3: data.pagine.titoloPag3,
			descrPag3: data.pagine.descrPag3,
			titoloPag4: data.pagine.titoloPag4,
			descrPag4: data.pagine.descrPag4,
			titoloPag5: data.pagine.titoloPag5,
			descrPag5: data.pagine.descrPag5,
		},
		dettagliExtra: data.dettagliExtra,
		contatti: data.contatti,
		slider: {
			sliderFM: data.slider.sliderFM,
			sliderShape: data.slider.sliderShape,
			classicoModerno: data.slider.classicoModerno,
			giocosoSerio: data.slider.giocosoSerio,
			coloratoPulito: data.slider.coloratoPulito,
			sempliceComplesso: data.slider.sempliceComplesso,
		},
		dateKey: data.dateKey,
		sitePack: data.sitePack,
	});
}

export function sendRevisioneSitoWeb(data, path) {
	console.log("sending...");

	set(ref(realtimeDatabase, path + data.dateKey), {
		mail: data.mail,
		ideaRevisione: data.ideaRevisione,
		sitiIspirazione: data.sitiIspirazione,
		linkSito: data.linkSito,
		dettagliExtra: data.dettagliExtra,
		contatti: data.contatti,
		dateKey: data.dateKey,
		sitePack: data.sitePack,
		cms: data.cms,
	});
}
