import { useState, useEffect, useRef, useContext } from "react";
import storageOne from "../../firebase/storageFir";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
	sendRevisioneSitoWeb,
	sendSitoWeb,
} from "../../firebase/SendModuloOrdinazioneVideoAds.js/Send";
import { useSearchParams } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import { useTranslation } from "react-i18next";

import { linkmink } from "../../linkmink/LinkMinkRef";

import InputLabel from "../../components/InputLabel/InputLabel";
import TextArea from "../../components/TextArea/TextArea";
import { StyledModuloOrdinaSitoWeb } from "./StyledModuloOrdinaSitoWeb";

import whatsappQR from "../../images/whatsappQR.webp";
import InputFile from "../../components/InputFile/InputFile";
import { useNavigate } from "react-router-dom";
import { headerContext } from "../../context/headerContext";
import { RangeSliderLRValues } from "../../components/SliderRange/SliderRange";

const ModuloOrdinaSitoWeb = () => {
	const [inputFileOne, setInputFileOne] = useState(null);
	const [inputFileTwo, setInputFileTwo] = useState(null);
	const [mail, setMail] = useState();
	const [colori, setColori] = useState("none");
	const [linkProgetto, setLinkProgetto] = useState("none");
	const [ideaSito, setIdeaSito] = useState("none");
	const [nomeSito, setNomeSito] = useState("none");
	const [sitiIspirazione, setSitiIspirazione] = useState("none");
	const [titoloPag1, setTitoloPag1] = useState("none");
	const [descrPag1, setDescPag1] = useState("none");
	const [titoloPag2, setTitoloPag2] = useState("none");
	const [descrPag2, setDescPag2] = useState("none");
	const [titoloPag3, setTitoloPag3] = useState("none");
	const [descrPag3, setDescPag3] = useState("none");
	const [titoloPag4, setTitoloPag4] = useState("none");
	const [descrPag4, setDescPag4] = useState("none");
	const [titoloPag5, setTitoloPag5] = useState("none");
	const [descrPag5, setDescPag5] = useState("none");
	const [dettagliExtra, setDettagliExtra] = useState("none");
	const [contatti, setContatti] = useState("none");
	const [sliderFM, setSliderFM] = useState(5);
	const [sliderShape, setSliderShape] = useState(5);
	const [classicoModerno, setClassicoModerno] = useState(5);
	const [giocosoSerio, setGiocosoSerio] = useState(5);
	const [coloratoPulito, setColoratoPulito] = useState(5);
	const [sempliceComplesso, setSempliceComplesso] = useState(5);
	const [ideaRevisione, setIdeaRevisione] = useState("none");
	const [linkSito, setLinkSito] = useState("none");
	const [CMS, setCMS] = useState("none");

	const [nessunFile, setNessunFile] = useState(false);

	const [searchParams, setSearchParams] = useSearchParams();
	const [sitePack, setSitePack] = useState();

	const [isMobile, setIsMobile] = useState();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalText, setModalText] = useState();
	const [isUploading, setIsUploading] = useState();
	const [uploadProgress, setUploadProgress] = useState();
	const [indexPages, setIndexPages] = useState(1);
	let firstUpdate = useRef(0);

	const { t } = useTranslation();

	const navigate = useNavigate();

	const headerCtx = useContext(headerContext);
	headerCtx.handleTransparency(false);

	const handleResize = () => {
		if (window.innerWidth < 980) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
		return;
	};

	useEffect(() => {
		setSitePack(searchParams.get("site_pack"));
	}, []);

	const setGenerateDate = () => {
		var date = new Date();
		var datetime = `${date.getFullYear()}${
			date.getMonth() + 1
		}${date.getDay()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}${date.getMilliseconds()}`;
		return datetime;
	};

	useEffect(() => {
		handleResize();
		window.addEventListener("resize", handleResize);
	}, []);

	const isValid = (email) => {
		return /\S+@\S+\.\S+/.test(email);
	};

	useEffect(() => {
		const handleChange = (event) => {
			if (window.location.pathname === "/modulo-ordinazione-sito-web") {
				event.preventDefault();
				event.returnValue = "";
				return "";
			}
		};
		window.addEventListener("beforeunload", handleChange);
		window.addEventListener("popstate", handleChange);
	}, []);

	const setTextTo = (text) => {
		return text;
	};

	const handleSumbit = () => {
		setIsUploading(true);
	};

	useEffect(() => {
		//but why renders two times??
		if (firstUpdate.current < 2) {
			firstUpdate.current += 1;
			return;
		}
		if (isUploading) {
			handleSumbit();
			sendData();
		}
	}, [isUploading]);

	const sendData = (fileOne, fileTwo) => {
		const datetime = setGenerateDate();

		if (sitePack === "CMS_rev") {
			if (isValid(mail)) {
				const dataRev = {
					mail: mail,
					cms: CMS,
					ideaRevisione: ideaRevisione,
					sitiIspirazione: sitiIspirazione,
					dettagliExtra: dettagliExtra,
					linkSito: linkSito,
					contatti: contatti,
					dateKey: setTextTo(datetime),
					sitePack: sitePack,
				};
				console.log(dataRev);
				sendRevisioneSitoWeb(dataRev, "ordinazioneSitoWeb/modulo/ordini/");
				navigate("/grazie-per-il-tuo-ordine", { replace: true });
			} else {
				setIsModalOpen(true);
				setModalText(t("moduleWebsiteOverlay.1"));
			}
			setIsUploading(false);
			// const options = {
			// 	// type: "Pro (Annual)",
			// 	// status: "trialing",
			// 	match_with: "customer", // allowed values are 'customer', 'subscription'
			// 	livemode: true,
			// };
			// linkmink
			// 	.conversion(mail, options)
			// 	.then((success) => console.log(`success: ${success}`))
			// 	.catch((error) => console.log(`error: ${error}`));
			// return;
		}

		const sendToDatabaseOrder = (handleData) => {
			if (isValid(mail)) {
				const data = handleData;
				console.log(data);
				sendSitoWeb(data, "ordinazioneSitoWeb/modulo/ordini/");
				navigate("/grazie-per-il-tuo-ordine", { replace: true });
			} else {
				setIsModalOpen(true);
				setModalText(t("moduleOrderWebsiteOverlay.1"));
			}
		};
		fileOne = inputFileOne;
		fileTwo = inputFileTwo;

		let data = {
			fileOne: fileOne,
			fileTwo: fileTwo,
			mail: mail,
			cms: CMS,
			colori: colori,
			linkProgetto: linkProgetto,
			ideaSito: ideaSito,
			nomeSito: nomeSito,
			sitiIspirazione: sitiIspirazione,
			pagine: {
				titoloPag1: titoloPag1,
				descrPag1: descrPag1,
				titoloPag2: titoloPag2,
				descrPag2: descrPag2,
				titoloPag3: titoloPag3,
				descrPag3: descrPag3,
				titoloPag4: titoloPag4,
				descrPag4: descrPag4,
				titoloPag5: titoloPag5,
				descrPag5: descrPag5,
			},
			dettagliExtra: dettagliExtra,
			contatti: contatti,
			slider: {
				sliderFM: sliderFM,
				sliderShape: sliderShape,
				classicoModerno: classicoModerno,
				giocosoSerio: giocosoSerio,
				coloratoPulito: coloratoPulito,
				sempliceComplesso: sempliceComplesso,
			},
			dateKey: setTextTo(datetime),
			sitePack: sitePack,
		};

		//* --------------------------------------------------------------------------------------------
		//* ---------------------------------BOTH FILES ARE UPLOADED------------------------------------
		//* --------------------------------------------------------------------------------------------
		if (inputFileOne !== null && inputFileTwo !== null) {
			try {
				const fileRefOnFirStorageOne =
					"/modulo-creazione-sito-web/immagini-inviate/" +
					`${mail}/` +
					`_${datetime}_` +
					inputFileOne.name;
				const fileLocalRefOne = ref(storageOne, fileRefOnFirStorageOne);

				const fileRefOnFirStorageTwo =
					"/modulo-creazione-sito-web/immagini-inviate/" +
					`${mail}/` +
					`_${datetime}_` +
					inputFileTwo.name;
				const fileLocalRefTwo = ref(storageOne, fileRefOnFirStorageTwo);

				const taskOne = uploadBytesResumable(fileLocalRefOne, inputFileOne);
				const taskTwo = uploadBytesResumable(fileLocalRefTwo, inputFileTwo);

				taskOne.on(
					"state_changed",
					(snapshot) => {
						// Observe state change events such as progress, pause, and resume
						// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
						const progress =
							(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
						setUploadProgress(progress.toFixed(0));
					},
					(error) => {
						setIsUploading(false);
						setIsModalOpen(true);
						setModalText(t("moduleOrderWebsiteOverlay.2"));
					},
					() => {
						getDownloadURL(taskOne.snapshot.ref).then((downloadURLOne) => {
							taskTwo.on(
								"state_changed",
								(snapshot) => {
									// Observe state change events such as progress, pause, and resume
									// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
									const progress =
										(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
									setUploadProgress(progress.toFixed(0));
								},
								(error) => {
									setIsUploading(false);
									setIsModalOpen(true);
									setModalText(t("moduleOrderWebsiteOverlay.2"));
								},
								() => {
									getDownloadURL(taskOne.snapshot.ref).then(
										(downloadURLTwo) => {
											data = {
												...data,
												fileOne: setTextTo(downloadURLOne),
												fileTwo: setTextTo(downloadURLTwo),
												fileOneName: fileOne.name,
												fileTwoName: fileTwo.name,
											};

											sendToDatabaseOrder(data);
											setIsUploading(false);
											const options = {
												// type: "Pro (Annual)",
												// status: "trialing",
												match_with: "customer", // allowed values are 'customer', 'subscription'
												livemode: true,
											};
											linkmink
												.conversion(mail, options)
												.then((success) => console.log(`success: ${success}`))
												.catch((error) => console.log(`error: ${error}`));
										}
									);
								}
							);
						});
					}
				);
			} catch (err) {
				console.log(err);
			}

			//* --------------------------------------------------------------------------------------------
			//* ----------------------------------ONE FILE IS UPLOADED--------------------------------------
			//* --------------------------------------------------------------------------------------------
		} else if (inputFileOne !== null || inputFileTwo !== null) {
			try {
				let fileLocalRef = "";
				let objLocOne = "none";
				let objLocTwo = "none";
				let currentFile;
				let fileOneName = "none";
				let fileTwoName = "none";

				if (inputFileOne !== null) {
					const fileRefOnFirStorageOne =
						"/modulo-creazione-sito-web/immagini-inviate/" +
						`${mail}/` +
						`_${datetime}_` +
						inputFileOne.name;
					fileLocalRef = ref(storageOne, fileRefOnFirStorageOne);
					currentFile = inputFileOne;
					fileOneName = fileOne.name;
				} else if (inputFileTwo !== null) {
					const fileRefOnFirStorageTwo =
						"/modulo-creazione-sito-web/immagini-inviate/" +
						`${mail}/` +
						`_${datetime}_` +
						inputFileTwo.name;
					fileLocalRef = ref(storageOne, fileRefOnFirStorageTwo);
					currentFile = inputFileTwo;
					fileTwoName = fileTwo.name;
				}

				const task = uploadBytesResumable(fileLocalRef, currentFile);

				task.on(
					"state_changed",
					(snapshot) => {
						// Observe state change events such as progress, pause, and resume
						// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
						const progress =
							(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
						setUploadProgress(progress.toFixed(0));
					},
					(error) => {
						setIsUploading(false);
						setIsModalOpen(true);
						setModalText(t("moduleOrderWebsiteOverlay.2"));
					},
					() => {
						getDownloadURL(task.snapshot.ref).then((downloadURL) => {
							if (inputFileOne !== null) {
								objLocOne = downloadURL;
								objLocTwo = "none";
							} else if (inputFileTwo !== null) {
								objLocTwo = downloadURL;
								objLocOne = "none";
							}
							data = {
								...data,
								fileOne: setTextTo(objLocOne),
								fileTwo: setTextTo(objLocTwo),
								fileOneName: setTextTo(fileOneName),
								fileTwoName: setTextTo(fileTwoName),
							};

							sendToDatabaseOrder(data);
							setIsUploading(false);
							const options = {
								// type: "Pro (Annual)",
								// status: "trialing",
								match_with: "customer", // allowed values are 'customer', 'subscription'
								livemode: true,
							};
							linkmink
								.conversion(mail, options)
								.then((success) => console.log(`success: ${success}`))
								.catch((error) => console.log(`error: ${error}`));
						});
					}
				);
			} catch (err) {
				console.log(err);
			}

			//* --------------------------------------------------------------------------------------------
			//* ------------------------------------NO FILE UPLOADED----------------------------------------
			//* --------------------------------------------------------------------------------------------
		} else if (inputFileOne === null && inputFileTwo === null) {
			setNessunFile(true);
			sendToDatabaseOrder(data);
			setIsUploading(false);
			const options = {
				// type: "Pro (Annual)",
				// status: "trialing",
				match_with: "customer", // allowed values are 'customer', 'subscription'
				livemode: true,
			};
			linkmink
				.conversion(mail, options)
				.then((success) => console.log(`success: ${success}`))
				.catch((error) => console.log(`error: ${error}`));
		}
	};

	//?----------------------------------------------------------------------------
	//? Page Cards ------------------------------------------------------

	const items = [
		<div className={`card card--1 ${indexPages !== 1 && "notVisible"}`}>
			<p>{t("moduleOrderWebsitePages.pages.1")}</p>
			<InputLabel
				text={t("moduleOrderWebsitePages.1")}
				isRequired={true}
				inputText={setTitoloPag1}
			/>
			<p>{t("moduleOrderWebsitePages.2")}</p>
			<TextArea
				placeholder={t("moduleOrderWebsitePages.3")}
				inputText={setDescPag1}
			/>
		</div>,
		<div className={`card card--2 ${indexPages !== 2 && "notVisible"}`}>
			<p>{t("moduleOrderWebsitePages.pages.2")}</p>
			<InputLabel
				text={t("moduleOrderWebsitePages.1")}
				isRequired={true}
				inputText={setTitoloPag2}
			/>
			<p>{t("moduleOrderWebsitePages.2")}</p>
			<TextArea
				placeholder={t("moduleOrderWebsitePages.3")}
				inputText={setDescPag2}
			/>
		</div>,
		<div className={`card card--3 ${indexPages !== 3 && "notVisible"}`}>
			<p>{t("moduleOrderWebsitePages.pages.3")}</p>
			<InputLabel
				text={t("moduleOrderWebsitePages.1")}
				isRequired={true}
				inputText={setTitoloPag3}
			/>
			<p>{t("moduleOrderWebsitePages.2")}</p>
			<TextArea
				placeholder={t("moduleOrderWebsitePages.3")}
				inputText={setDescPag3}
			/>
		</div>,
		<div className={`card card--4 ${indexPages !== 4 && "notVisible"}`}>
			<p>{t("moduleOrderWebsitePages.pages.4")}</p>
			<InputLabel
				text={t("moduleOrderWebsitePages.1")}
				isRequired={true}
				inputText={setTitoloPag4}
			/>
			<p>{t("moduleOrderWebsitePages.2")}</p>
			<TextArea
				placeholder={t("moduleOrderWebsitePages.3")}
				inputText={setDescPag4}
			/>
		</div>,
		<div className={`card card--5 ${indexPages !== 5 && "notVisible"}`}>
			<p>{t("moduleOrderWebsitePages.pages.5")}</p>
			<InputLabel
				text={t("moduleOrderWebsitePages.1")}
				isRequired={true}
				inputText={setDescPag5}
			/>
			<p>{t("moduleOrderWebsitePages.2")}</p>
			<TextArea
				placeholder={t("moduleOrderWebsitePages.3")}
				inputText={setTitoloPag5}
			/>
		</div>,
	];

	return (
		<StyledModuloOrdinaSitoWeb>
			{isUploading && (
				<div className='loadingOverlay'>
					<div className='modal'>
						<h3 className='title'>{t("moduleOrderWebsiteOverlay.3")}</h3>
						<span>
							{nessunFile
								? t("moduleOrderWebsiteOverlay.4")
								: t("moduleOrderWebsiteOverlay.5")}
						</span>
						<div className='loader' />
						{!nessunFile && (
							<span className='progress'>{`${uploadProgress}%`}</span>
						)}
					</div>
				</div>
			)}
			{isModalOpen && (
				<div className='overlayModal'>
					<div className='modal'>
						<h3>Oops...</h3>
						<span className='span'>{modalText}</span>
						<button onClick={() => setIsModalOpen(false)}>OK</button>
					</div>
				</div>
			)}
			<div className='firstSectionDiv'>
				<div className='textWrapper'>
					<span className='title'>{t("moduleOrderWebsiteHeader.1")}</span>
					<span className='subtitle'>{t("moduleOrderWebsiteHeader.2")}</span>
				</div>
			</div>
			{(sitePack === "CMS_5pag" || sitePack === "CC_sP") && (
				<div className='questionsWrapper'>
					<div className='leftWrapper'>
						<div className='cat'>
							<span className='title'>{t("moduleOrderWebsiteFormLeft.1")}</span>
							<p>{t("moduleOrderWebsiteFormLeft.2")}</p>
							<InputLabel
								text={t("moduleOrderWebsiteFormLeft.3")}
								inputText={setMail}
								isRequired={true}
							/>
							{sitePack === "CMS_5pag" && (
								<>
									<p>{t("moduleOrderWebsiteFormLeft.4")}</p>
									<InputLabel
										text={t("moduleOrderWebsiteFormLeft.5")}
										inputText={setCMS}
									/>
								</>
							)}
							<p>{t("moduleOrderWebsiteFormLeft.6")}</p>
							<InputLabel
								text={t("moduleOrderWebsiteFormLeft.7")}
								inputText={setColori}
							/>
							<p>{t("moduleOrderWebsiteFormLeft.8")}</p>
							<InputLabel
								text={t("moduleOrderWebsiteFormLeft.9")}
								isRequired={true}
								inputText={setLinkProgetto}
							/>
							<p>{t("moduleOrderWebsiteFormLeft.10")}</p>
							<TextArea
								placeholder={t("moduleOrderWebsiteFormLeft.11")}
								inputText={setIdeaSito}
							/>
							<p>{t("moduleOrderWebsiteFormLeft.12")}</p>
							<InputLabel
								text={t("moduleOrderWebsiteFormLeft.13")}
								inputText={setNomeSito}
							/>
							<p>{t("moduleOrderWebsiteFormLeft.14")}</p>
							<TextArea
								placeholder={t("moduleOrderWebsiteFormLeft.15")}
								inputText={setSitiIspirazione}
							/>
						</div>
						{/* -------------------- START SEZIONE SLIDER ------------------------- */}
						<div className='cat'>
							<span className='title'>
								{t("moduleOrderWebsiteFormLeft.slider.1")}
							</span>
							<p className='subtitle-slider'>
								{t("moduleOrderWebsiteFormLeft.slider.2")}
							</p>
							<div className='slider'>
								<RangeSliderLRValues
									setter={setSliderFM}
									state={sliderFM}
									left={t("moduleOrderWebsiteFormLeft.slider.3")}
									right={t("moduleOrderWebsiteFormLeft.slider.4")}
								/>
								<RangeSliderLRValues
									setter={setSliderShape}
									state={sliderShape}
									left={t("moduleOrderWebsiteFormLeft.slider.5")}
									right={t("moduleOrderWebsiteFormLeft.slider.6")}
								/>
								<RangeSliderLRValues
									setter={setClassicoModerno}
									state={classicoModerno}
									left={t("moduleOrderWebsiteFormLeft.slider.7")}
									right={t("moduleOrderWebsiteFormLeft.slider.8")}
								/>
								<RangeSliderLRValues
									setter={setGiocosoSerio}
									state={giocosoSerio}
									left={t("moduleOrderWebsiteFormLeft.slider.9")}
									right={t("moduleOrderWebsiteFormLeft.slider.10")}
								/>
								<RangeSliderLRValues
									setter={setColoratoPulito}
									state={coloratoPulito}
									left={t("moduleOrderWebsiteFormLeft.slider.11")}
									right={t("moduleOrderWebsiteFormLeft.slider.12")}
								/>
								<RangeSliderLRValues
									setter={setSempliceComplesso}
									state={sempliceComplesso}
									left={t("moduleOrderWebsiteFormLeft.slider.13")}
									right={t("moduleOrderWebsiteFormLeft.slider.14")}
								/>
							</div>
						</div>
						{/* -------------------- END SEZIONE SLIDER ------------------------- */}
					</div>
					<div className='rightWrapper'>
						<div className='cat'>
							<span className='title'>
								{t("moduleOrderWebsiteFormRight.1")}
							</span>
							<p>{t("moduleOrderWebsiteFormRight.2")}</p>
							<div className='divUploadButtons'>
								<InputFile inputFile={setInputFileOne} />
								<InputFile inputFile={setInputFileTwo} />
							</div>
						</div>
						{/* ---------------- START SEZIONE INFO PAGINE ------------------- */}
						{sitePack === "CMS_5pag" && (
							<div className='cat'>
								<span className='title'>
									{t("moduleOrderWebsiteFormRight.3")}
								</span>
								<p className='p'>{t("moduleOrderWebsiteFormRight.4")}</p>
								<div className='selector'>
									<p
										className={`p ${indexPages === 1 && "selected"}`}
										onClick={() => {
											setIndexPages(1);
											window.getElementById("id").target.value = "";
										}}
									>
										{t("moduleOrderWebsiteFormRight.5")} 1
									</p>
									<p
										className={`p ${indexPages === 2 && "selected"}`}
										onClick={() => {
											setIndexPages(2);
										}}
									>
										{t("moduleOrderWebsiteFormRight.5")} 2
									</p>
									<p
										className={`p ${indexPages === 3 && "selected"}`}
										onClick={() => {
											setIndexPages(3);
										}}
									>
										{t("moduleOrderWebsiteFormRight.5")} 3
									</p>
									<p
										className={`p ${indexPages === 4 && "selected"}`}
										onClick={() => {
											setIndexPages(4);
										}}
									>
										{t("moduleOrderWebsiteFormRight.5")} 4
									</p>
									<p
										className={`p ${indexPages === 5 && "selected"}`}
										onClick={() => {
											setIndexPages(5);
										}}
									>
										{t("moduleOrderWebsiteFormRight.5")} 5
									</p>
								</div>
								{items}
							</div>
						)}
						{/* ----------------- END SEZIONE INFO PAGINE ------------------- */}
						<div className='cat'>
							<span className='title'>
								{t("moduleOrderWebsiteFormRight.6")}
							</span>
							<p>{t("moduleOrderWebsiteFormRight.7")}</p>
							<TextArea
								placeholder='scrivi qui...'
								inputText={setDettagliExtra}
							/>
						</div>
						<div className='cat'>
							<span className='title'>
								{t("moduleOrderWebsiteFormRight.9")}
							</span>
							<p>{t("moduleOrderWebsiteFormRight.10")}</p>
							<InputLabel
								text={t("moduleOrderWebsiteFormRight.11")}
								inputText={setContatti}
							/>
						</div>
						<div className='divInvia'>
							<span>{t("moduleOrderWebsiteFormRight.12")}</span>
							<button type='submit' onClick={handleSumbit}>
								{t("moduleOrderWebsiteFormRight.13")}
							</button>
						</div>
						<div className='contattoQR'>
							<h2>{t("moduleOrderWebsiteFormRight.14")}</h2>
							<p>
								{t("moduleOrderWebsiteFormRight.15")}
								<a href='https://wa.me/00393276216453' className='a'>
									<b>+39 327 621 6453</b>
								</a>
								{!isMobile && t("moduleOrderWebsiteFormRight.16")}
								{t("moduleOrderWebsiteFormRight.17")}
								<a href='mailto:informazioni@servizidiamond.it' className='a'>
									<b>informazioni@servizidiamond.it</b>
								</a>
							</p>
							<img src={whatsappQR} alt='whatsapp qr' />
						</div>
					</div>
				</div>
			)}
			{sitePack === "CMS_rev" && (
				<div className='questionsWrapper'>
					<div className='leftWrapper'>
						<div className='cat'>
							<span className='title'>{t("moduleOrderWebsiteFormLeft.1")}</span>
							<p>{t("moduleOrderWebsiteFormLeft.2")}</p>
							<InputLabel
								text={t("moduleOrderWebsiteFormLeft.3")}
								inputText={setMail}
								isRequired={true}
							/>
							<p>{t("moduleOrderWebsiteFormLeft.16")}</p>
							<InputLabel
								text={t("moduleOrderWebsiteFormLeft.17")}
								inputText={setCMS}
							/>
							<p>{t("moduleOrderWebsiteFormLeft.18")}</p>
							<TextArea
								placeholder={t("moduleOrderWebsiteFormLeft.19")}
								inputText={setIdeaRevisione}
							/>
							<p>{t("moduleOrderWebsiteFormLeft.14")}</p>
							<TextArea
								placeholder={t("moduleOrderWebsiteFormLeft.15")}
								inputText={setSitiIspirazione}
							/>
							<p>{t("moduleOrderWebsiteFormLeft.20")}</p>
							<InputLabel
								text={t("moduleOrderWebsiteFormLeft.21")}
								inputText={setLinkSito}
							/>
						</div>
					</div>
					<div className='rightWrapper'>
						<div className='cat'>
							<span className='title'>
								{t("moduleOrderWebsiteFormRight.6")}
							</span>
							<p>{t("moduleOrderWebsiteFormRight.7")}</p>
							<TextArea
								placeholder={t("moduleOrderWebsiteFormRight.8")}
								inputText={setDettagliExtra}
							/>
							<div className='cat'>
								<span className='title'>
									{t("moduleOrderWebsiteFormRight.9")}
								</span>
								<p>
									{t("moduleOrderWebsiteFormRight.18")}
									<a href='https://wa.me/00393276216453' className='a'>
										+39 327 621 6453
									</a>
									.
								</p>
								<InputLabel
									text={t("moduleOrderWebsiteFormRight.11")}
									inputText={setContatti}
								/>
							</div>
						</div>
						<div className='divInvia'>
							<span>{t("moduleOrderWebsiteFormRight.12")}</span>
							<button type='submit' onClick={handleSumbit}>
								{t("moduleOrderWebsiteFormRight.13")}
							</button>
						</div>
						<div className='contattoQR'>
							<h2>{t("moduleOrderWebsiteFormRight.14")}</h2>
							<p>
								{t("moduleOrderWebsiteFormRight.15")}
								<a href='https://wa.me/00393276216453' className='a'>
									<b>+39 327 621 6453</b>
								</a>
								{!isMobile && t("moduleOrderWebsiteFormRight.16")}
								{t("moduleOrderWebsiteFormRight.17")}
								<a href='mailto:informazioni@servizidiamond.it' className='a'>
									<b>informazioni@servizidiamond.it</b>
								</a>
							</p>
							<img src={whatsappQR} alt='whatsapp qr' />
						</div>
					</div>
				</div>
			)}
		</StyledModuloOrdinaSitoWeb>
	);
};

export default ModuloOrdinaSitoWeb;
