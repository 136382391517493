import { headerContext } from "../../context/headerContext";
import { useContext, useEffect, useState, useRef } from "react";
import { animated } from "react-spring";
import { use3dEffect } from "../../modules/use3dEffect";
import { useTranslation } from "react-i18next";

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

import project from "../../images/OrdinaSitoWeb/project.png";
import idea from "../../images/idea.png";
import change from "../../images/change.png";
import logo_gray from "../../images/logo_gray.png";
import design_gray from "../../images/design_gray.png";
import ad_gray from "../../images/ad_gray.png";
import rocket from "../../images/rocket.gif";
import star from "../../images/star.gif";
import logos from "../../images/logos_grid.png";
import startup_website from "../../images/startup_website.png";
import videoAd from "../../images/firstImage.png";

import { StyledHome } from "./StyledHome";
import TextHomeTitle from "../../components/PureComponents/TitleHomeAnimated";
import ContactSection from "../../components/Contact/ContactSection";

const Home = () => {
	const [offsetY, setOffsetY] = useState(0);
	const [selector, setSelector] = useState("sitiWeb");
	const [vDim, setVDim] = useState("1000");
	const [traXBlob, setTraXBlob] = useState("489.2012423328408");
	const [traYBlob, setTraYBlob] = useState("457.0079178814801)");
	const [viewBlob, setViewBlob] = useState(true);

	const { t } = useTranslation();

	const headerCtx = useContext(headerContext);
	headerCtx.handleTransparency(true);
	headerCtx.handleHeaderColors(true);

	const handleOffset = () => setOffsetY(window.scrollY);

	const imageRef = useRef();
	const { style, ...mouseHandlers } = use3dEffect(imageRef);

	useEffect(() => {
		window.addEventListener("scroll", handleOffset);
		return () => window.removeEventListener("scroll", handleOffset);
	}, [offsetY]);

	useEffect(() => {
		if (window.innerWidth < 600) {
			setViewBlob(false);
		} else if (window.innerWidth < 860) {
			setVDim("950");
			setTraXBlob("459.2012423328408");
			setTraYBlob("427.0079178814801)");
		}
	}, []);

	const handleClick = () => {
		window.scrollBy({
			top: 600,
			behavior: "smooth",
		});
	};

	console.log(window.navigator.userLanguage || window.navigator.language);

	return (
		<StyledHome offsetY={offsetY}>
			<div className='divImageTop' style={{ marginTop: `${offsetY * 0.7}px` }}>
				<div className='divText' style={{ marginTop: `(${offsetY * -1.5}px)` }}>
					<h2>{t("headerHomeTitle.1")}</h2>
					<p>{t("headerHomeSubtitle.1")}</p>
					<button onClick={handleClick} className='buttonScopri'>
						{t("headerHomeLearnMore.1")}
					</button>
				</div>
			</div>
			<section
				className='section-first'
				style={{ marginTop: `${offsetY * -1.5}px` }}
			>
				{viewBlob && <TextHomeTitle />}
				<div className='left-text-wrapper'>
					<h3 className='h3'>
						{t("youDreamHome.1")}
						<br /> {t("youDreamHome.2")}
					</h3>
					<p className='p'>
						{t("sectionFirstHomeP1.1")}
						<br />
						<br />
						<span className='span-p'>
							{t("sectionFirstHomeP1.2")}
							<span>{t("sectionFirstHomeP1.3")}</span>
						</span>
					</p>
				</div>
				<div className='right-images-wrapper'>
					{viewBlob && (
						<svg
							id='blob'
							viewBox={`0 0 ${vDim} ${vDim}`}
							width='100%'
							height='auto'
							xmlns='http://www.w3.org/2000/svg'
							xmlnsXlink='http://www.w3.org/1999/xlink'
							version='1.1'
						>
							<g transform={`translate(${traXBlob} ${traYBlob}`}>
								<path id='blob-1' fill='#933ff9'>
									<animate
										attributeName='d'
										dur='25s'
										repeatCount='indefinite'
										values='M224.5 -296.2C278.8 -221.9 302.4 -139.7 304.4 -63.3C306.5 13.2 287.1 83.8 262.7 175.6C238.3 267.4 208.9 380.4 141.3 417.9C73.6 455.4 -32.3 417.5 -111 363.5C-189.7 309.4 -241.2 239.2 -290.6 163.2C-339.9 87.1 -387.1 5.3 -366.4 -56.4C-345.8 -118.1 -257.3 -159.6 -184.9 -230.7C-112.5 -301.7 -56.2 -402.4 14.4 -419.5C85.1 -436.7 170.1 -370.4 224.5 -296.2;
									M198.7 -212.8C284.8 -164.5 400.9 -127.8 421.2 -66.4C441.4 -4.9 365.8 81.3 295 138.9C224.3 196.6 158.5 225.6 90.9 250.5C23.4 275.3 -45.9 296 -104.7 278.6C-163.4 261.1 -211.6 205.6 -261.5 140.6C-311.4 75.6 -363 1.3 -357.1 -68.3C-351.2 -137.9 -287.8 -202.6 -218.7 -253.9C-149.6 -305.2 -74.8 -343.1 -9.3 -332.1C56.2 -321 112.5 -261.1 198.7 -212.8;
									M179.6 -222.5C222.9 -177.7 241.5 -111.8 245.3 -49.3C249.1 13.2 238.2 72.2 222.5 155.8C206.9 239.4 186.4 347.6 118.5 407.9C50.7 468.3 -64.4 480.8 -144.7 435.3C-225.1 389.7 -270.6 286.1 -305.7 192.4C-340.7 98.7 -365.4 15 -356.8 -68C-348.3 -151 -306.5 -233.3 -241.5 -274.4C-176.6 -315.4 -88.3 -315.2 -10.1 -303.2C68.1 -291.2 136.3 -267.4 179.6 -222.5;
									M224.5 -296.2C278.8 -221.9 302.4 -139.7 304.4 -63.3C306.5 13.2 287.1 83.8 262.7 175.6C238.3 267.4 208.9 380.4 141.3 417.9C73.6 455.4 -32.3 417.5 -111 363.5C-189.7 309.4 -241.2 239.2 -290.6 163.2C-339.9 87.1 -387.1 5.3 -366.4 -56.4C-345.8 -118.1 -257.3 -159.6 -184.9 -230.7C-112.5 -301.7 -56.2 -402.4 14.4 -419.5C85.1 -436.7 170.1 -370.4 224.5 -296.2'
									></animate>
								</path>
							</g>
						</svg>
					)}
					<div className='text-box text-box--1'>
						<img src={idea} alt='listening' className='img img--1' />
						<h2 className='h2'>{t("sectionFirstHomeFloatingCards.1")}</h2>
						<p className='p'>{t("sectionFirstHomeFloatingCards.2")}</p>
					</div>
					<div className='text-box text-box--2'>
						<img src={project} alt='prototyping' className='img img--2' />
						<h2 className='h2'>{t("sectionFirstHomeFloatingCards.3")}</h2>
						<p className='p'>{t("sectionFirstHomeFloatingCards.4")}</p>
					</div>
					<div className='text-box text-box--3'>
						<img src={change} alt='changing' className='img img--3' />
						<h2 className='h2'>{t("sectionFirstHomeFloatingCards.5")}</h2>
						<p className='p'>{t("sectionFirstHomeFloatingCards.6")}</p>
					</div>
				</div>
			</section>
			<section className='section-second'>
				<div className='background'>
					<div className='wrapper'>
						<div className='left-wrapper'>
							<img src={star} alt='star' className='star star--1' />
							<img src={star} alt='star' className='star star--2' />

							<img src={rocket} alt='rocket' className='rocket' />

							<img src={star} alt='star' className='star star--3' />
						</div>
						<div className='right-wrapper'>
							<h2 className='h2'>
								{t("sectionSecondHomeTitle.1")}
								<br />
								{t("sectionSecondHomeTitle.2")}
							</h2>
							<p className='p'>
								{t("sectionSecondHomeP1.1")}
								<br />
								{t("sectionSecondHomeP1.2")}
							</p>
							<p className='p-ct'>
								<i>{t("sectionSecondHomeP1Cit.1")}</i>
							</p>
						</div>
					</div>
				</div>
			</section>
			<section className='section-third'>
				<div className='wrapper'>
					<div className='left-wrapper'>
						<img src={logos} alt='logos' className='logos-grid' />
					</div>
					<div className='right-wrapper'>
						<h2 className='h2'>{t("sectionThirdHomeTitle.1")}</h2>
						<p className='p'>{t("sectionThirdHomeP1.1")}</p>
						<p className='p-ct'>
							<i>{t("sectionThirdHomeP1.2")}</i>
						</p>
					</div>
				</div>
			</section>
			<section className='section-fourth'>
				<div className='wrapper'>
					<div className='left-wrapper'>
						<div className='selector'>
							<div className='text-wrapper'>
								<h2
									onClick={() =>
										selector !== "sitiWeb" && setSelector("sitiWeb")
									}
									className={`h2 siti-web ${
										selector === "sitiWeb" && "h2--underline"
									}`}
								>
									{t("sectionFourthHomeSelector.1")}
								</h2>
								{selector === "sitiWeb" && (
									<div className='underline'>&nbsp;</div>
								)}
							</div>
							<div className='text-wrapper'>
								<h2
									onClick={() =>
										selector !== "videoAds" && setSelector("videoAds")
									}
									className={`h2 custom-code ${
										selector === "videoAds" && "h2--underline"
									}`}
								>
									{t("sectionFourthHomeSelector.2")}
								</h2>
								{selector === "videoAds" && (
									<div className='underline'>&nbsp;</div>
								)}
							</div>
						</div>
						{selector === "sitiWeb" && (
							<div className='body animate'>
								<h2 className='h2 h2-title'>{t("sectionFourthHomeCard.1")}</h2>
								<p className='p'>{t("sectionFourthHomeCard.2")}</p>
								<a href='/ordina-il-tuo-sito-web' className='a'>
									{t("sectionFourthHomeCard.3")}
								</a>
							</div>
						)}
						{selector === "videoAds" && (
							<div className='body animate'>
								<h2 className='h2 h2-title'>{t("sectionFourthHomeCard.4")}</h2>
								<p className='p'>{t("sectionFourthHomeCard.5")}</p>
								<a href='/ordina-i-tuoi-video-ads' className='a'>
									{t("sectionFourthHomeCard.3")}
								</a>
							</div>
						)}
					</div>
					<div className='right-wrapper'>
						<div className='card'>
							{selector === "sitiWeb" && (
								// <animated.img
								// 	{...mouseHandlers}
								// 	ref={imageRef}
								// 	src={startup_website}
								// 	alt='website'
								// 	className='startup-website'
								// 	style={{
								// 		...style,
								// 	}}
								// />
								<img
									src={startup_website}
									alt='website'
									className='startup-website'
								/>
							)}
							{selector === "videoAds" && (
								<img src={videoAd} alt='video ad' className='video-ads' />
							)}
						</div>
					</div>
				</div>
			</section>
			<section className='section-fifth'>
				<h2 className='h2-title'>{t("sectionFifthHomeTitle.1")}</h2>
				<div className='wrapper-boxes'>
					<div className='wrapper'>
						<img src={logo_gray} alt='logo creation' className='img' />
						<h2 className='title'>{t("sectionFifthHomeCards.1")}</h2>
						<p className='p'>{t("sectionFifthHomeCards.2")}</p>
					</div>
					<div className='wrapper'>
						<img src={design_gray} alt='logo creation' className='img' />
						<h2 className='title'>{t("sectionFifthHomeCards.3")}</h2>
						<p className='p'>{t("sectionFifthHomeCards.4")}</p>
					</div>
					<div className='wrapper'>
						<img src={ad_gray} alt='logo creation' className='img' />
						<h2 className='title'>{t("sectionFifthHomeCards.5")}</h2>
						<p className='p'>{t("sectionFifthHomeCards.6")}</p>
					</div>
				</div>
			</section>
			<ContactSection />
		</StyledHome>
	);
};

export default Home;
