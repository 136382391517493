import styled from "styled-components";

export const StyledMainFooter = styled.div`
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	padding-inline: 8.33vw;
	height: auto;
	/* margin-top: 100px; */
	position: relative;
	bottom: 0;
	left: 0;
	right: 0;

	/* position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background: #fff; */

	.wrapper {
		display: flex;
		@media (max-width: 979px) {
			display: block;
		}
		padding-top: 50px;
		justify-content: space-around;

		.head {
			display: inline-block;
			display: flex;

			@media (max-width: 979px) {
				display: block;
				text-align: center;
			}

			.divLogo {
				box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
				border-radius: 30px;
				height: 100px;
				width: 100px;

				@media (max-width: 979px) {
					display: inline-block;
				}

				.logo {
					height: 100px;
				}
			}

			.textDiv {
				margin-left: 20px;

				@media (max-width: 979px) {
					margin-left: 0;
					margin-top: 20px;
				}

				.title {
					font-family: Avenir;
					font-size: 1.8rem;
					margin-block: 0;
					font-weight: 900;
				}

				.subtitle {
					font-family: Avenir;
					font-weight: lighter;
				}

				.contacts {
					margin-top: 30px;
					font-family: Avenir;
					font-weight: lighter;
				}
			}
		}

		.info {
			padding-bottom: 100px;
			@media (max-width: 979px) {
				padding-top: 50px;
				text-align: center;
			}

			.title {
				margin: 0;
				font-family: Avenir;
			}

			.second {
				margin-top: 20px;
			}

			.third {
				margin-top: 20px;
			}

			.text {
				margin: 0;
				font-family: Avenir;
			}
		}
	}

	.copyrightDiv {
		text-align: center;
		position: absolute;
		bottom: 20px;
		width: 100%;
		left: 0;

		.copyright {
			font-weight: lighter;
		}
	}
`;
