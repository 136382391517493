import styled from "styled-components";

export const StyledTextArea = styled.div`
	/* width: ${(props) => props.width};
	* {
		box-sizing: border-box;
		outline: none;
		font-family: Avenir;
	}

	.div {
		height: 40px;
		width: 100%;
		position: relative;

		& > textarea {
			height: 100%;
			width: 100%;
			border: none;
			border-bottom: 2px solid;
			font-size: 1rem;
		}

		.underline {
			position: absolute;
			bottom: 0px;
			height: 2px;
			width: 100%;
		}

		.underline:before {
			position: absolute;
			content: "";
			height: 100%;
			width: 100%;
			background: ${({ theme }) => theme.colors.primary};
			transform: scaleX(0);
			transition: transform 0.3 ease;
			right: 0;
		}

		& > textarea:focus ~ .underline:before {
			transform: scaleX(1);
		}
	} */
	textarea {
		box-sizing: border-box;
		outline: none;
		width: 100%;
		height: 150px;
		padding: 12px 20px;
		box-sizing: border-box;
		border: 2px solid ${({ theme }) => theme.colors.black};
		border-radius: 0px;
		background-color: #fff;
		font-size: 1rem;
		resize: none;
		font-family: Avenir;
		background-color: transparent;

		&:focus {
			border-color: ${({ theme }) => theme.colors.primary};
			border-radius: 0px;
		}
	}
`;
