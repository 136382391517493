import { createContext, useContext, useState, useEffect } from "react";
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { firAuth } from "../firebase/firAuth";

export const authContext = createContext();

export const useAuth = () => {
	const context = useContext(authContext);
	return context;
};

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const login = (email, password) => {
		return signInWithEmailAndPassword(firAuth, email, password);
	};
	useEffect(() => {
		const unsubuscribe = onAuthStateChanged(firAuth, (currentUser) => {
			setUser(currentUser);
		});
		return () => unsubuscribe();
	}, []);
	return (
		<authContext.Provider value={{ user, login }}>
			{children}
		</authContext.Provider>
	);
};
