import styled from "styled-components";

export const StyledInputFile = styled.div`
	.inputFile {
		display: none;
	}

	button {
		background: ${({ theme }) => theme.colors.primary};
		color: #fff;
		border: 1px solid ${({ theme }) => theme.colors.primary};
		padding: 8px 20px 8px 20px;
		margin-inline: 5.2vw;
		border-radius: 2px;
		display: flex;
		align-items: center;

		&:hover {
			/* border-radius: 30px; */
			background: #fff;
			border: 1px solid ${({ theme }) => theme.colors.primary};
			color: ${({ theme }) => theme.colors.primary};
			cursor: pointer;

			span {
				color: ${({ theme }) => theme.colors.primary};
				cursor: pointer;
			}
		}

		span {
			padding-left: 1.04vw;
			color: #fff;
			font-size: 1rem;
		}
	}

	p {
		width: 60%;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 150px;
	}
`;
