import styled from "styled-components";

export const StyledRangeSlider = styled.div`
	display: flex;
	align-items: flex-end;

	.left,
	.right {
		text-align: center;
		width: 20%;

		&-p {
			margin-top: 1rem !important;
		}

		#rangeValue {
			margin-top: 1rem !important;
			font-weight: 500;
		}
	}

	.slider-div {
		width: 60%;
		margin-bottom: 0.9rem;

		.slider {
			-webkit-appearance: none !important;
			-moz-appearance: none;
			width: 100%;
			background: black;
			height: 2px;
			border: none;
			outline: none;
		}
	}

	input[type="range" i]::-webkit-slider-thumb {
		-webkit-appearance: none !important;
		width: 1.2rem;
		height: 1.2rem;
		background: ${({ theme }) => theme.colors.primary};
		border: none;
		border-radius: 50%;
		cursor: pointer;

		&:hover {
			background: ${({ theme }) => theme.colors.primary};
		}
	}

	.slider::-moz-range-thumb {
		-webkit-appearance: none !important;
		width: 1.2rem;
		height: 1.2rem;
		background: ${({ theme }) => theme.colors.primary};
		border: none;
		border-radius: 50%;
		cursor: pointer;

		&:hover {
			background: ${({ theme }) => theme.colors.primary};
		}
	}
`;
