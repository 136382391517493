import styled from "styled-components";

export const StyledMainHeader = styled.header`
	${(props) =>
		props.isScrolled && "box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);"}
	z-index: 100;

	@media (min-width: 980px) {
		width: 100%;
		position: fixed;
		top: 0;
		background: ${(props) => (props.isScrolled ? "white" : "none")};
	}

	.logo {
		max-height: 40px;
		margin-top: 20px;
		margin-left: ${(props) =>
			props.headerColors ? "8.33vw" : props.isScrolled ? "6.5vw" : "8.33vw"};
		margin-bottom: 15px;
		scale: ${(props) =>
			props.headerColors ? "80%" : props.isScrolled ? "80%" : "120%"};
	}

	.mainHeader {
		position: fixed;
		width: 100%;
	}

	.mainDivHeaderWeb {
		display: flex;
		margin-left: auto;
		justify-content: space-between;
		z-index: 100;
	}

	.nav {
		align-self: center;
		margin-right: 8.33vw;
	}

	.nav ul {
		display: flex;
	}

	.li-wrapper {
		display: flex;
		justify-content: center;
		width: 100px;

		.lang-sel {
			font-weight: 500;
			font-size: 1rem;
			margin-bottom: 0.2rem;
			cursor: pointer;

			span {
				font-weight: bold;
				font-size: 1.3rem;
				line-height: 1;

				color: ${(props) =>
					props.headerColors
						? ({ theme }) => theme.colors.black
						: props.isScrolled
						? ({ theme }) => theme.colors.black
						: "white"};
			}
		}
	}

	.wrapper-langs {
		position: absolute;
		display: flex;
		flex-direction: column;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		background-color: white;
		padding: 1rem;
		border-radius: 10px;
		top: 4.2rem;
		right: 0;
		margin-right: 5.5vw;
		width: 200px;
		z-index: 1;

		&::after {
			content: "";
			background-color: white;
			position: absolute;
			width: 50px;
			height: 50px;
			top: -6px;
			right: calc(3.9vw + 10px);
			z-index: -1;
			transform: rotate(45deg);
			border-radius: 5px;
		}

		.btn {
			background-color: white;
			color: ${({ theme }) => theme.colors.black};
			font-size: 1rem;
			border-radius: 5px;
			padding: 1rem 2rem;
			border: 1px solid white;
			cursor: pointer;

			&:hover {
				box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
				z-index: 2;
			}

			&:active {
				box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
				transform: scale(0.95);
			}
		}
	}

	.hover-menu {
		background-color: white;
		position: absolute;
		display: flex;
		padding: 1rem 1rem;
		animation: hM 0.5s ease-in-out;
		margin-top: 4.2rem;
		right: 0;
		margin-right: 4.4vw;
		border-radius: 15px;
		z-index: 1;
		box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
		justify-content: center;

		&::after {
			content: "";
			background-color: white;
			position: absolute;
			width: 50px;
			height: 50px;
			top: -6px;
			right: calc(12vw + 25px);
			z-index: -1;
			transform: rotate(45deg);
			border-radius: 5px;
		}

		.wrapper {
			padding: 1rem 2rem 1rem 0rem;
			border-radius: 10px;
			cursor: pointer;
			display: grid;
			grid-template-columns: min-content 20vw;
			grid-template-rows: repeat(2, min-content);
			text-decoration: none;

			img {
				grid-area: 1/1/3/2;
				height: 50px;
				margin-right: 1rem;
				padding-left: 2rem;
			}

			.h2 {
				font-family: "Avenir";
				font-weight: 800;
				font-size: 1.2rem;
				letter-spacing: -1px;
				grid-column: 2 / 3;
				grid-row: 1 / 2;
			}

			.p {
				grid-column: 2 / 3;
				grid-row: 2 / 3;
				font-weight: 300;
			}

			&:hover {
				box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
			}

			&:active {
				box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
				transform: scale(0.95);
			}
		}
	}

	@media (min-width: 980px) {
		#a-p {
			width: 100px;
		}
	}

	.hover-menu:hover ~ #a-p {
		font-weight: bold;
	}

	.nav li {
		list-style: none;
		font-size: 16px;
		height: 50px;
		display: flex;
		align-items: center;

		&:not(:last-child) {
			padding-left: 50px;
		}

		@media (max-width: 979px) {
			padding-left: 20vw;
		}
	}

	.a {
		text-decoration: none;
		display: flex;
		align-items: center;
		color: ${(props) =>
			props.headerColors
				? ({ theme }) => theme.colors.black
				: props.isScrolled
				? ({ theme }) => theme.colors.black
				: "white"};

		.chDo {
			width: 1rem;
			margin-left: 3px;
			transition: all 0.2s;
		}

		.rot {
			transform: rotate(180deg);
		}
	}

	.bold {
		font-weight: bold;
	}

	.nav a:visited {
		color: black;
		color: ${(props) =>
			props.headerColors
				? ({ theme }) => theme.colors.black
				: props.isScrolled
				? ({ theme }) => theme.colors.black
				: "white"};
	}

	.nav a:hover {
		text-decoration: underline;
	}

	@media (min-width: 980px) {
		.nav p:hover {
			cursor: pointer;
			font-weight: bold;
		}
	}

	@keyframes hM {
		0% {
			transform: translateY(-10px);
			opacity: 0;
		}

		100% {
			transform: translateY(0);
			opacity: 1;
		}
	}

	@media (min-width: 980px) {
		.mainDivHeaderMobile {
			display: none;
		}
	}

	.overlay {
		display: none;
	}

	@media (max-width: 979px) {
		.mainDivHeaderWeb {
			display: none;
		}

		.divWrapper {
			.mainDivHeaderMobile {
				width: 100%;
				position: fixed;
				background: ${(props) => (props.isScrolled ? "white" : "none")};
				z-index: 1;
				display: flex;
				margin-left: auto;
				margin-right: auto;
				justify-content: space-between;
				top: 0;
				padding-block: 0px;
				z-index: 100;

				.divMenu {
					align-self: center;
					margin-right: 4vw;

					.img {
						margin-right: 15px;
						width: 40px;
					}
				}
			}
		}

		.overlay {
			display: grid;
			position: absolute;
			z-index: 1000;
			width: 100vw;
			height: 100vh;
			background: white;

			position: fixed;
			overflow-y: scroll;

			.lang-sel-mobile {
				font-family: "Work Sans";
				position: absolute;
				bottom: 120px;
				font-size: 1.5rem;
				color: ${({ theme }) => theme.colors.black};
				background-color: transparent;
				border: 1px solid transparent;
				margin-left: 36%;
				font-weight: bold;
			}

			.li-menu {
				display: flex;
				flex-direction: column;
				padding-top: 2rem;

				.p:link,
				.p:visited {
					text-decoration: none;
					color: ${({ theme }) => theme.colors.black};
					font-size: 1.8rem;
					margin-block: 0.8rem;
				}
			}

			.chDo {
				width: 2rem;
				margin-top: 5px;
				margin-left: 3px;
				transition: all 0.2s;
			}

			.rot {
				transform: rotate(180deg);
			}

			.divMenu {
				text-align: right;
				top: 30px;
				right: 30px;
				position: absolute;

				.img {
					width: 40px;
					margin-top: -10px;
				}
			}

			.nav {
				height: 100%;
				display: grid;
				align-items: center;
				margin-right: 0;
				margin-top: -8rem;
				margin-inline: 2rem;

				ul {
					margin-block: 0;
					display: block;
					text-align: center;

					li {
						display: flex;
						margin-top: 30px;
						justify-content: center;
						padding-left: 0;

						a,
						.a {
							color: black;
							font-size: 2.5rem;
							font-family: Avenir;

							/* &:hover {
								text-decoration: none;
								color: silver;
							} */
						}

						.bold {
							font-weight: bold;
						}
					}
				}
			}
		}
	}
`;
