import { useEffect } from "react";
import { StyledRangeSlider } from "./StyledSliderRange";

export const RangeSliderLRValues = (props) => {
	const id = Math.random();
	const value = props.value * 1;

	useEffect(() => {
		props.disabled && (document.getElementById(id).disabled = props.disabled);
	});

	return (
		<StyledRangeSlider>
			<div className='left'>
				<p className='left-p'>{props.left}</p>
				<p id='rangeValue'>
					{props.value ? 10 / 2 - value + 5 : 10 / 2 - props.state * 1 + 5}
				</p>
			</div>
			<div className='slider-div'>
				<input
					type='range'
					min='0'
					max='10'
					defaultValue={props.value ? props.value : 5}
					onChange={(input) => props.setter(input.target.value)}
					className='slider'
					id={id}
				/>
			</div>
			<div className='right'>
				<p className='right-p'>{props.right}</p>
				<p id='rangeValue'>
					{props.value ? 10 / 2 + value - 5 : 10 / 2 + props.state * 1 - 5}
				</p>
			</div>
		</StyledRangeSlider>
	);
};
