import { StyledOrdinaSitoWeb } from "./StyledOrdinaSitoWeb";
import { headerContext } from "../../context/headerContext";
import { useCallback, useContext, useEffect, useState } from "react";
import { realtimeDatabase } from "../../firebase/realtimeFir";
import { ref, set } from "firebase/database";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { useTranslation } from "react-i18next";

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

import roundedRectangle from "../../images/OrdinaSitoWeb/rounded-rectangle.svg";
import hand from "../../images/OrdinaSitoWeb/hand.png";
import project from "../../images/OrdinaSitoWeb/project.png";
import responsive from "../../images/OrdinaSitoWeb/responsive.png";
import shopify from "../../images/OrdinaSitoWeb/shopify.png";
import vscode from "../../images/OrdinaSitoWeb/vscode.png";
import wix from "../../images/OrdinaSitoWeb/wix.png";
import wordpress from "../../images/OrdinaSitoWeb/wordpress.png";
import dot_pattern from "../../images/dot-pattern.png";
import shopify_logo from "../../images/logos/shopify_logo.png";
import wordpress_logo from "../../images/logos/wordpress_logo.png";
import wix_logo from "../../images/logos/wix_logo.png";
import woocommerce_logo from "../../images/logos/woocommerce_logo.png";
import firebase_logo from "../../images/logos/firebase_logo.png";
import javascript_logo from "../../images/logos/javascript_logo.png";
import mongodb_logo from "../../images/logos/mongodb_logo.png";
import react_logo from "../../images/logos/react_logo.png";
import nodejs_logo from "../../images/logos/nodejs_logo.png";
import sass_logo from "../../images/logos/sass_logo.png";
import mongodb_dashboard from "../../images/OrdinaSitoWeb/mongodb_image.png";
import saas_sashboard from "../../images/OrdinaSitoWeb/saas_image.png";
import website_check from "../../images/OrdinaSitoWeb/website_check.png";
import website_cms from "../../images/OrdinaSitoWeb/website_cms.png";
import website_coding from "../../images/OrdinaSitoWeb/website_coding.png";

import ContactSection from "../../components/Contact/ContactSection";

AOS.init();

const OrdinaSitoWeb = (props) => {
	const [isCms, setIsCms] = useState(true);
	const [paddingInline, setPaddingInline] = useState();

	const { t } = useTranslation();

	useEffect(() => {
		document.title = props.title;
	}, [props.title]);

	const handleWidth = () => {
		if (window.innerWidth <= 979) {
			if (window.innerWidth <= 600) return setPaddingInline(20);
			return setPaddingInline(50);
		}
		setPaddingInline((window.innerWidth - 860) / 2);
		console.log((window.innerWidth - 860) / 2);
	};

	useEffect(() => {
		handleWidth();
		window.addEventListener("resize", handleWidth);
	}, []);

	const handleSelector = useCallback(() => {
		setIsCms(!isCms);
	}, [isCms]);

	const items = [
		<div className='card card--1'>
			<div className='img-title'>
				<img
					src={website_check}
					alt='website check'
					className='img-carousel--1'
				/>
				<div className='right-text'>
					<div className='text-wrapper'>
						<h2 className='h2-title'>{t("orderWebsitePageCardItems.rev.1")}</h2>
						<h3 className='h3-subtitle'>
							{t("orderWebsitePageCardItems.rev.2")}
						</h3>
					</div>
					<p className='p'>{t("orderWebsitePageCardItems.rev.3")}</p>
				</div>
			</div>
			<div className='wrapper-body'>
				<div className='left-wrapper'>
					<h2 className='h2-title'>{t("orderWebsitePageCardItems.rev.4")}</h2>
					<p className='p'>{t("orderWebsitePageCardItems.rev.5")}</p>
				</div>
				<div className='line-middle'>&nbsp;</div>
				<div className='right-wrapper'>
					<div className='p'>
						<b>{t("orderWebsitePageCardItems.rev.6")}</b>
						{t("orderWebsitePageCardItems.rev.7")}
					</div>
					<h2 className='h2-price'>
						<span className='text'>{t("orderWebsitePageCardItems.rev.8")}</span>
						<span className='price'>
							{t("orderWebsitePageCardItems.rev.9")}
						</span>
					</h2>
					<a
						href='https://buy.stripe.com/4gw6rxcpw4f89kA4go'
						className='button-acquista'
					>
						{t("orderWebsitePageCardItems.rev.10")}
					</a>
				</div>
			</div>
		</div>,
		<div className='card card--2'>
			<div className='img-title'>
				<img
					src={website_cms}
					alt='website cms creation'
					className='img-carousel--2'
				/>
				<div className='right-text'>
					<div className='text-wrapper'>
						<h2 className='h2-title'>
							{t("orderWebsitePageCardItems.cms5.1")}
						</h2>
						<h3 className='h3-subtitle'>
							{t("orderWebsitePageCardItems.cms5.2")}
						</h3>
					</div>
					<p className='p'>{t("orderWebsitePageCardItems.cms5.3")}</p>
				</div>
			</div>
			<div className='wrapper-body'>
				<div className='left-wrapper'>
					<h2 className='h2-title'>{t("orderWebsitePageCardItems.cms5.4")}</h2>
					<p className='p'>{t("orderWebsitePageCardItems.cms5.5")}</p>
				</div>
				<div className='line-middle'>&nbsp;</div>
				<div className='right-wrapper'>
					<div className='p'>
						<b>{t("orderWebsitePageCardItems.cms5.6")}</b>
						{t("orderWebsitePageCardItems.cms5.7")}
					</div>
					<h2 className='h2-price h2-price--2'>
						<span className='text'>
							{t("orderWebsitePageCardItems.cms5.8")}
						</span>
						<span className='price'>
							{t("orderWebsitePageCardItems.cms5.9")}
						</span>
					</h2>
					<a
						href='https://buy.stripe.com/5kA039fBIdPI1S8bIR'
						className='button-acquista'
					>
						{t("orderWebsitePageCardItems.cms5.10")}
					</a>
				</div>
			</div>
		</div>,
		<div className='card card--3'>
			<div className='img-title'>
				<img
					src={website_coding}
					alt='website coding'
					className='img-carousel--3'
				/>
				<div className='right-text'>
					<div className='text-wrapper'>
						<h2 className='h2-title'>{t("orderWebsitePageCardItems.cc.1")}</h2>
						<h3 className='h3-subtitle'>
							{t("orderWebsitePageCardItems.cc.2")}
						</h3>
					</div>
					<p className='p'>{t("orderWebsitePageCardItems.cc.3")}</p>
				</div>
			</div>
			<div className='wrapper-body'>
				<div className='left-wrapper'>
					<h2 className='h2-title'>{t("orderWebsitePageCardItems.cc.4")}</h2>
					<p className='p'>{t("orderWebsitePageCardItems.cc.5")}</p>
				</div>
				<div className='line-middle'>&nbsp;</div>
				<div className='right-wrapper'>
					<div className='p'>
						<b>{t("orderWebsitePageCardItems.cc.6")}</b>
						{t("orderWebsitePageCardItems.cc.7")}
					</div>
					<h2 className='h2-price h2-price--3'>
						<span className='text'>{t("orderWebsitePageCardItems.cc.8")}</span>
						<span className='price'>{t("orderWebsitePageCardItems.cc.9")}</span>
					</h2>
					<a
						href='https://buy.stripe.com/7sI17dexEfXQdAQ9AK'
						className='button-acquista'
					>
						{t("orderWebsitePageCardItems.cc.10")}
					</a>
				</div>
			</div>
		</div>,
	];

	const headerCtx = useContext(headerContext);
	headerCtx.handleTransparency(false);

	return (
		<StyledOrdinaSitoWeb>
			<section className='section-first header-section'>
				<div className='left-box'>
					<h2 className='h2 title'>{t("orderWebsitePageHeader.1")}</h2>
					<p className='p'>{t("orderWebsitePageHeader.2")}</p>
					<ul className='ul'>
						<li className='li'>
							<img src={project} alt='' className='img img-1' />
							<div className='text'>
								<h2 className='h2'>{t("orderWebsitePageHeader.3")}</h2>
								<p className='p'>{t("orderWebsitePageHeader.4")}</p>
							</div>
						</li>
						<li className='li'>
							<img src={responsive} alt='' className='img img-2' />
							<div className='text'>
								<h2 className='h2'>{t("orderWebsitePageHeader.5")}</h2>
								<p className='p'>{t("orderWebsitePageHeader.6")}</p>
							</div>
						</li>
						<li className='li'>
							<img src={hand} alt='' className='img img-3' />
							<div className='text'>
								<h2 className='h2'>{t("orderWebsitePageHeader.7")}</h2>
								<p className='p'>{t("orderWebsitePageHeader.8")}</p>
							</div>
						</li>
					</ul>
				</div>
				<img src={roundedRectangle} alt='' className='svg__rounded-rectangle' />
				<img
					src={shopify}
					alt=''
					className='img-ex shopify'
					data-aos='fade-up'
					data-aos-delay='50'
					data-aos-duration='1000'
					data-aos-easing='ease-in-out'
					data-aos-once='true'
					data-aos-anchor-placement='top-center'
				/>
				<img
					src={vscode}
					alt=''
					className='img-ex vscode'
					data-aos='fade-up'
					data-aos-delay='200'
					data-aos-duration='1000'
					data-aos-easing='ease-in-out'
					data-aos-once='true'
					data-aos-anchor-placement='top-center'
					data-aos-anchor='shopify'
				/>
			</section>
			<div className='wrapper-dots'>
				<img src={dot_pattern} alt='' className='dot_pattern' />
			</div>
			<section className='section section-second'>
				<div className='box'>
					<div className='left-box'>
						<div className='selector'>
							<div className='text-wrapper'>
								<h2
									onClick={!isCms && handleSelector}
									className={`h2 cms ${isCms && "h2--underline"}`}
								>
									CMS
								</h2>
								{isCms && <div className='underline'>&nbsp;</div>}
							</div>
							<div className='text-wrapper'>
								<h2
									onClick={isCms && handleSelector}
									className={`h2 custom-code ${!isCms && "h2--underline"}`}
								>
									Custom Code
								</h2>
								{!isCms && <div className='underline'>&nbsp;</div>}
							</div>
						</div>
						{isCms && (
							<h2 className='title animate'>
								{t("orderWebsitePageFirstSection.1")}
							</h2>
						)}
						{!isCms && (
							<h2 className='title animate'>
								{t("orderWebsitePageFirstSection.2")}
							</h2>
						)}
						{isCms && (
							<p className={`p ${"animate"}`}>
								{t("orderWebsitePageFirstSection.3")}
							</p>
						)}
						{!isCms && (
							<p className={`p ${"animate"}`}>
								{t("orderWebsitePageFirstSection.4")}
							</p>
						)}
						{isCms && (
							<div className='logo-grid animate'>
								<img
									src={shopify_logo}
									alt='shopify logo'
									className='img-logo img-logo-shopify'
								/>
								<img
									src={wix_logo}
									alt='wix logo'
									className='img-logo img-logo-wix'
								/>
								<img
									src={woocommerce_logo}
									alt='woocommerce logo'
									className='img-logo img-logo-woocommerce'
								/>
								<img
									src={wordpress_logo}
									alt='wordpress logo'
									className='img-logo img-logo-wordpress'
								/>
							</div>
						)}
						{!isCms && (
							<div className='logo-grid-2 animate'>
								<img
									src={firebase_logo}
									alt='firebase logo'
									className='img-logo img-logo-firebase'
								/>
								<img
									src={javascript_logo}
									alt='javasctipt_logo'
									className='img-logo img-logo-javascript'
								/>
								<img
									src={nodejs_logo}
									alt='nodejs logo'
									className='img-logo img-logo-nodejs'
								/>
								<img
									src={mongodb_logo}
									alt='mongodb logo'
									className='img-logo img-logo-mongodb'
								/>
								<img
									src={sass_logo}
									alt='sass logo'
									className='img-logo img-logo-sass'
								/>
								<img
									src={react_logo}
									alt='react logo'
									className='img-logo img-logo-react'
								/>
							</div>
						)}
					</div>
					{isCms && (
						<div className='right-box'>
							<img
								src={shopify}
								alt='shopify dashboard'
								className='img img-shopify'
								data-aos='fade-up'
								data-aos-delay='50'
								data-aos-duration='1000'
								data-aos-easing='ease-in-out'
								data-aos-once='true'
								data-aos-anchor-placement='top-center'
							/>
							<img
								src={wix}
								alt='wix dashboard'
								className='img img-wix'
								data-aos='fade-up'
								data-aos-delay='200'
								data-aos-duration='1000'
								data-aos-easing='ease-in-out'
								data-aos-once='true'
								data-aos-anchor='.img-shopify'
							/>
							<img
								src={wordpress}
								alt='wordpress dashboard'
								className='img img-saas'
								data-aos='fade-up'
								data-aos-delay='350'
								data-aos-duration='1000'
								data-aos-easing='ease-in-out'
								data-aos-once='true'
								data-aos-anchor='.img-shopify'
							/>
						</div>
					)}
					{!isCms && (
						<div className='right-box'>
							<img
								src={vscode}
								alt='vscode'
								className='img img-shopify'
								data-aos='fade-up'
								data-aos-delay='50'
								data-aos-duration='1000'
								data-aos-easing='ease-in-out'
								data-aos-once='true'
								data-aos-anchor-placement='top-center'
							/>
							<img
								src={mongodb_dashboard}
								alt='mongodb dashboard'
								className='img img-wix'
								data-aos='fade-up'
								data-aos-delay='200'
								data-aos-duration='1000'
								data-aos-easing='ease-in-out'
								data-aos-once='true'
								data-aos-anchor='.img-shopify'
							/>
							<img
								src={saas_sashboard}
								alt='saas dashboard'
								className='img img-saas'
								data-aos='fade-up'
								data-aos-delay='350'
								data-aos-duration='1000'
								data-aos-easing='ease-in-out'
								data-aos-once='true'
								data-aos-anchor='.img-shopify'
							/>
						</div>
					)}
				</div>
			</section>
			<ContactSection />
			<section className='section-fourth'>
				<AliceCarousel
					mouseTracking
					items={items}
					disableButtonsControls={true}
					paddingLeft={paddingInline}
					paddingRight={paddingInline}
					activeIndex={1}
					infinite={true}
				/>
			</section>
		</StyledOrdinaSitoWeb>
	);
};

export default OrdinaSitoWeb;
