import { StyledTextArea } from "./StyledTextArea";

const TextArea = (props) => {
	const isRequired = props.isRequired;

	return (
		<StyledTextArea>
			<div className='div'>
				<div className='underline'>
					{isRequired && (
						<textarea
							required={true}
							placeholder={props.placeholder}
							onChange={(input) => props.inputText(input.target.value)}
						/>
					)}
				</div>
				<div className='underline'>
					{!isRequired && (
						<textarea
							placeholder={props.placeholder}
							onChange={(input) => props.inputText(input.target.value)}
						/>
					)}
				</div>
			</div>
		</StyledTextArea>
	);
};

export default TextArea;
