import { useState } from "react";
import { TypeAnimation } from "react-type-animation";
import { useTranslation } from "react-i18next";

const TextHomeTitle = () => {
	const [content, setContent] = useState();

	const { t } = useTranslation();

	const handleContent = () => {
		if (window.innerWidth >= 1180) {
			setContent(
				<TypeAnimation
					sequence={[
						t("titleHomeAnimated.1"),
						2000,
						t("titleHomeAnimated.2"),
						2000,
					]}
					wrapper='b'
					cursor={true}
					repeat={Infinity}
					style={{
						display: "block",
						textAlign: "center",
						fontSize: "3rem",
						paddingTop: "4rem",
						gridColumn: "1/3",
						alignSelf: "center",
					}}
				/>
			);
		} else if (window.innerWidth < 1180) {
			setContent(
				<TypeAnimation
					sequence={[
						t("titleHomeAnimated.1"),
						2000,
						t("titleHomeAnimated.2"),
						2000,
					]}
					wrapper='b'
					cursor={true}
					repeat={Infinity}
					style={{
						display: "block",
						textAlign: "center",
						fontSize: "2rem",
						gridColumn: "1/3",
						paddingInline: "3rem",
						alignSelf: "center",
					}}
				/>
			);
		}
	};

	useState(() => {
		handleContent();
		window.addEventListener("resize", handleContent);
	}, []);

	return content;
};

export default TextHomeTitle;
