import styled from "styled-components";

export const StyledContactSection = styled.div`
	padding-inline: 8.33vw;
	padding-block: 6rem;
	display: flex;
	background-color: white;

	@media (max-width: 63rem) {
		flex-direction: column;
	}

	.left-text {
		width: 60%;
		margin-right: 3rem;

		@media (max-width: 63rem) {
			width: 100%;
			margin-right: 0;
		}

		a:link,
		a:visited,
		b {
			color: ${({ theme }) => theme.colors.primary};
		}

		.h2-contatti {
			font-size: 2.3rem;
			margin-bottom: 2rem;
		}

		.p {
			line-height: 1.7;
		}

		.wrapper-qr {
			display: flex;
			margin-top: 3rem;

			img {
				height: 200px;
				box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
				border-radius: 20px;
				transition: all 0.3s;

				&:hover {
					transform: scale(1.2);
					box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
				}
			}

			.wrapper-text {
				margin-left: 2rem;
				margin-top: 1rem;

				@media (max-width: 979px) {
					margin-left: 0;
					margin-top: 0;
					margin-bottom: 2rem;
				}

				h2 {
					margin-bottom: 1rem;

					@media (max-width: 979px) {
						display: none;
					}
				}

				p {
					line-height: 1.7;
				}
			}
		}
	}

	.form {
		width: 40vw;

		@media (max-width: 62rem) {
			width: 100%;
		}

		.input-wrapper {
			margin-block: 2.5rem;
		}

		.p {
			margin-bottom: 0.8rem;
		}

		.cta {
			margin-top: 2rem;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.visible {
				opacity: 1;
			}

			.invisible {
				opacity: 0;
			}

			.button-send {
				border: 1px solid transparent;
				color: ${({ theme }) => theme.colors.secondary};
				padding: 0.8rem 2rem;
				background-color: ${({ theme }) => theme.colors.primary};
				border-radius: 15px;
				font-size: 1rem;
				font-family: Avenir;
				font-weight: 600;
				box-shadow: 10px 10px 20px rgba(147, 63, 253, 0.1);
				transition: all 0.2s;

				&:hover {
					color: ${({ theme }) => theme.colors.primary};
					background-color: white;
					border: 1px solid ${({ theme }) => theme.colors.primary};
					cursor: pointer;
					transform: translateY(-3px);
					box-shadow: 0px 10px 30px rgba(147, 63, 253, 0.2);
				}

				&:active {
					color: ${({ theme }) => theme.colors.primary};
					background-color: white;
					border: 1px solid ${({ theme }) => theme.colors.primary};
					cursor: pointer;
					transform: translateY(-3px);
					box-shadow: 0px 5px 15px rgba(147, 63, 253, 0.2);

					transform: scale(0.95);
				}
			}
		}
	}
`;
