import styled from "styled-components";

export const StyledUnderConstruction = styled.div`
	min-height: 100vh;

	.background {
		background: rgb(176, 63, 253);
		background: linear-gradient(
			349deg,
			rgba(176, 63, 253, 1) 0%,
			rgba(147, 63, 253, 1) 30%,
			rgba(233, 146, 251, 1) 100%
		);
		min-height: 100vh;
	}

	.wrapper {
		display: flex;
		padding-top: 20vh;
		padding-bottom: 20vh;
		justify-content: center;

		h3 {
			color: ${({ theme }) => theme.colors.secondary};
			margin-bottom: 70px;
		}

		h4 {
			color: ${({ theme }) => theme.colors.secondary};
			margin-top: 10px;
			font-weight: 600;
			font-size: 1.2rem;

			@media (max-width: 979px) {
				margin-top: 60px;
			}
		}

		.a {
			color: ${({ theme }) => theme.colors.secondary};
			text-decoration: none;
			box-shadow: inset 0 0 0 0 ${({ theme }) => theme.colors.secondary};
			margin: -10px -0.25rem 30px 0;
			padding: 0 0.25rem;
			transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
			line-height: 2rem;
			margin-top: 1rem;

			&:hover {
				color: ${({ theme }) => theme.colors.primary};
				box-shadow: inset 100px 0 0 0 ${({ theme }) => theme.colors.secondary};
			}
		}

		.textDiv {
			padding-left: 8.33vw;
			padding-right: 5vw;

			.title {
				font-size: 10vw;
				text-shadow: 0px 0px 10px rgb(0 0 0 / 0.2);
				@media (min-width: 1080px) {
					font-size: 126px;
				}

				color: ${({ theme }) => theme.colors.secondary};
				font-weight: 600;
			}

			.insideDivOne {
				margin-top: 1vw;
				display: grid;

				@media (max-width: 979px) {
					display: inline-block;
				}

				.firstText {
					font-size: 1rem;
					font-weight: 200;
				}
			}

			@media (max-width: 979px) {
				margin-bottom: 70px;
			}
		}

		.divImage {
			align-self: center;

			.imageRight {
				width: 40vw;
				max-width: 700px;
				@media (min-width: 980px) {
					padding-right: 8.33vw;
				}
			}
		}
	}

	@media (max-width: 979px) {
		.wrapper {
			display: block;
			text-align: center;

			.divImage {
				margin-top: 40px;
			}
		}
	}
`;
