import { StyledAdmin } from "./StyledAdmin";
import { useRef, useContext, useState, useCallback } from "react";
import { headerContext } from "../../../context/headerContext";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/authContext";

import logoWhite from "../../../images/diamondWhite.png";
import OrdiniModuloVideoAds from "../OrdiniModuloVideoAds/OrdiniModuloVideoAds";
import OrdiniModuloSitoWeb from "./OrdiniModuloSitoWeb/OrdiniModuloSitoWeb";

const Admin = () => {
	const email = useRef();
	const password = useRef();

	const [selectedSide, setSelectedSide] = useState();

	const { login, user } = useAuth();
	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			await login(email.current.value, password.current.value);
			navigate("/admin/ordini-modulo-video-ads");
		} catch (error) {
			console.log(error.message);
		}
	};

	const headerCtx = useContext(headerContext);
	headerCtx.handleVisibility(true);

	const handleSide = useCallback((side) => {
		setSelectedSide(side);
	}, []);

	if (!user) {
		return (
			<StyledAdmin>
				<div className='loginWrapper'>
					<div className='login'>
						<form onSubmit={handleSubmit}>
							<input ref={email} placeholder='email' />
							<input ref={password} placeholder='password' />
							<button type='submit'>Login</button>
						</form>
					</div>
				</div>
			</StyledAdmin>
		);
	} else {
		return (
			<StyledAdmin>
				<div className='side-bar'>
					<div className='logo-wrapper'>
						<img src={logoWhite} alt='logo' className='logo' />
						<span className='span'>admin</span>
					</div>
					<section className='section-ordini'>
						<h2 className='h2-title-section'>ordini</h2>
						<div
							className={`box ${selectedSide === "videoAds" && "box-clicked"}`}
							onClick={() => {
								handleSide("videoAds");
							}}
						>
							<img
								src='https://img.icons8.com/material-rounded/96/000000/video.png'
								alt='video'
								className='icon'
							/>
							<h3 className='h3'>Video Ads</h3>
						</div>
						<div
							className={`box ${selectedSide === "sitoWeb" && "box-clicked"}`}
							onClick={() => {
								handleSide("sitoWeb");
							}}
						>
							<img
								src='https://img.icons8.com/ios-filled/50/000000/internet.png'
								alt='video'
								className='icon'
							/>
							<h3 className='h3'>Siti Web</h3>
						</div>
					</section>
				</div>
				{selectedSide === "videoAds" ? (
					<OrdiniModuloVideoAds />
				) : selectedSide === "sitoWeb" ? (
					<OrdiniModuloSitoWeb />
				) : (
					""
				)}
			</StyledAdmin>
		);
	}
};

export default Admin;
