import styled from "styled-components";

export const StyledApp = styled.div`
	/* width: fit-content; */
	overflow-x: hidden;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	span,
	p {
		color: ${({ theme }) => theme.colors.black};
	}

	.lds-ellipsis {
		display: flex;
		position: relative;
		width: 100vw;
		height: 100vh;
		justify-content: center;
		align-items: center;

		@media (max-width: 979px) {
			transform: translate(-64px, -60px);
		}
	}
	.lds-ellipsis div {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		margin-inline: 45%;
		width: 26px;
		height: 26px;
		border-radius: 50%;
		background: #933ffd;
		animation-timing-function: cubic-bezier(0, 1, 1, 0);
	}
	.lds-ellipsis div:nth-child(1) {
		left: 16px;
		animation: lds-ellipsis1 0.6s infinite;
	}
	.lds-ellipsis div:nth-child(2) {
		left: 16px;
		animation: lds-ellipsis2 0.6s infinite;
	}
	.lds-ellipsis div:nth-child(3) {
		left: 64px;
		animation: lds-ellipsis2 0.6s infinite;
	}
	.lds-ellipsis div:nth-child(4) {
		left: 108px;
		animation: lds-ellipsis3 0.6s infinite;
	}
	@keyframes lds-ellipsis1 {
		0% {
			transform: scale(0);
		}
		100% {
			transform: scale(1);
		}
	}
	@keyframes lds-ellipsis3 {
		0% {
			transform: scale(1);
		}
		100% {
			transform: scale(0);
		}
	}
	@keyframes lds-ellipsis2 {
		0% {
			transform: translate(0, 0);
		}
		100% {
			transform: translate(24px, 0);
		}
	}
`;
