import { useState, useEffect, useRef, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import storageOne from "../../firebase/storageFir";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { sendVideoAds as sendDataToFir } from "../../firebase/SendModuloOrdinazioneVideoAds.js/Send";
import { useTranslation } from "react-i18next";

import { linkmink } from "../../linkmink/LinkMinkRef";

import InputLabel from "../../components/InputLabel/InputLabel";
import TextArea from "../../components/TextArea/TextArea";
import { StyledModuloOrdinazioneVideoAds } from "./StyledModuloOrdinazioneVideoAds";

import whatsappQR from "../../images/whatsappQR.webp";
import InputFile from "../../components/InputFile/InputFile";
import { useNavigate } from "react-router-dom";
import { headerContext } from "../../context/headerContext";

const ModuloOrdinazioneVideoAds = () => {
	const [inputFileOne, setInputFileOne] = useState(null);
	const [inputFileTwo, setInputFileTwo] = useState(null);
	const [mail, setMail] = useState();
	const [colori, setColori] = useState("none");
	const [puntiDiForza, setPuntiDiForza] = useState("none");
	const [ideaVideo, setIdeaVideo] = useState("none");
	const [linkProdotto, setLinkProdotto] = useState("none");
	const [nomeProdotto, setNomeProdotto] = useState("none");
	const [storeConcorrenti, setStoreConcorrenti] = useState("none");
	const [dettagliExtra, setDettagliExtra] = useState("none");
	const [contatti, setContatti] = useState("none");
	const [nessunFile, setNessunFile] = useState(false);

	const [searchParams, setSearchParams] = useSearchParams();
	const [videoPack, setVideoPack] = useState();
	const [isMobile, setIsMobile] = useState();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalText, setModalText] = useState();
	const [isUploading, setIsUploading] = useState();
	const [uploadProgress, setUploadProgress] = useState();
	let firstUpdate = useRef(0);

	const { t } = useTranslation();

	const navigate = useNavigate();

	const headerCtx = useContext(headerContext);
	headerCtx.handleTransparency(false);

	const handleResize = () => {
		if (window.innerWidth < 980) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
		return;
	};

	const setGenerateDate = () => {
		var date = new Date();
		var datetime = `${date.getFullYear()}${
			date.getMonth() + 1
		}${date.getDay()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}${date.getMilliseconds()}`;
		return datetime;
	};

	useEffect(() => {
		handleResize();
		window.addEventListener("resize", handleResize);
	}, []);

	const isValid = (email) => {
		return /\S+@\S+\.\S+/.test(email);
	};

	useEffect(() => {
		const handleChange = (event) => {
			if (window.location.pathname === "/modulo-ordinazione-video-ads") {
				event.preventDefault();
				event.returnValue = "";
				return "";
			}
		};
		window.addEventListener("beforeunload", handleChange);
		window.addEventListener("popstate", handleChange);
	}, []);

	const setTextTo = (text) => {
		return text;
	};

	const handleSumbit = () => {
		setIsUploading(true);
	};

	useEffect(() => {
		//but why renders two times??
		if (firstUpdate.current < 2) {
			firstUpdate.current += 1;
			return;
		}
		if (isUploading) {
			handleSumbit();
			sendData();
		}
	}, [isUploading]);

	useEffect(() => {
		setVideoPack(searchParams.get("video_pack"));
	}, []);

	const sendData = (fileOne, fileTwo) => {
		const sendToDatabaseOrder = (handleData) => {
			if (isValid(mail)) {
				if (linkProdotto === "none") {
					setIsModalOpen(true);
					setModalText(t("moduleOrderVideoAdsOverlay.1"));
					return;
				}
				const data = handleData;
				console.log(data);
				sendDataToFir(data, "ordinazioneVideoAds/modulo/ordini/");
				navigate("/grazie-per-il-tuo-ordine", { replace: true });
			} else {
				setIsModalOpen(true);
				setModalText(t("moduleOrderVideoAdsOverlay.2"));
			}
		};
		fileOne = inputFileOne;
		fileTwo = inputFileTwo;
		const datetime = setGenerateDate();

		if (inputFileOne !== null && inputFileTwo !== null) {
			try {
				const fileRefOnFirStorageOne =
					"/modulo-creazione-video-ads/immagini-inviate/" +
					`${mail}/` +
					`_${datetime}_` +
					inputFileOne.name;
				const fileLocalRefOne = ref(storageOne, fileRefOnFirStorageOne);

				const fileRefOnFirStorageTwo =
					"/modulo-creazione-video-ads/immagini-inviate/" +
					`${mail}/` +
					`_${datetime}_` +
					inputFileTwo.name;
				const fileLocalRefTwo = ref(storageOne, fileRefOnFirStorageTwo);

				const taskOne = uploadBytesResumable(fileLocalRefOne, inputFileOne);
				const taskTwo = uploadBytesResumable(fileLocalRefTwo, inputFileTwo);

				taskOne.on(
					"state_changed",
					(snapshot) => {
						// Observe state change events such as progress, pause, and resume
						// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
						const progress =
							(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
						setUploadProgress(progress.toFixed(0));
					},
					(error) => {
						setIsUploading(false);
						setIsModalOpen(true);
						setModalText(t("moduleOrderVideoAdsOverlay.3"));
					},
					() => {
						getDownloadURL(taskOne.snapshot.ref).then((downloadURLOne) => {
							taskTwo.on(
								"state_changed",
								(snapshot) => {
									// Observe state change events such as progress, pause, and resume
									// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
									const progress =
										(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
									setUploadProgress(progress.toFixed(0));
								},
								(error) => {
									setIsUploading(false);
									setIsModalOpen(true);
									setModalText(t("moduleOrderVideoAdsOverlay.3"));
								},
								() => {
									getDownloadURL(taskOne.snapshot.ref).then(
										(downloadURLTwo) => {
											const data = {
												fileOne: setTextTo(downloadURLOne),
												fileTwo: setTextTo(downloadURLTwo),
												fileOneName: fileOne.name,
												fileTwoName: fileTwo.name,
												mail: mail,
												colori: colori,
												puntiDiForza: puntiDiForza,
												ideaVideo: ideaVideo,
												linkProdotto: linkProdotto,
												nomeProdotto: nomeProdotto,
												storeConcorrenti: storeConcorrenti,
												dettagliExtra: dettagliExtra,
												contatti: contatti,
												dateKey: setTextTo(datetime),
												videoPack: videoPack,
											};
											console.log(data);

											sendToDatabaseOrder(data);
											setIsUploading(false);
											const options = {
												// type: "Pro (Annual)",
												// status: "trialing",
												match_with: "customer", // allowed values are 'customer', 'subscription'
												livemode: true,
											};
											linkmink
												.conversion(mail, options)
												.then((success) => console.log(`success: ${success}`))
												.catch((error) => console.log(`error: ${error}`));
										}
									);
								}
							);
						});
					}
				);
			} catch {}
		} else if (inputFileOne !== null || inputFileTwo !== null) {
			try {
				let fileLocalRef = "";
				let objLocOne = "none";
				let objLocTwo = "none";
				let currentFile;
				let fileOneName = "none";
				let fileTwoName = "none";

				if (inputFileOne !== null) {
					const fileRefOnFirStorageOne =
						"/modulo-creazione-video-ads/immagini-inviate/" +
						`${mail}/` +
						`_${datetime}_` +
						inputFileOne.name;
					fileLocalRef = ref(storageOne, fileRefOnFirStorageOne);
					currentFile = inputFileOne;
					fileOneName = fileOne.name;
				} else if (inputFileTwo !== null) {
					const fileRefOnFirStorageTwo =
						"/modulo-creazione-video-ads/immagini-inviate/" +
						`${mail}/` +
						`_${datetime}_` +
						inputFileTwo.name;
					fileLocalRef = ref(storageOne, fileRefOnFirStorageTwo);
					currentFile = inputFileTwo;
					fileTwoName = fileTwo.name;
				}

				const task = uploadBytesResumable(fileLocalRef, currentFile);

				task.on(
					"state_changed",
					(snapshot) => {
						// Observe state change events such as progress, pause, and resume
						// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
						const progress =
							(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
						setUploadProgress(progress.toFixed(0));
					},
					(error) => {
						setIsUploading(false);
						setIsModalOpen(true);
						setModalText(t("moduleOrderVideoAdsOverlay.3"));
					},
					() => {
						getDownloadURL(task.snapshot.ref).then((downloadURL) => {
							if (inputFileOne !== null) {
								objLocOne = downloadURL;
								objLocTwo = "none";
							} else if (inputFileTwo !== null) {
								objLocTwo = downloadURL;
								objLocOne = "none";
							}
							console.log(`Uploaded ${fileLocalRef}`);
							const data = {
								fileOne: setTextTo(objLocOne),
								fileTwo: setTextTo(objLocTwo),
								fileOneName: setTextTo(fileOneName),
								fileTwoName: setTextTo(fileTwoName),
								mail: mail,
								colori: colori,
								puntiDiForza: puntiDiForza,
								ideaVideo: ideaVideo,
								linkProdotto: linkProdotto,
								nomeProdotto: nomeProdotto,
								storeConcorrenti: storeConcorrenti,
								dettagliExtra: dettagliExtra,
								contatti: contatti,
								dateKey: setTextTo(datetime),
								videoPack: videoPack,
							};

							sendToDatabaseOrder(data);
							setIsUploading(false);
							const options = {
								// type: "Pro (Annual)",
								// status: "trialing",
								match_with: "customer", // allowed values are 'customer', 'subscription'
								livemode: true,
							};
							linkmink
								.conversion(mail, options)
								.then((success) => console.log(`success: ${success}`))
								.catch((error) => console.log(`error: ${error}`));
						});
					}
				);
			} catch {}
		} else if (inputFileOne === null && inputFileTwo === null) {
			const data = {
				fileOne: "none",
				fileTwo: "none",
				fileOneName: "none",
				fileTwoName: "none",
				mail: mail,
				colori: colori,
				puntiDiForza: puntiDiForza,
				ideaVideo: ideaVideo,
				linkProdotto: linkProdotto,
				nomeProdotto: nomeProdotto,
				storeConcorrenti: storeConcorrenti,
				dettagliExtra: dettagliExtra,
				contatti: contatti,
				dateKey: setTextTo(datetime),
				videoPack: videoPack,
			};

			setNessunFile(true);
			sendToDatabaseOrder(data);
			setIsUploading(false);
			const options = {
				// type: "Pro (Annual)",
				// status: "trialing",
				match_with: "customer", // allowed values are 'customer', 'subscription'
				livemode: true,
			};
			linkmink
				.conversion(mail, options)
				.then((success) => console.log(`success: ${success}`))
				.catch((error) => console.log(`error: ${error}`));
		}
	};

	return (
		<StyledModuloOrdinazioneVideoAds>
			{isUploading && (
				<div className='loadingOverlay'>
					<div className='modal'>
						<h3 className='title'>{t("moduleOrderVideoAdsOverlay.4")}</h3>
						<span>
							{nessunFile
								? t("moduleOrderVideoAdsOverlay.5")
								: t("moduleOrderVideoAdsOverlay.6")}
						</span>
						<div className='loader' />
						{!nessunFile && (
							<span className='progress'>{`${uploadProgress}%`}</span>
						)}
					</div>
				</div>
			)}
			{isModalOpen && (
				<div className='overlayModal'>
					<div className='modal'>
						<h3>Oops...</h3>
						<span className='span'>{modalText}</span>
						<button onClick={() => setIsModalOpen(false)}>OK</button>
					</div>
				</div>
			)}
			<div className='firstSectionDiv'>
				<div className='textWrapper'>
					<span className='title'>{t("moduleOrderVideoAdsHeader.1")}</span>
					<span className='subtitle'>{t("moduleOrderVideoAdsHeader.2")}</span>
				</div>
			</div>
			<div className='questionsWrapper'>
				<div className='leftWrapper'>
					<div className='cat'>
						<span className='title'>{t("moduleOrderVideoAdsLeft.1")}</span>
						<p>{t("moduleOrderVideoAdsLeft.2")}</p>
						<InputLabel
							text={t("moduleOrderVideoAdsLeft.3")}
							inputText={setMail}
							isRequired={true}
						/>
						<p>{t("moduleOrderVideoAdsLeft.4")}</p>
						<InputLabel
							text={t("moduleOrderVideoAdsLeft.5")}
							inputText={setColori}
						/>
						<p>{t("moduleOrderVideoAdsLeft.6")}</p>
						<InputLabel
							text={t("moduleOrderVideoAdsLeft.7")}
							inputText={setPuntiDiForza}
						/>
						<p>{t("moduleOrderVideoAdsLeft.8")}</p>
						<TextArea
							placeholder={t("moduleOrderVideoAdsLeft.9")}
							inputText={setIdeaVideo}
						/>
						<p>{t("moduleOrderVideoAdsLeft.10")}</p>
						<InputLabel
							text={t("moduleOrderVideoAdsLeft.11")}
							isRequired={true}
							inputText={setLinkProdotto}
						/>
						<p>{t("moduleOrderVideoAdsLeft.12")}</p>
						<InputLabel
							text={t("moduleOrderVideoAdsLeft.13")}
							inputText={setNomeProdotto}
						/>
						<p>{t("moduleOrderVideoAdsLeft.14")}</p>
						<TextArea
							placeholder={t("moduleOrderVideoAdsLeft.15")}
							inputText={setStoreConcorrenti}
						/>
					</div>
				</div>
				<div className='rightWrapper'>
					<div className='cat'>
						<span className='title'>{t("moduleOrderVideoAdsRight.1")}</span>
						<p>{t("moduleOrderVideoAdsRight.2")}</p>
						<div className='divUploadButtons'>
							<InputFile inputFile={setInputFileOne} />
							<InputFile inputFile={setInputFileTwo} />
						</div>
					</div>
					<div className='cat'>
						<span className='title'>{t("moduleOrderVideoAdsRight.3")}</span>
						<p>{t("moduleOrderVideoAdsRight.4")}</p>
						<TextArea
							placeholder={t("moduleOrderVideoAdsRight.5")}
							inputText={setDettagliExtra}
						/>
					</div>
					<div className='cat'>
						<span className='title'>{t("moduleOrderVideoAdsRight.6")}</span>
						<p>{t("moduleOrderVideoAdsRight.7")}</p>
						<InputLabel
							text={t("moduleOrderVideoAdsRight.8")}
							inputText={setContatti}
						/>
					</div>

					<div className='divInvia'>
						<span>{t("moduleOrderVideoAdsRight.9")}</span>
						<button type='submit' onClick={handleSumbit}>
							{t("moduleOrderVideoAdsRight.10")}
						</button>
					</div>
					<div className='contattoQR'>
						<h2>{t("moduleOrderVideoAdsRight.11")}</h2>
						<p>
							{t("moduleOrderVideoAdsRight.12")}
							<a href='https://wa.me/00393276216453' className='a'>
								<b>+39 327 621 6453</b>
							</a>
							{!isMobile && t("moduleOrderVideoAdsRight.13")}
							{t("moduleOrderVideoAdsRight.14")}
							<a href='mailto:informazioni@servizidiamond.it' className='a'>
								<b>informazioni@servizidiamond.it</b>
							</a>
						</p>
						<img src={whatsappQR} alt='whatsapp qr' />
					</div>
				</div>
			</div>
		</StyledModuloOrdinazioneVideoAds>
	);
};

export default ModuloOrdinazioneVideoAds;
