import styled from "styled-components";

export const StyledContattaci = styled.div`
	min-height: 100vh;
	padding-top: 150px;
	padding-inline: 8.33vw;
	display: flex;

	@media (max-width: 979px) {
		display: block;

		text-align: -webkit-center;
		text-align: -moz-center;

		.leftDiv,
		.rightDiv {
			width: 80%;
			margin-bottom: 100px;
		}
	}

	@media (min-width: 980px) {
		.leftDiv,
		.rightDiv {
			height: 100vh;
			width: 50%;
			margin-bottom: 100px;
		}

		.leftDiv {
			padding-right: 5vw;
		}

		.rightDiv {
			padding-left: 5vw;
		}
	}

	p {
		font-weight: 300;
	}

	.divQR {
		display: flex;
		align-items: center;
		margin-bottom: 80px;

		@media (max-width: 979px) {
			display: block;
		}

		img {
			@media (max-width: 979px) {
				height: 40vw;
				margin-right: 0;
			}
			height: 10vw;
			margin-right: 20px;
		}
	}

	ul {
		margin-bottom: 50px;
		padding: 0;

		li {
			list-style: none;
			font-size: 1.3rem;
			font-weight: lighter;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-block: 15px;

			@media (max-width: 1240px) {
				display: block;
			}

			a {
				margin: none;
				display: flex;
				align-items: center;
				padding: 8px 12px 8px 12px;
				border: 0.5px solid black;
				border-radius: 2px;
				font-weight: 300;
				width: fit-content;
				text-decoration: none;
				color: ${({ theme }) => theme.colors.black};

				@media (max-width: 1240px) {
					margin-top: 20px;
				}

				.imageDiv {
					img {
						height: 20px;
						margin-top: 6px;
						padding-left: 15px;
					}
				}

				&:hover {
					cursor: pointer;
				}
			}
		}
	}

	form {
		margin-top: 40px;

		.line {
			margin-top: 40px;
		}

		.text {
			margin-top: 50px;
			h3 {
				font-family: Avenir;
				font-size: 1rem;
				font-weight: 400;
				color: #808080;
			}
		}

		.divButton {
			margin-top: 30px;
			display: flex;
			justify-content: space-between;
			justify-content: right;
			align-items: center;

			.left {
				width: 50%;
				align-items: left;

				p {
					margin-block: 0;
				}
			}

			.right {
				width: 50%;
				text-align: right;

				button {
					background: ${({ theme }) => theme.colors.primary};
					color: ${({ theme }) => theme.colors.secondary};
					border: 1px solid ${({ theme }) => theme.colors.primary};
					padding: 6px 16px 6px 16px;
					font-family: Avenir;
					font-weight: 500;

					&:hover {
						background: white;
						color: ${({ theme }) => theme.colors.primary};
						cursor: pointer;
					}
				}
			}
		}
	}

	.overlayModal {
		position: fixed;
		width: 100vw;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		z-index: 10;
		display: flex;
		margin-left: -8.33vw;
		margin-top: -150px;

		.modal {
			display: grid;
			background: #f4f4f4;
			align-items: center;
			justify-items: center;
			margin: auto;
			border-radius: 10px;
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

			h3 {
				font-size: 2rem;
				margin-bottom: 20px;
				margin-top: 40px;
			}

			.span {
				font-weight: lighter;
				margin-inline: 80px;
			}

			button {
				border-radius: 8px;
				background: ${({ theme }) => theme.colors.primary};
				color: #fff;
				border: 1px solid ${({ theme }) => theme.colors.primary};
				padding: 8px 20px 8px 20px;
				margin-inline: 100px;
				font-size: 1.05rem;
				margin-bottom: 40px;
				margin-top: 40px;

				&:hover {
					/* border-radius: 30px; */
					background: #fff;
					border: 1px solid ${({ theme }) => theme.colors.primary};
					color: ${({ theme }) => theme.colors.primary};
					cursor: pointer;
				}
			}
		}
	}
`;
