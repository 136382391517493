import { StyledContactSection } from "./StyledContactSections";

import { realtimeDatabase } from "../../firebase/realtimeFir";
import { ref, set } from "firebase/database";
import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

import InputLabel from "../../components/InputLabel/InputLabel";
import TextArea from "../../components/TextArea/TextArea";

import wQR from "../../images/whatsappQR.webp";

const ContactSection = () => {
	const [isMobile, setIsMobile] = useState();
	const [successModuleMessage, setSuccessModuleMessage] = useState(false);
	const [email, setEmail] = useState();
	const [name, setName] = useState();
	const [message, setMessage] = useState();

	const { t } = useTranslation();

	const handleResize = () => {
		if (window.innerWidth < 980) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
		return;
	};

	useEffect(() => {
		handleResize();
		window.addEventListener("resize", handleResize);
	}, []);

	const setGenerateDate = () => {
		var date = new Date();
		var datetime = `${date.getFullYear()}${
			date.getMonth() + 1
		}${date.getDay()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}${date.getMilliseconds()}`;
		return datetime;
	};

	const handleContactModule = useCallback(
		(event) => {
			try {
				set(ref(realtimeDatabase, "/moduliContatto/home/messaggi/"), {
					name: name,
					email: email,
					message: message,
					sentAt: setGenerateDate,
				});
				setSuccessModuleMessage(true);
			} catch (err) {
				console.log(err);
				setSuccessModuleMessage(false);
			}
		},
		[email, name, message]
	);

	return (
		<StyledContactSection>
			<div className='left-text'>
				<h2 className='h2-contatti'>{t("contactSectionLeftTitle.1")}</h2>

				<p className='p'>
					{t("contactSectionLeftP1.1")}
					<a href='mailto:informazioni@servizidiamond.it'>
						<b>informazioni@servizidiamond.it</b>
					</a>
					{t("contactSectionLeftP1.2")}
				</p>
				<div className='wrapper-qr'>
					{!isMobile && <img src={wQR} alt='whatsapp qr' />}
					<div className='wrapper-text'>
						<h2>{t("contactSectionLeftSubtitle.1")}</h2>
						<p>
							{t("contactSectionLeftP2.1")}
							<a href='https://wa.me/00393276216453'>
								<b>+39 327 621 6453</b>
							</a>{" "}
							{!isMobile && t("contactSectionLeftP2.2")}.
						</p>
					</div>
				</div>
			</div>
			<form onSubmit={handleContactModule} className='form'>
				<div className='input-wrapper'>
					<InputLabel
						inputText={setEmail}
						isRequired={true}
						label={t("contactSectionForm.5")}
					/>
				</div>
				<div className='input-wrapper'>
					<InputLabel
						inputText={setName}
						isRequired={true}
						label={t("contactSectionForm.6")}
					/>
				</div>
				<div className='input-wrapper'>
					<p className='p'>{t("contactSectionForm.1")}</p>
					<TextArea
						placeholder={t("contactSectionForm.4")}
						inputText={setMessage}
					/>
					<div className='cta'>
						<button className='button-send'>{t("contactSectionForm.2")}</button>
						<p className={successModuleMessage ? "visible" : "invisible"}>
							{t("contactSectionForm.3")}
						</p>
					</div>
				</div>
			</form>
		</StyledContactSection>
	);
};

export default ContactSection;
