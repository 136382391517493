export const firebaseConfig = {
	apiKey: "AIzaSyAjR2EPFuolodgD7myjnD1YOLjtl5zm0xk",
	authDomain: "servizi-diamond.firebaseapp.com",
	databaseURL:
		"https://servizi-diamond-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "servizi-diamond",
	storageBucket: "servizi-diamond.appspot.com",
	messagingSenderId: "575263582619",
	appId: "1:575263582619:web:3dd1e8b723ef04f448593e",
	measurementId: "G-4HHZ2SX8K9",
};
